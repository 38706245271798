import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { TraveltimeComponent } from './traveltime/traveltime.component';
import { WeatherStationComponent } from './weather-station/weather-station.component';
import { TrafficSignalComponent } from './traffic-signal/traffic-signal.component';
import { PerMeasurementComponent } from './per-measurement/per-measurement.component';
import { SensorMeasurementComponent } from './sensor-measurement/sensor-measurement.component';
import { SensorComponent } from './sensor/sensor.component';
import { SensorMeasurementNewComponent } from './sensor-measurement-new/sensor-measurement-new.component';
export const routes: Routes = [
  {
    path: '',
    redirectTo: 'traveltime',
    pathMatch: 'full',
  },
  {
    path: '',
    component: HomeComponent,
    children: [
      {
        path: 'traveltime',
        component: TraveltimeComponent,
      },
      {
        path: 'weather-station',
        component: WeatherStationComponent,
      },
      {
        path: 'traffic-signal',
        component: TrafficSignalComponent,
      },
      {
        path: 'sensor',
        component: SensorComponent,
      },
      {
        path: 'sensor-measurement',
        component: SensorMeasurementNewComponent,
      },
      {
        path: 'per-measurement',
        component: PerMeasurementComponent,
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TrafficToolRoutingModule { }
