export const mainRouteUrl = "dashboard";
export const id = 1200;
export const menuItems = [
    {
        id: 1201,
        name: 'Corridor Control',
        url: `/${mainRouteUrl}/corridor-control`,
        icon: 'fa fa-clone',
        parent_id: id
    },
    {
        id: 1202,
        name: 'Schematic Map',
        url: `/${mainRouteUrl}/schematic-map`,
        icon: 'icon-directions',
        parent_id: id
    },
    {
        id: 1203,
        name: 'Reversible Lane',
        url: `/${mainRouteUrl}/reversible-lane`,
        icon: 'icon-cursor',
        parent_id: id
    },
    {
        id: 1204,
        name: 'Travel Time',
        url: `/${mainRouteUrl}/travel-time`,
        icon: 'icon-clock',
        parent_id: id
    },
];