import { Component, OnInit, AfterViewInit, Pipe, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';
import * as _ from 'lodash';
import { interval } from 'rxjs';
import { PlotBand } from '@progress/kendo-angular-charts';
import { CommonService } from 'src/app/services/common.service';
import { ActionEnum } from 'src/app/models/common-enum';

@Component({
  selector: 'app-travel-time',
  templateUrl: './travel-time.component.html',
  styleUrls: ['./travel-time.component.scss']
})
export class TravelTimeComponent implements OnInit, OnDestroy {
  selected: any;
  selected2: any;
  selected_text: string;
  data = { categories: [], current: [], max: [], avg: [], min: [], current_predict: [] };
  public allowCustom: boolean = false;

  route: any;
  path: any;

  route_first_load = true;
  path_first_load = true;

  public data_route: Array<{ route: string }>;
  public routeList: Array<{ route: string }> = [];
  public data_path: Array<{ name: string, code: string, route: string }>;
  public pathList: Array<{ name: string, code: string, route: string }> = [];
  public style: string = 'smooth';
  public dashType: string = 'dot';
  subscription: any;
  type: any = '';
  public categoryPlotBands: PlotBand[] = [{
    from: null,
    to: null,
    color: null,
    opacity: 0
  }];
  constructor(private dashboardService: DashboardService,
    private commonService: CommonService) {
    this.commonService.activityLog(ActionEnum.Get, 'Dashboard ➡ Travel Time').subscribe(res => { }, error => { console.log(error); }); }

  ngOnInit() {
    this.getRouteList();
    this.subscription = interval(60000).subscribe((x) => {
      this.getTimeseries();
    });
  }
  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
  async getRouteList() {
    this.dashboardService.getReversibleTraveltimeRoute().subscribe(
      res => {
        if (res.code == 1) {
          this.routeList = _.cloneDeep(res.data);
          this.data_route = _.cloneDeep(res.data);
          if (this.routeList.length > 0 && this.route_first_load) {
            this.route = this.routeList.find(x => +x.route == 2);
            if (!this.route) {
              this.route = this.routeList[0];
            }
            this.route_first_load = false;
            this.getPathList(+this.route.route);
          }
        }
      },
      err => {
        console.log(JSON.stringify(err.statusText));
      });
  }
  handleFilterRoute(value) {
    if (value.length > 0) {
      this.data_route = this.data_route.filter((s) => s.route.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else if (value.length == 0) {
      this.data_route = _.cloneDeep(this.routeList);
    } else {
      this.data_route = [];
    }
  }
  handleValueRoute(value) {
    if (value) {
      this.route = value;
      this.getPathList(+value.route);
    } else {
      this.route = null;
    }
    this.path = null;
    this.data = { categories: [], current: [], max: [], avg: [], min: [], current_predict: [] };
  }

  handleFilterPath(value) {
    if (value.length > 0) {
      this.data_path = this.pathList.filter((s) => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else if (value.length == 0) {
      this.data_path = _.cloneDeep(this.pathList);
    } else {
      this.data_path = [];
    }
  }
  handleValuePath(value) {
    if (value) {
      this.path = value;
      //this.getTimeseries();
    } else {
      this.path = null;
      this.data = { categories: [], current: [], max: [], avg: [], min: [], current_predict: [] };
    }
  }
  async getPathList(route?: number) {
    this.dashboardService.getReversibleTraveltimePath(route).subscribe(
      res => {
        if (res.code == 1) {
          this.pathList = _.cloneDeep(res.data);
          this.data_path = _.cloneDeep(res.data);
          if (res.data.length > 0 && this.path_first_load) {
            this.path = this.pathList[0];
            this.path_first_load = false;
            this.getTimeseries();
          }
        }
      },
      err => {
        console.log(JSON.stringify(err.statusText));
      });
  }

  search() {
    console.log(this.type)
    this.getTimeseries();
  }


  getData() {
    this.getTimeseries();
  }

  async getTimeseries() {
    if (this.route && this.path) {
      this.data = { categories: [], current: [], max: [], avg: [], min: [], current_predict: [] };
      this.categoryPlotBands = [{
        from: null,
        to: null,
        color: null,
        opacity: 0
      }];
      this.dashboardService.getReversibleTraveltime(this.path.code).subscribe(
        res => {
          if (res.code == 1) {
            if (res.data) {
              let d = res.data;
              this.data.categories = _.map(d, 'hour');
              //this.data.current = _.map(d, 'current_value');
              this.data.max = _.map(d, 'max_old_value');
              this.data.avg = _.map(d, 'avg_old_value');
              this.data.min = _.map(d, 'min_old_value');
              let current_predict = [];
              let current = [];
              //let predict_index = _.findIndex(res.data, { predict: 1 });
              let current_index = _.findIndex(res.data, { is_current: 1 });
              res.data.forEach((item, index) => {
                if (item.predict == 1) {
                  current_predict.push(item.current_value);
                  current.push(null);
                } else {
                  if (index == current_index) {
                    current_predict.push(item.current_value);
                  } else {
                    current_predict.push(null);
                  }
                  current.push(item.current_value);
                }
              });
              this.categoryPlotBands = [{
                from: current_index,
                to: current_index,
                color: '#28b4c8',
                opacity: 0.8
              }];
              this.data.current = current;
              this.data.current_predict = current_predict;
            } else {
              this.data = { categories: [], current: [], max: [], avg: [], min: [], current_predict: [] };
            }
          } else {
            this.data = { categories: [], current: [], max: [], avg: [], min: [], current_predict: [] };
          }

        },
        err => {
          console.log(JSON.stringify(err.statusText));
        });
    }

  }

}
