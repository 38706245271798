<div class="row p-3">
  <div class="col-lg-4 col-md-4 col-sm-6">
    <div>ทางหลวง :</div>
    <div>
      <kendo-combobox class="w-100" [data]="data_route" [allowCustom]="allowCustom" [textField]="" [valueField]=""
        [value]="search_route" (valueChange)="handleValueRoute($event)" (filterChange)="handleFilterRoute($event)"
        [placeholder]="'ทั้งหมด'" [filterable]="true" >
      </kendo-combobox>
    </div>
  </div>
  <div class="col-lg-4 col-md-4 col-sm-6">
    <div>ช่วงกิโลเมตร :</div>
    <div class="d-flex align-items-center">
      <div class="flex-fill"><span class="nowrap mr-1">เริ่มต้น :</span></div>
      <div class="flex-fill"><input type="text" class="form-control w-100" placeholder="กม." numbersOnly maxlength="4"
          [(ngModel)]="search_km_st" [ngModelOptions]="{standalone: true}"></div>
      <div class="flex-fill ml-1 mr-1"> + </div>
      <div class="flex-fill"><input type="text" class="form-control w-100" placeholder="ม." numbersOnly maxlength="3"
          [(ngModel)]="search_m_st" [ngModelOptions]="{standalone: true}"></div>
      <div class="flex-fill"><span class="nowrap ml-1 mr-1">สิ้นสุด :</span></div>
      <div class="flex-fill"><input type="text" class="form-control w-100" placeholder="กม." numbersOnly maxlength="4"
          [(ngModel)]="search_km_en" [ngModelOptions]="{standalone: true}"></div>
      <div class="flex-fill ml-1 mr-1"> + </div>
      <div class="flex-fill"><input type="text" class="form-control w-100" placeholder="ม." numbersOnly maxlength="3"
          [(ngModel)]="search_m_en" [ngModelOptions]="{standalone: true}"></div>
    </div>
  </div>
  <div class="col-lg-4 col-md-4 col-sm-6">
    <div>สถานะ :</div>
    <div>
      <kendo-combobox class="w-100" [data]="status_list" [allowCustom]="allowCustom" [textField]="'name'" [filterable]="true" 
        [valueField]="'id'" [value]="search_status" (valueChange)="handleValueStatus($event)" [placeholder]="'ทั้งหมด'">
      </kendo-combobox >
    </div>
  </div>
  <div class="col-lg-4 col-md-4 col-sm-6">
    <div>Station :</div>
    <div>
      <kendo-combobox class="w-100" [data]="station_search_list" [allowCustom]="allowCustom" [textField]="'name'"
        [valueField]="'id'" [value]="search_station" (valueChange)="handleValueStation($event)" [filterable]="true" 
        (filterChange)="handleFilterStation($event)" [placeholder]="'ทั้งหมด'">
      </kendo-combobox>
    </div>
  </div>
  <div class="col-lg-4 col-md-4 col-sm-6">
    <div>Owner :</div>
    <div>
      <kendo-combobox class="w-100" [data]="data_owner" [allowCustom]="allowCustom" [textField]="'company_name'"
        [valueField]="'id'" [value]="search_owner" (valueChange)="handleValueOwner($event)" [filterable]="true"
        (filterChange)="handleFilterOwner($event)" [placeholder]="'ทั้งหมด'">
      </kendo-combobox>
    </div>
  </div>
  <div class="col-lg-4 col-md-4 col-sm-6">
    <div>Vendor :</div>
    <div>
      <kendo-combobox class="w-100" [data]="data_vendor" [allowCustom]="allowCustom" [textField]="'company_name'"
        [valueField]="'vendor_id'" [value]="search_vendor" (valueChange)="handleValueVendor($event)" [filterable]="true"
        (filterChange)="handleFilterVendor($event)" [placeholder]="'ทั้งหมด'">
      </kendo-combobox>
    </div>
  </div>
  <div class="col-lg-4 col-md-4 col-sm-6">
    <div>กลุ่มอุปกรณ์ :</div>
    <div>
      <kendo-combobox class="w-100" [data]="data_device_group" [allowCustom]="allowCustom" [textField]="'name'"
        [valueField]="'id'" [value]="search_device_group" (valueChange)="handleValueDeviceGroup($event)" [filterable]="true"
        (filterChange)="handleFilterDeviceGroup($event)" [placeholder]="'ทั้งหมด'">
      </kendo-combobox>
    </div>
  </div>
  <div class="col-lg-4 col-md-4 col-sm-6">
    <div>ประเภทอุปกรณ์ :</div>
    <div>
      <kendo-combobox class="w-100" [data]="data_device_type" [allowCustom]="allowCustom" [textField]="'name'"
        [valueField]="'type_id'" [value]="search_device_type" (valueChange)="handleValueDeviceType($event)"
        [filterable]="true"
        (filterChange)="handleFilterDeviceType($event)" [placeholder]="'ทั้งหมด'">
      </kendo-combobox>
    </div>
  </div>
  <div class="col-lg-4 col-md-4 col-sm-6">
    <div>Monitor :</div>
    <div>
      <kendo-combobox class="w-100" [data]="monitor_type_list" [allowCustom]="allowCustom" [textField]="'name'"
        [valueField]="'id'" [value]="search_monitor_type" (valueChange)="handleValueMonitor($event)" [filterable]="true"
        [placeholder]="'ทั้งหมด'">
      </kendo-combobox>
    </div>
  </div>
  <div class="col-lg-12 col-md-12 d-flex align-items-end justify-content-end">
    <div class="mt-1">
      <button type="button" (click)="search()" class="btn btn-primary pull-right text-white ml-1"> <i
          class="fa fa-search"></i>
        ค้นหา </button>
    </div>
  </div>

  <div class="col-12 mt-3 table-header pl-3 pr-3 pt-2 pb-2 d-flex align-items-center justify-content-between">
    <!-- <div class="font-2">
        <i class="fa fa-bars mr-2"></i> Station
      </div> -->
    <div class="btn-group btn-group-toggle" data-toggle="buttons">
      <label class="btn text-white pointer" [ngClass]="(viewType == 1)?'btn-primary':'btn-dark'">
        <input type="radio" name="options" id="option1" autocomplete="off" [value]="1" [(ngModel)]="viewType"
          (click)="changeView(1)"> <i class="fa fa-bars mr-1"></i>List
      </label>
      <label class="btn text-white pointer" [ngClass]="(viewType == 2)?'btn-primary':'btn-dark'">
        <input type="radio" name="options" id="option1" autocomplete="off" [value]="2" [(ngModel)]="viewType"
          (click)="changeView(2)"><i class="fa fa-map mr-1"></i> Map
      </label>
    </div>
    <button *ngIf="isAdd" (click)="openAddModal()" type="button" class="btn btn-primary text-white"> <i class="fa fa-plus"></i>
      เพิ่มการแจ้งซ่อม</button>
  </div>
  <div *ngIf="viewType == 1" class="col-12 mt-3">
    <table class="table table-outline mb-0">
      <thead class="thead-dark" style="background-color: black;">
        <tr>
          <th class="text-center text-light table-header"><i class="fa fa-hashtag"></i></th>
          <th class="text-center text-light table-header">สถานะ</th>
          <th class="text-center text-light table-header">การเชื่อมต่อ</th>
          <th class="text-center text-light table-header">อุปกรณ์</th>
          <th class="text-center text-light table-header">ประเภทการแจ้งซ่อม</th>
          <th class="text-center text-light table-header">Create date</th>
          <th *ngIf="isEdit" class="text-center text-light table-header">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngIf="!this.is_loading && data_list?.length == 0">
          <td colspan="10" style="height: 50px;">
            ไม่พบข้อมูล
          </td>
        </tr>
        <tr *ngIf="this.is_loading">
          <td colspan="10" style="height: 50px;">
            กำลังโหลด ..
          </td>
        </tr>
        <tr *ngFor="let item of data_list;index as i;">
          <td class="text-center">
            <div>
              {{(i+1)+((this.currentPage - 1)*itemsPerPage)}}
            </div>
          </td>
          <td class="text-center">
            <i tooltip="Close" placement="top" *ngIf="item?.status == maStatusEnum.Close"
              class="fa fa-circle text-success"></i>
            <i tooltip="Create" placement="top" *ngIf="item?.status == maStatusEnum.Create"
              class="fa fa-circle text-danger"></i>
            <i tooltip="Operate" placement="top" *ngIf="item?.status == maStatusEnum.Operate"
              class="fa fa-circle text-warning"></i>
          </td>
          <td class="text-center">
            <i tooltip="Online" placement="top" *ngIf="item?.connection_status == connectionStatusEnum.Online"
              class="fa fa-wifi text-success"></i>
            <i tooltip="Offline" placement="top" *ngIf="item?.connection_status == connectionStatusEnum.Offline"
              class="fa fa-wifi text-danger"></i>
          </td>
          <td class="text-center">
            {{item?.item_name}}
            <div *ngIf="!item?.item_name">-</div>
          </td>
          <td class="text-center">
            <div *ngIf="item?.type_id == monitorTypeEnum.Hardware">Hardware</div>
            <div *ngIf="item?.type_id == monitorTypeEnum.Software">Software</div>
            <div *ngIf="item?.type_id == monitorTypeEnum.Boardsign">ป้ายแสดงผล</div>
          </td>
          <td class="text-center">
            <div style="font-size: 12px;">{{item?.create_date | date: 'dd/MM/yyyy'}}</div>
          </td>

          <td *ngIf="isEdit" class="text-center">
            <div class="d-flex align-items-center justify-content-center">
              <button (click)="openAddLogModal(item)" title="ดำเนินการ" type="button"
                class="btn btn-sm btn-warning mr-1 text-light">
                <i class="cui-note icons"></i>
              </button>
              <button (click)="openDeleteModal(item)" title="ลบ" type="button" class="btn btn-sm btn-danger ml-1">
                <i class="cui-trash icons"></i>
              </button>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="12" class="table-footer">
            <div class="table-footer-inside d-flex align-items-center justify-content-between">
              <div class="ml-3 pt-3">
                <pagination [totalItems]="totalItems" [maxSize]="5" [itemsPerPage]="itemsPerPage"
                  [(ngModel)]="currentPage" (pageChanged)="pageChanged($event)" class="pagination"
                  previousText="&lsaquo;" nextText="&rsaquo;" [boundaryLinks]="true" firstText="&laquo;"
                  lastText="&raquo;"></pagination>
              </div>
              <div></div>
              <div class="mr-3 ml-2">total: {{totalItems}}</div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="col-12 mt-3">
    <div class="map" id="map-ma" [ngClass]="(viewType == 2) ? 'map' : 'map-hide'"></div>
  </div>
</div>
<div bsModal #deleteModal="bs-modal" class="modal fade modal-md" tabindex="-1" role="dialog"
  aria-labelledby="myModalLabel" aria-hidden="true" (onHide)="clearData()" [config]="{backdrop: 'static'}">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header bg-dark bg-gray-900">
        <h4 class="modal-title"> <i class="icon-info mr-2"></i>ลบการแจ้งซ่อม</h4>
        <button type="button" class="close text-light" (click)="deleteModal.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="mt-2 text-center">
          <h4>คุณต้องการลบใช่หรือไม่</h4>
        </div>
        <div class="mt-2 mb-4 text-center">
        </div>
        <div class="d-flex justify-content-end mt-2 mb-2">
          <button type="button" class="btn btn-secondary" (click)="deleteModal.hide()">ยกเลิก</button>
          <button type="button" class="btn btn-primary text-white ml-2" (click)="deleteMa()">ยืนยัน</button>
        </div>
      </div>
    </div>
  </div>
</div>
<swal #swalSuccess title="<div class='text-light'>สำเร็จ</div>" type="success" text="" icon="success"></swal>
<swal #swalError title="<div class='text-light'>ไม่สำเร็จ</div>" html="<div class='text-light'>{{res_message}}</div>"
  type="error" icon="error"></swal>
<app-ma-management [clickOpen]="clickOpenAdd" [view]="selectMaView" [maId]="selectMaId" (onClose)="closeAddModal()"
  (onSuccess)="addMaSuccess()" [isDescDevice]="true" [isDescMa]="true" [isAddMa]="true" [isMaLog]="true"
  [isAddMaLog]="true"></app-ma-management>