import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash'
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FuncService } from 'src/app/services/user/func.service';
import { EventService } from 'src/app/services/user/event.service';
import { EventModalComponent } from './event-modal/event-modal.component';
import { DeviceService } from 'src/app/services/user/device.service';
import { CommonService } from 'src/app/services/common.service';
import { permission } from 'src/app/views/event/menu';
import * as mm from 'moment'
import { EventDetailComponent } from '../event-detail/event-detail.component';
import { evt_status } from '../values';
import { ActionEnum } from 'src/app/models/common-enum';
import { Router, ActivatedRoute} from '@angular/router';
@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.scss']
})
export class EventComponent implements OnInit {
  modalRef: BsModalRef;
  modalDetailEvt: BsModalRef;
  allowCustom: boolean = false;
  allData : any = []
  route_list : any
  search_txt : any
  search_route : any
  direction : any = [true,true]
  st_date : any
  en_date : any
  data_list : any = []
  groupList : any = []
  allType : any
  list: any;
  total : number = 0;
  currentPage : number = 1;
  itemsPerPage : number = 15;
  data_route: any;
  route: any;
  st_km: any;
  en_km: any;
  src_group_id: any = null
  group_id: any = null
  type_id: any = null
  status: any = null

  isAdd: boolean = false;
  isDetail: boolean = false;
  isDelete: boolean = false;
  statusList : any = evt_status
  typeList: any
  paramsObject: any
  constructor(
    private func: FuncService,
    private modalService: BsModalService,
    private evt_S: EventService,
    private devS: DeviceService,
    private commonService: CommonService,
    private router: Router,
    private route_p: ActivatedRoute,
  ) { 
    this.commonService.activityLog(ActionEnum.Get, 'Event Management ➡ Event list').subscribe(res => { }, error => { console.log(error); });
  }

  async ngOnInit() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.isAdd = await this.commonService.getPermission(permission.incident.add_id).toPromise();
    this.isDetail = await this.commonService.getPermission(permission.incident.detail_id).toPromise();
    this.isDelete = await this.commonService.getPermission(permission.incident.delete_id).toPromise();
    this.getData()
  }
  
  params_list: any
  clearPosParam() {
    this.router.navigateByUrl('event/list');
  }
  async getParams() {
    let result: any = this.route_p.params.subscribe(async params => {
      this.params_list = params
      await this.getEvt({id:params.evt_id})
      this.openModal(this.data_list[0])
      this.clearPosParam()
    });
    result.unsubscribe();
    // this.search()
  }
  FilterRoute(evt:any){
    if(evt.length>0){
      this.route_list = this.route_list.filter(res => res.includes(evt))
    }else if(evt.length==0){
      this.route_list = this.route
    }else{

    }
  }
  async ChageRoute(evt) {
    console.log(evt)
  }

  async search(){
    this.data_list = null
    let all : any = {}
    if(this.search_route && this.search_route !=='null') all.route = Number(this.search_route)
    if(this.src_group_id && this.src_group_id !=='null') all.src_group_id = this.src_group_id
    if(this.group_id && this.group_id !=='null') all.group_id = this.group_id
    if(this.type_id && this.type_id !=='null') all.event_type_id = this.type_id
    if(this.status && this.status!=='null') all.status = this.status
    if(this.st_km) {
      all.st_km = this.st_km
      all.st_m = 0
    }
    if(this.en_km) {
      all.en_km = this.en_km
      all.en_m = 1000
    }
    if(this.st_date) all.st_date = mm(this.st_date).add(7, "hours").toISOString()
    if(this.en_date) all.en_date = mm(this.en_date).endOf("day").add(7, "hours").toISOString()
    // if(this.direction.length){
    //   let dir = []
    //   for (const i in this.direction) {
    //     if(this.direction[i]) {
    //       dir.push(Number(i)+1)
    //     }
    //   }
    //   if(dir.toString().match('1') && dir.toString().match('2')) dir.push(3)
    //   all.direction = dir.toString()
    // }
    this.getEvt(all)
  }
  async getEvt(params?:any){
    let res : any = await this.evt_S.getEvt(params)
    if(res.data.length){
      for (const i of res.data) {
        i.status_name = this.statusList.find(v=>i.status==v.id).name
      }
    }
    if(res) this.data_list = res.data
  }
  async rmlist(id) {
    let result = await this.func.alertpopup('<h4>คุณต้องการลบใช่หรือไม่</h4>', `<i class="icon-info mr-2"></i>ลบผู้ใช้งาน`, 1);
    if (result) {
      let res: any = await this.evt_S.rmEvt(id)
      if (res) this.search()
    }
  }
  async getData(){
    // this.allData = await this.evt_S.getIncidentType()
    // this.allType = this.allData 
    this.getGroup()
    this.getType()
    this.getRoute();
    if(this.router.url.match('fromIncident=true;')){
      this.getParams()
    }else if(this.route_p.snapshot.queryParamMap.get('event_type_id')){
      this.route_p.queryParamMap.subscribe((params) => {
        this.paramsObject = { ...params.keys, ...params };
        this.type_id = this.paramsObject.params.event_type_id
        this.status = this.paramsObject.params.status
      });
      this.search();
    }else{this.getEvt()}
  }
  async getRoute(){
    this.route_list = await this.devS.getRoute()
    this.route = this.route_list
  }
  async getGroup(){
    this.groupList = await this.evt_S.getEvtGroup()
  }
  async getType(){
    this.typeList = await this.evt_S.getEvtType()
  }
  openModal(data?: any) {
    const initialState = {
      values: data,
      list: this.list,
      inc_type : this.allType
    };
    this.modalRef = this.modalService.show(EventModalComponent, { initialState, class: 'w-80',backdrop: 'static'});
    this.modalRef.content.event.subscribe(data => {
      // this.getData()
      if (data) this.search()
    });
  }
  pageChanged(event: any): void {
    if (this.currentPage != event.page) {
      this.currentPage = event.page;
      // this.getDevice();
    }
  }
  handleFilter(value) {
    if (value.length > 0) {
     this.allData = this.allData.filter((s) => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else if (value.length == 0) {
      this.allData = _.cloneDeep(this.allType);
    } else {
     this.allData = [];
    }
  }
  handleValue(value) {
    if (value) {
      this.search_txt = value;
    } else {
      this.search_txt = null;
    }
  }

  
  addEvent(){
    const initialState = {
      values: null,
    };
    this.modalDetailEvt = this.modalService.show(EventDetailComponent, { initialState, class: 'w-80',backdrop: 'static'});
    this.modalDetailEvt.content.event.subscribe(data => {
      if (data) this.getData()
      // return resolve(data);
    });
  }
}
