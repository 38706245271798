<div class="modal-header">
    <h4 class="modal-title pull-left">หน่วยงาน</h4>
    <button type="button" class="close pull-right text-white" aria-label="Close" (click)="bsModalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body text-center" >
    <div class="row">
        <div class="col-6">
            <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <p class="input-group-text">ชื่อหน่วยงาน <span class="red-txt"> *</span></p>
                </div>
                <input type="text" class="form-control" [(ngModel)]="name">
              </div>
        </div>
        <div class="col-6">
            <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text">Hashtag</span>
                </div>
                <input type="text" class="form-control" [(ngModel)]="hashtag">
              </div>
        </div>
        <div class="col-6">
            <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text">เบอร์โทร 1</span>
                </div>
                <input type="text" class="form-control" [(ngModel)]="tel_1">
              </div>
        </div>
        <div class="col-6">
            <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text">เบอร์โทร 2</span>
                </div>
                <input type="text" class="form-control" [(ngModel)]="tel_2">
              </div>
        </div>
        <div class="col-12">
            <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text">รายละเอียด</span>
                </div>
                <textarea type="text" class="form-control" [(ngModel)]="remark" style="resize: none;">
                </textarea>
              </div>
        </div>
    </div>
  </div>
  <div class="modal-footer">
      <!-- <button type="button" class="btn btn-primary btn-sm" (click)="config.animated = !config.animated">{{config.animated ? 'Disable' : 'Enable'}} animation</button> -->
    <button type="button" class="btn btn-danger" (click)="close()">ปิด</button>
    <!-- <button type="button" class="btn btn-danger" (click)="cancel()">No</button> -->
    <button type="button" class="btn btn-info" (click)="save()" [disabled]="(!name) ? 'disabled': null">ยืนยัน</button>
  </div>