import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ApiResponse, PagableData } from '../../models/common';
import { environment } from '../../../environments/environment';
import * as _ from 'lodash';
import { AuthService } from '../auth/auth.service';
import { MediaInfo, ReqAddImage } from '../../models/sign-control/media';

import { StatusEnum } from '../../models/common-enum';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { SignEventMediaTextItem } from '../../models/sign-control/sign-setting';

@Injectable({
  providedIn: 'root'
})
export class MediaService {

  domain: string = environment.api.domain_sign_control;
  constructor(private http: HttpClient, private authService: AuthService) {
  }
  getImage(page?: number, limit?: number, name?: string, ratio?: string, media_type?: number, use_type?: number,
    sign_type_id?: number, status?: number, boardsign_id?: number, sign_id?: number, behavior_type?:number, select_type?:number) {
    let params = new HttpParams();
    if (!_.isNil(page)) {
      params = params.append('page', page.toString());
    }
    if (!_.isNil(limit)) {
      params = params.append('limit', limit.toString());
    }
    if (!_.isNil(name)) {
      params = params.append('name', name.toString());
    }
    if (!_.isNil(ratio)) {
      params = params.append('ratio', ratio.toString());
    }
    if (!_.isNil(media_type)) {
      params = params.append('media_type', media_type.toString());
    }
    if (!_.isNil(use_type)) {
      params = params.append('use_type', use_type.toString());
    }
    if (!_.isNil(sign_type_id)) {
      params = params.append('sign_type_id', sign_type_id.toString());
    }
    if (!_.isNil(status)) {
      params = params.append('status', status.toString());
    }
    if (!_.isNil(boardsign_id)) {
      params = params.append('boardsign_id', boardsign_id.toString());
    }
    if (!_.isNil(sign_id)) {
      params = params.append('sign_id', sign_id.toString());
    }
    if (!_.isNil(behavior_type)) {
      params = params.append('behavior_type', behavior_type.toString());
    }
    if (!_.isNil(select_type)) {
      params = params.append('select_type', select_type.toString());
    }

    const uri = `image`;
    const url = `${this.domain}/${uri}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      }),
      params: params
    };
    return this.http.get<ApiResponse<PagableData<MediaInfo[]>>>(url, httpOptions);
    // let url = 'assets/data/owner.json';
    // return this.http.get<ApiResponse<PagableData<Owner[]>>>(url);
  }

  getImageRatio() {
    const uri = `image/ratio`;
    const url = `${this.domain}/${uri}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      })
    };
    return this.http.get<ApiResponse<string[]>>(url, httpOptions);
    // let url1 = 'assets/data/ratio.json';
    // return this.http.get<ApiResponse<string[]>>(url1);
  }
  addImage(req?: ReqAddImage) {
    const uri = `image`;
    const url = `${this.domain}/${uri}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      })
    };
    return this.http.post<ApiResponse<any>>(url, req, httpOptions);
  }
  editImage(req: ReqAddImage, id?: number): Observable<ApiResponse<any>> {
    const uri = `image/${id}`;
    const url = `${this.domain}/${uri}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      })
    };
    return this.http.put<ApiResponse<any>>(url, req, httpOptions);
  }
  deleteImage(id: number): Observable<ApiResponse<any>> {
    const uri = `image/${id}`;
    const url = `${this.domain}/${uri}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      })
    };
    return this.http.delete<ApiResponse<any>>(url, httpOptions);
  }
  deleteImageEditStatus(req: ReqAddImage, id?: number): Observable<ApiResponse<any>> {
    req.status = StatusEnum.Delete;
    const uri = `image/${id}`;
    const url = `${this.domain}/${uri}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      })
    };
    return this.http.put<ApiResponse<any>>(url, req, httpOptions);
  }

  getImageTexts(image_id?: number, text_id?: number): Observable<ApiResponse<SignEventMediaTextItem[]>> {
    let params = new HttpParams();
    if (!_.isNil(text_id)) {
      params = params.append('text_id', text_id.toString());
    }

    const uri = `image/${image_id}/text`;
    const url = `${this.domain}/${uri}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      }),
      params: params
    };
    return this.http.get<ApiResponse<SignEventMediaTextItem[]>>(url, httpOptions);
  }
  addImageText(req?: SignEventMediaTextItem, image_id?: number) {
    const uri = `image/${image_id}/text`;
    const url = `${this.domain}/${uri}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      })
    };
    return this.http.post<ApiResponse<any>>(url, req, httpOptions);
  }
  addImageTexts(req?: SignEventMediaTextItem[], image_id?: number) {
    const req1 = { text_items:req }; 
    const uri = `image/${image_id}/text`;
    const url = `${this.domain}/${uri}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      })
    };
    return this.http.post<ApiResponse<any>>(url, req1, httpOptions);
  }
  editImageText(req: SignEventMediaTextItem, image_id?: number,id?: number): Observable<ApiResponse<any>> {
    const uri = `image/${image_id}/text/${id}`;
    const url = `${this.domain}/${uri}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      })
    };
    return this.http.put<ApiResponse<any>>(url, req, httpOptions);
  }
  deleteImageText(image_id?: number,id?: number): Observable<ApiResponse<any>> {
    const uri = `image/${image_id}/text/${id}`;
    const url = `${this.domain}/${uri}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      })
    };
    return this.http.delete<ApiResponse<any>>(url, httpOptions);
  }
}
