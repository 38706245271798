<div class="row pt-3 pr-3 pl-3">
  <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
    <div>ชื่อป้าย :</div>
    <div>
      <div class="flex-fill"><input type="text" class="form-control w-100" placeholder="" maxlength="50"
          [(ngModel)]="search_name" [ngModelOptions]="{standalone: true}"></div>
    </div>
  </div>
  <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
    <div>ทางหลวง :</div>
    <div>
      <kendo-combobox class="w-100" [data]="data_route" [allowCustom]="allowCustom" [textField]="" [valueField]=""
        [value]="search_route" (valueChange)="handleValueRoute($event)" (filterChange)="handleFilterRoute($event)"
        [placeholder]="'ทั้งหมด'" [filterable]="true">
      </kendo-combobox>
    </div>
  </div>
  <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
    <div>ช่วงกิโลเมตร :</div>
    <div class="d-flex align-items-center">
      <div class="flex-fill"><span class="nowrap mr-1">เริ่มต้น :</span></div>
      <div class="flex-fill"><input type="text" class="form-control w-100" placeholder="กม." numbersOnly maxlength="4"
          [(ngModel)]="search_km_st" [ngModelOptions]="{standalone: true}"></div>
      <div class="flex-fill ml-1 mr-1"> + </div>
      <div class="flex-fill"><input type="text" class="form-control w-100" placeholder="ม." numbersOnly maxlength="3"
          [(ngModel)]="search_m_st" [ngModelOptions]="{standalone: true}"></div>
      <div class="flex-fill"><span class="nowrap ml-1 mr-1">สิ้นสุด :</span></div>
      <div class="flex-fill"><input type="text" class="form-control w-100" placeholder="กม." numbersOnly maxlength="4"
          [(ngModel)]="search_km_en" [ngModelOptions]="{standalone: true}"></div>
      <div class="flex-fill ml-1 mr-1"> + </div>
      <div class="flex-fill"><input type="text" class="form-control w-100" placeholder="ม." numbersOnly maxlength="3"
          [(ngModel)]="search_m_en" [ngModelOptions]="{standalone: true}"></div>
    </div>
  </div>
  <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
    <div>สถานะ :</div>
    <div>
      <kendo-combobox class="w-100" [data]="status_list" [allowCustom]="allowCustom" [textField]="'name'"
        [filterable]="true" [valueField]="'id'" [value]="search_status" (valueChange)="handleValueStatus($event)"
        [placeholder]="'ทั้งหมด'">
      </kendo-combobox>
    </div>
  </div>
  <div class="col-xl-12 col-lg-8 col-md-12 d-flex align-items-end justify-content-end">
    <div class="mt-1">
      <button type="button" (click)="search()" class="btn btn-primary pull-right text-white ml-1"> <i
          class="fa fa-search"></i>
        ค้นหา </button>
    </div>
  </div>
  <div class="col-12 mt-3 table-header pl-3 pr-3 pt-2 pb-2 d-flex align-items-center justify-content-between">
    <div class="btn-group btn-group-toggle" data-toggle="buttons">
      <label class="btn text-white pointer" [ngClass]="(viewType == 1)?'btn-primary':'btn-dark'">
        <input type="radio" name="options111" autocomplete="off" [value]="1" [(ngModel)]="viewType"
          (ngModelChange)="changeView($event)"> <i class="fa fa-bars mr-1"></i>List
      </label>
      <label class="btn text-white pointer" [ngClass]="(viewType == 2)?'btn-primary':'btn-dark'">
        <input type="radio" name="options111" autocomplete="off" [value]="2" [(ngModel)]="viewType"
          (ngModelChange)="changeView($event)"><i class="fa fa-map mr-1"></i> Map
      </label>
    </div>
    <button *ngIf="isAddSign" (click)="openAddBoardModal()" type="button" class="btn btn-primary text-white"> <i
        class="fa fa-plus"></i>
      เพิ่มป้าย VSW</button>
  </div>
  <div class="col-12 p-0 table-panel-body-height scrollbar-style">
    <div class="p-3" *ngIf="viewType == 1">
      <table class="table table-outline mb-0">
        <thead class="thead-dark" style="background-color: black;">
          <tr style="height: 50px;" class="align-items-center">
            <th class="text-center text-light"><i class="fa fa-hashtag"></i></th>
            <th class="text-center text-light">การเชื่อมต่อ</th>
            <th class="text-center text-light">ชื่อป้าย</th>
            <th class="text-center text-light">ตำแหน่ง</th>
            <th class="text-center text-light">กลุ่ม</th>
            <th class="text-center text-light">วันที่แก้ไขล่าสุด</th>
            <th *ngIf="isAutoSuggest" class="text-center text-light">Auto Suggestion</th>
            <th class="text-center text-light">จัดการ</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngIf="!this.is_loading && itemList?.length == 0">
            <td colspan="10" style="height: 50px;">
              ไม่พบข้อมูล
            </td>
          </tr>
          <tr *ngIf="this.is_loading">
            <td colspan="10" style="height: 50px;">
              กำลังโหลด ..
            </td>
          </tr>
          <!-- <tr *ngFor="let item of data_list;index as i;">
              <td class="text-center">
                <div>
                  {{(i+1)+((this.currentPage - 1)*itemsPerPage)}}
                </div>
              </td>
              <td class="text-center">
                <i tooltip="Active" placement="top" *ngIf="item?.status == statusEnum.Active"
                  class="fa fa-circle text-success"></i>
                <i tooltip="Inctive" placement="top" *ngIf="item?.status == statusEnum.Inactive"
                  class="fa fa-circle text-danger"></i>
              </td>
              <td class="text-center">
                {{item?.name}}
              </td>
              <td class="text-center">
                ทล. {{item?.route | number:'1.0-0'}} ({{item?.km}}+{{item?.m | number:'3.0-0'}})
              </td>
              <td class="text-center">
                {{item?.prov_str}}
              </td>
              <td class="text-center">
                <div style="font-size: 12px;">{{item?.created_datetime | date: 'dd/MM/yyyy'}}</div>
              </td>
  
              <td class="text-center">
                <div class="d-flex align-items-center justify-content-center">
                  <button title="แก้ไข" type="button" class="btn btn-sm btn-warning mr-1 text-light">
                    <i class="cui-note icons"></i>
                  </button>
                  <button title="ลบ" type="button" class="btn btn-sm btn-danger ml-1">
                    <i class="cui-trash icons"></i>
                  </button>
                </div>
              </td>
            </tr> -->
          <ng-template ngFor let-p [ngForOf]="itemList" let-i="index">
            <tr class="bg-gray-900 table-no-border sign-dir-text" style="border-width: 0">
              <!-- <td class="d-flex justify-content-center" style="min-width: 50px;">
                  <div>
                    {{(i+1)+((this.currentPage - 1)*itemsPerPage)}}
                  </div>
                </td> -->
              <td class="d-flex justify-content-center">
                <label title="เลือกป้าย" class="checkbox-container">
                  <input type="checkbox" [value]="p.id" [(ngModel)]="p.check" (change)="checkValue($event,p?.id)">
                  <span class="checkmark-box"></span>
                </label>
              </td>
              <td>
                <div>
                  <!-- <i title="Online" class="fa fa-2x fa-wifi text-success"></i> -->
                  <!-- <i title="Offline" class="fa fa-2x fa-wifi text-danger"></i> -->
                  <span class="fa fa-2x fa-wifi" [ngClass]="{ 'text-success': p?.connection_status == connectionStatusEnum.Online,
                    'text-danger':p?.connection_status == connectionStatusEnum.Offline ,
                    'text-warning':p?.connection_status == connectionStatusEnum.TmpOffline,
                    'text-dark':p?.connection_status == null}" [title]="(p?.connection_status == connectionStatusEnum.Online)? 'Online' : 
                    (p?.connection_status == connectionStatusEnum.Offline)?'Offline':
                    (p?.connection_status == connectionStatusEnum.TmpOffline)?'':''">
                  </span>
                </div>
              </td>
              <td>
                <div class="d-flex justify-content-start align-items-center">
                  <div class="mr-1 rounded-circle bg-info d-flex justify-content-center align-items-center"
                    style="width:auto;height:auto;min-height:32px;min-width:32px">
                    <strong>{{(i+1)+((this.currentPage - 1)*itemsPerPage)}}</strong></div>
                  <div> {{p?.name}}</div>
                </div>
              </td>
              <td>
                <div>{{p?.station?.station_name}}</div>
              </td>
              <td>
                <div>{{p?.device_group?.group_name}}</div>
              </td>
              <td class="text-center">
                <div>{{p?.lastupdate_date| date:'yyyy.MM.dd HH:mm'}}</div>
              </td>
              <td *ngIf="isAutoSuggest">
                <div class="d-flex align-items-center justify-content-center">
                  <label *ngIf="switch_show" class="switch switch-label switch-sm switch-pill switch-success b-0">
                    <input type="checkbox" class="switch-input" [value]="1" [ngModel]="p.auto_suggestion"
                      (change)="openChangeAutoSuggestModal(p)">
                    <span class="switch-slider" data-checked="On" data-unchecked="Off"></span>
                  </label>
                  <label *ngIf="!switch_show" class="switch switch-label switch-sm switch-pill switch-success b-0">
                    <input type="checkbox" class="switch-input" checked=false>
                    <span class="switch-slider" data-checked="On" data-unchecked="Off"></span>
                  </label>
                </div>
              </td>
              <td class="text-center">
                <div class="d-flex align-items-center justify-content-center">
                  <button *ngIf="isFirmware" (click)="openFirmwareModal(p)" class="btn btn-sm bg-blue ml-1 b-0 "><i
                      class="icon-cloud-upload"></i></button>
                  <!-- <button *ngIf="isSignSetting" (click)="openSignSettingModal(p)" class="btn btn-sm bg-purple ml-1 b-0">
                    <i class="fa fa-cog"></i></button> -->
                  <!-- <button *ngIf="isFavorite" (click)="openFavoriteModal(p)" [disabled]="p?.signs?.length == 0"
                    title="Favorite" type="button" class="btn btn-sm btn-primary1 bg-dark-blue ml-1 text-light">
                    <i class="icon-star"></i>
                  </button> -->
                  <button *ngIf="isCalendar" (click)="goToSchedule(p?.id)" title="Schedule" type="button"
                    class="btn btn-sm btn-success ml-1 b-0 text-light">
                    <i class="icon-calendar"></i>
                  </button>
                  <button *ngIf="isMa" (click)="openAddMaModal(p)" title="แจ้งซ่อม" type="button"
                    class="btn btn-sm btn-info ml-1 text-light b-0">
                    <i class="cui-wrench icons"></i>
                  </button>
                  <!-- <button title="รายละเอียด" (click)="clickItemOpenInfoLayer(p)" type="button"
                      class="btn btn-sm btn-success text-light ml-1 mr-1">
                      <i class="icon-info"></i>
                    </button> -->
                  <button *ngIf="isEditSign" title="แก้ไข" (click)="openBoardsignManageModal(p)" type="button"
                    class="btn btn-sm btn-warning text-light b-0 ml-1">
                    <i class="cui-note icons"></i>
                  </button>
                  <button *ngIf="isDeleteSign" title="ลบ" (click)="openDeleteBoardModal(p)" type="button"
                    class="btn btn-sm b-0 btn-danger ml-1">
                    <i class="cui-trash icons"></i>
                  </button>
                </div>
              </td>
            </tr>
            <tr style="min-height: 150px;" class="bg-gray-400">
              <td style="height: 50px !important;" class="text-center text-dark" colspan="10"
                *ngIf="!(p?.signs?.length>0)">
                <div>ยังไม่มีป้ายแสดงภาพที่ตำแหน่งนี้</div>
              </td>
              <td class="text-center" colspan="10" *ngIf="(p?.signs?.length>0)">
                <!-- <div class="d-flex justify-content-between" [hidden]="true">
                    <div>
                      <div class="sign-dir-text mb-1 text-dark">ป้ายหน้า</div>
                      <div class="d-flex justify-content-start flex-wrap">
                        <div *ngFor="let s of p?.signs;index as ii;">
                          <div class="sign-box" [style.width.px]="get_sign_width(s?.board_type_obj?.aspect_ratio)"
                            [style.height.px]="set_sign_height" *ngIf="s?.direction == signDir.Front">
  
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div class="sign-dir-text mb-1 text-dark">ป้ายหลัง</div>
                      <div class="d-flex justify-content-start flex-wrap">
                        <div *ngFor="let s of p?.signs;index as ii;">
                          <div class="sign-box" [style.width.px]="get_sign_width(s?.board_type_obj?.aspect_ratio)"
                            [style.height.px]="set_sign_height" *ngIf="s?.direction == signDir.Back">
  
                          </div>
                        </div>
                      </div>
                    </div>
  
                  </div> -->
                <div *ngIf="this.boardItem.id == p?.id && is_loading_fav"
                  class="d-flex align-items-center justify-content-center">
                  <div class="lds-dual-ring"></div>
                </div>
                <div class="d-flex justify-content-start">
                  <span *ngIf="p?.status == this.statusEnum.Active" class="badge badge-success">Active</span>
                  <span *ngIf="p?.status == this.statusEnum.Inactive" class="badge badge-danger">Defective</span>
                  <span *ngIf="p?.status == this.statusEnum.Ma" class="badge badge-warning">MA</span>
                </div>
                <div class="d-flex justify-content-around mb-2">
                  <div>
                    <div class="sign-dir-text mb-1 text-dark d-flex justify-content-center align-items-center">
                      <div *ngFor="let l of p?.layers;index as ii;">
                        <div class="d-flex justify-content-center flex-wrap">
                          <div *ngFor="let sign of l;index as iii;">
                            <div class="sign-box" [style.width.px]="get_sign_width(sign?.ratio,set_sign_height_small)"
                              [style.height.px]="set_sign_height_small" *ngIf="sign?.dir == signDir.Front">
                              <div [style.height.px]="set_sign_height_small"
                                style="background-color: black;position: relative;"
                                [style.width.px]="get_sign_width(sign?.ratio,set_sign_height_small)">
                                <img *ngIf="p?.vsw?.img_url" [src]="p?.vsw?.img_url" width="100%" height="100%"
                                  (error)="onImgError($event)"
                                  style="position: absolute;left: 0px;top: 0px;width: 100%;height: 100%;" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <span class="ml-1"><strong>Last Checked :
                          {{p?.vsw?.updated_datetime | date:'HH:mm dd/MM/yyyy'}}</strong></span>
                    </div>
                  </div>
                  <div>
                    <div class="sign-dir-text mb-1 text-dark d-flex justify-content-center align-items-center">

                      <div *ngFor="let l of p?.layers;index as ii;">
                        <div class="d-flex justify-content-center flex-wrap">
                          <div *ngFor="let sign of l;index as iii;">
                            <div class="sign-box" [style.width.px]="get_sign_width(sign?.ratio,set_sign_height_small)"
                              [style.height.px]="set_sign_height_small" *ngIf="sign?.dir == signDir.Front">
                              <app-sign-image-item [media]="sign?.media?.media" [sign_height]="set_sign_height_small"
                                [ratio]="sign?.ratio" [is_board]="true" [config_road]="p?.config_road" [rain]="p?.rain"
                                [resize]="(set_sign_height_small/sign?.sign_type_object?.height)"
                                *ngIf="sign?.media?.media?.path && (sign?.media?.media_type == mediaType.Image || sign?.media?.media_type == mediaType.Gif)">
                              </app-sign-image-item>
                              <app-playlist-item [show_count]="true" [config_road]="p?.config_road" [rain]="p?.rain"
                                [resize]="(set_sign_height_small/sign?.sign_type_object?.height)"
                                *ngIf="sign?.media?.items && sign?.media?.media_type == mediaType.Playlist"
                                [is_board]="true" [playlist]="sign?.media?.items" [height]="set_sign_height_small"
                                [width]="get_sign_width(sign?.ratio,set_sign_height_small)">
                              </app-playlist-item>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="ml-1 text-left">
                        <div *ngIf="p?.signs.length > 0"><strong>Last Update by :
                            {{p?.signs[0]?.updated_by_name}}</strong></div>
                        <div *ngIf="p?.signs.length > 0">
                          <strong>{{p?.signs[0]?.lastupdate_date | date:'HH:mm dd/MM/yyyy'}}</strong></div>
                      </div>
                    </div>
                  </div>

                </div>

              </td>
            </tr>
          </ng-template>
        </tbody>
      </table>
    </div>

    <div class="map" id="map-sign" [ngClass]="(viewType == 2) ? 'map' : 'map-hide'"></div>
  </div>
  <div class="col-12 p-0 table-header d-flex align-items-center justify-content-between">
    <div *ngIf="viewType == 1" class="ml-3 mr-2">
      <pagination [totalItems]="totalItems" [maxSize]="5" [itemsPerPage]="itemsPerPage" [(ngModel)]="currentPage"
        (pageChanged)="pageChanged($event)" class="pagination p-0 m-0" previousText="&lsaquo;" nextText="&rsaquo;"
        [boundaryLinks]="true" firstText="&laquo;" lastText="&raquo;"></pagination>
    </div>
    <div [ngClass]="(viewType == 2) ? 'ml-3' : ''">total : {{totalItems}}</div>
    <div class="ml-2 mr-3 d-flex align-items-center">
      <button *ngIf="board_select_list.length>0" type="button" class="btn btn-secondary ml-1 mr-1"
        (click)="clickClearBoardsignSelect()">
        Clear </button>
      <button *ngIf="isFirmware" type="button" class="btn bg-blue text-white mr-1"
        (click)="openUploadFirmwareModal(board_select_list)" [disabled]="board_select_list.length==0"> <span
          class="mr-1" *ngIf="board_select_list.length>0">{{board_select_list.length}}</span> <i
          class="icon-cloud-upload"></i> Firmware update</button>
      <button *ngIf="isChangeMedia" type="button" class="btn btn-danger text-white ml-1"
        (click)="openChangeMediaModal()" [disabled]="board_select_list.length==0"> <span class="mr-1"
          *ngIf="board_select_list.length>0">{{board_select_list.length}}</span> <i class="fa fa-eye"></i>
        เปลี่ยนป้าย</button>
    </div>
  </div>
</div>

<div bsModal #addModal="bs-modal" class="modal fade modal-md" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
  aria-hidden="true" (onHide)="clearData()" [config]="{backdrop: 'static'}"
  [ngClass]="(selectItem?.id)?'modal-edit-boardsign':''">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header bg-dark bg-gray-900">
        <h4 class="modal-title"> <i class="icon-info mr-2"></i><span *ngIf="!selectItem?.id">เพิ่มป้าย VSW</span><span
            *ngIf="selectItem?.id">แก้ไขป้าย</span></h4>
        <button type="button" class="close text-light" (click)="addModal.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form class="form-horizontal mt-2 mb-2">

          <div class="form-group row">
            <label class="col-md-4 col-form-label" for="text-input"> <span
                class="float-sm-left float-md-right">Station<span class="text-danger">*</span> :</span></label>
            <div class="col-md-8">
              <kendo-combobox class="w-100" [data]="data_station_modal" [allowCustom]="allowCustom" [textField]="'name'"
                [valueField]="'id'" [value]="station_modal" (valueChange)="handleValueStationModal($event)"
                (filterChange)="handleFilterStationModal($event)" [placeholder]="'โปรดระบุ'" [filterable]="true">
              </kendo-combobox>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-4 col-form-label" for="text-input"> <span
                class="float-sm-left float-md-right">กลุ่มอุปกรณ์<span class="text-danger">*</span> :</span></label>
            <div class="col-md-8">
              <div class="form-control">
                VSW
              </div>
              <!-- <kendo-combobox class="w-100" [data]="data_device_group_modal" [allowCustom]="allowCustom"
                [textField]="'name'" [valueField]="'id'" [value]="device_group_modal"
                (valueChange)="handleValueDeviceGroupModal($event)" [filterable]="true"
                (filterChange)="handleFilterDeviceGroupModal($event)" [placeholder]="'โปรดระบุ'">
              </kendo-combobox> -->
            </div>
          </div>

          <div class="form-group row">
            <label class="col-md-4 col-form-label" for="text-input"> <span
                class="float-sm-left float-md-right">ชื่อป้าย<span class="text-danger">*</span> :</span></label>
            <div class="col-md-8">
              <input type="text" [(ngModel)]="selectItem.name" name="text-name" class="form-control" placeholder=""
                maxlength="50">
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-4 col-form-label" for="select1"> <span class="float-sm-left float-md-right">Amount of
                lane<span class="text-danger">*</span>
                :</span></label>
            <div class="col-md-8">
              <kendo-combobox class="w-100" [data]="lane_list" [allowCustom]="allowCustom" [textField]="''"
                [valueField]="''" [value]="lane_modal" (valueChange)="handleValueLaneModal($event)"
                [placeholder]="'โปรดระบุ'" [filterable]="true">
              </kendo-combobox>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-4 col-form-label" for="select1"> <span class="float-sm-left float-md-right">สถานะ<span
                  class="text-danger">*</span>
                :</span></label>
            <div class="col-md-8">
              <kendo-combobox class="w-100" [data]="status_list" [allowCustom]="allowCustom" [textField]="'name'"
                [valueField]="'id'" [value]="status_modal" (valueChange)="handleValueStatusModal($event)"
                [placeholder]="'โปรดระบุ'" [filterable]="true">
              </kendo-combobox>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-4 col-form-label" for="select1"></label>
            <div class="col-md-8">
              <label class="checkbox-container">
                <span><strong>Auto Suggestion</strong></span>
                <input type="checkbox" [(ngModel)]="selectItem.auto_suggestion" [ngModelOptions]="{standalone: true}">
                <span class="checkmark-box"></span>
              </label>
            </div>
          </div>
        </form>
        <div class="d-flex justify-content-end mt-2 mb-2">
          <button type="button" class="btn btn-secondary" (click)="addModal.hide()">ยกเลิก</button>
          <!-- <button type="button" class="btn btn-primary ml-2" *ngIf="actionType==1" (click)="addType()" [disabled]="!newsTypeItem?.news_type_name">สร้าง</button>
            <button type="button" class="btn btn-primary ml-2" *ngIf="actionType==2" (click)="editType()">แก้ไข</button> -->
          <button
            [disabled]="!(selectItem.name && status_modal != null && lane_modal != null && station_modal != null && vsw_group_id != null)"
            *ngIf="selectItem?.id" type="button" class="btn btn-primary text-white ml-2"
            (click)="editBoard()">แก้ไข</button>
          <button
            [disabled]="!(selectItem.name && status_modal != null && lane_modal != null && station_modal != null && vsw_group_id != null)"
            *ngIf="!selectItem?.id" type="button" class="btn btn-primary text-white ml-2"
            (click)="addBoardAndVswDevice()">เพิ่ม</button>
        </div>

      </div>
      <!-- <div class="modal-footer">
            
          </div> -->
    </div>
  </div>
</div>
<div bsModal #deleteModal="bs-modal" class="modal fade modal-md" tabindex="-1" role="dialog"
  aria-labelledby="myModalLabel" aria-hidden="true" (onHide)="clearData()" [config]="{backdrop: 'static'}">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header bg-dark bg-gray-900">
        <h4 class="modal-title"> <i class="icon-info mr-2"></i>ลบป้าย</h4>
        <button type="button" class="close text-light" (click)="deleteModal.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="mt-2 text-center">
          <h4>คุณต้องการลบใช่หรือไม่</h4>
        </div>
        <div class="mt-3 mb-4 text-center">
          <h5>{{selectItem?.name}}</h5>
        </div>
        <div class="d-flex justify-content-end mt-2 mb-2">
          <button type="button" class="btn btn-secondary" (click)="deleteModal.hide()">ยกเลิก</button>
          <!-- <button type="button" class="btn btn-primary ml-2" (click)="deleteType(newsTypeItem.id)">ยืนยัน</button> -->
          <button type="button" class="btn btn-primary text-white ml-2" (click)="deleteBoard()">ยืนยัน</button>
        </div>

      </div>
      <!-- <div class="modal-footer">
            
          </div> -->
    </div>
  </div>
</div>
<div bsModal #firmwareModal="bs-modal" class="modal fade modal-md" tabindex="-1" role="dialog"
  aria-labelledby="myModalLabel" aria-hidden="true" (onHide)="clearData()" [config]="{backdrop: 'static'}">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header bg-dark bg-gray-900">
        <h4 class="modal-title"> <i class="icon-cloud-upload mr-2"></i>Firmware update</h4>
        <button type="button" class="close text-light" (click)="firmwareModal.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form class="form-horizontal mt-2 mb-2">
          <div class="form-group row">
            <label class="col-md-4 col-form-label"> <span class="float-sm-left float-md-right">ชื่อป้าย :</span></label>
            <label class="col-md-8 col-form-label">
              <span class="float-left">{{firmware_select?.boardsign_name}}</span>
            </label>
          </div>
          <div class="form-group row">
            <label class="col-md-4 col-form-label"> <span class="float-sm-left float-md-right">Version :</span></label>
            <label class="col-md-8 col-form-label">
              <span class="float-left">{{firmware_select?.version}}</span>
            </label>
          </div>
          <div class="form-group row">
            <label class="col-md-4 col-form-label"> <span class="float-sm-left float-md-right">Description
                :</span></label>
            <label class="col-md-8 col-form-label">
              <span class="float-left">{{firmware_select?.desc}}</span>
            </label>
          </div>
          <div class="form-group row">
            <label class="col-md-4 col-form-label"> <span class="float-sm-left float-md-right">Date :</span></label>
            <label class="col-md-8 col-form-label">
              <span class="float-left">{{firmware_select?.ts | date:'dd/MM/yyyy'}}</span>
            </label>
          </div>
        </form>
        <div class="d-flex justify-content-end mt-2 mb-2">
          <button type="button" class="btn btn-secondary" (click)="firmwareModal.hide()">ปิด</button>
        </div>

      </div>
      <!-- <div class="modal-footer">
            
          </div> -->
    </div>
  </div>
</div>
<div bsModal #uploadFirmwareModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
  aria-labelledby="myModalLabel" aria-hidden="true" (onHide)="clearData()" [config]="{backdrop: 'static'}">
  <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
    <div class="modal-content">
      <div class="modal-header bg-dark bg-gray-900">
        <h4 class="modal-title"> <i class="icon-cloud-upload mr-2"></i>Firmware update</h4>
        <button type="button" class="close text-light" (click)="uploadFirmwareModal.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form class="form-horizontal mb-3 p-3 bg-gray-850 rounded"
          *ngFor="let item of firmware_select_list;index as i;">
          <div class="row">
            <label class="col-md-4 col-form-label"> <span class="float-sm-left float-md-right">ชื่อป้าย :</span></label>
            <label class="col-md-8 col-form-label">
              <span class="float-left">{{item?.boardsign_name}}</span>
            </label>
          </div>
          <div class="row">
            <label class="col-md-4 col-form-label"> <span class="float-sm-left float-md-right">Version :</span></label>
            <label class="col-md-8 col-form-label">
              <span class="float-left">{{item?.version}}</span>
            </label>
          </div>
          <div class="row">
            <label class="col-md-4 col-form-label"> <span class="float-sm-left float-md-right">Description
                :</span></label>
            <label class="col-md-8 col-form-label">
              <span class="float-left">{{item?.desc}}</span>
            </label>
          </div>
          <div class="row">
            <label class="col-md-4 col-form-label"> <span class="float-sm-left float-md-right">Date :</span></label>
            <label class="col-md-8 col-form-label">
              <span class="float-left">{{item?.ts | date:'dd/MM/yyyy'}}</span>
            </label>
          </div>
        </form>
        <form class="form-horizontal mt-2 mb-3 p-3 bg-gray-850 rounded">
          <div class="row">
            <label class="col-md-4 col-form-label"> <span class="float-sm-left float-md-right">Firmware version <span
                  class="text-danger">*</span> :</span></label>
            <label class="col-md-8 col-form-label">
              <input type="text" class="form-control w-100" placeholder="" [(ngModel)]="firmware_input_version"
                [ngModelOptions]="{standalone: true}">
            </label>
          </div>
          <div class="row">
            <label class="col-md-4 col-form-label"> <span class="float-sm-left float-md-right">Description <span
                  class="text-danger">*</span> :</span></label>
            <label class="col-md-8 col-form-label">
              <input type="text" class="form-control w-100" placeholder="" [(ngModel)]="firmware_input_desc"
                [ngModelOptions]="{standalone: true}">
            </label>
          </div>
          <div class="row">
            <label class="col-md-4 col-form-label"> <span class="float-sm-left float-md-right">Firmware <span
                  class="text-danger">*</span> :</span></label>
            <label class="col-md-8 col-form-label">
              <input type="file" name="file-input" #fileInput (change)="detectFirmwareFiles($event)">
              <!-- accept=".png,.jpg,.gif" -->
            </label>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="d-flex justify-content-end mt-2 w-100">
                <button type="button" class="btn btn-secondary mr-3"
                  (click)="uploadFirmwareModal.hide()">ยกเลิก</button>
                <button
                  [disabled]="!(firmware_input_file && firmware_input_desc && firmware_input_version && board_select_list.length>0)"
                  (click)="UploadFirmware(board_select_list)" type="button" class="btn btn-primary text-white"><i
                    class="icon-cloud-upload mr-1"></i>Upload</button>
              </div>
            </div>
          </div>
        </form>
        <!-- <div class="d-flex justify-content-end mt-3">
            <button type="button" class="btn btn-secondary" (click)="uploadFirmwareModal.hide()">ยกเลิก</button>
          </div> -->
      </div>
    </div>
  </div>
</div>
<div bsModal #changeAutoSuggestModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
  aria-labelledby="myModalLabel" aria-hidden="true" (onHide)="onHideChangeAutoSuggest()"
  [config]="{backdrop: 'static'}">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header bg-dark bg-gray-900">
        <h4 class="modal-title"> <i class="icon-info mr-2"></i><span>เปลี่ยน Auto Suggestion Mode</span></h4>
        <button type="button" class="close text-light" (click)="changeAutoSuggestModal.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="d-flex justify-content-center align-items-center" style="min-height: 100px;">
          <div class="text-center">
            <h5>ต้องการ
              <span *ngIf="selectItem?.auto_suggestion"> ปิด Auto Suggest Mode</span>
              <span *ngIf="!selectItem?.auto_suggestion"> เปิด Auto Suggest Mode</span>
              ใช่หรือไม่?</h5>
            <h5 class="mt-3">{{selectItem?.name}}</h5>
          </div>
        </div>
        <div class="d-flex justify-content-end mt-2 mb-2">
          <button type="button" class="btn btn-secondary" (click)="changeAutoSuggestModal.hide()">ยกเลิก</button>
          <button *ngIf="selectItem?.id" type="button" class="btn btn-primary text-white ml-2"
            (click)="changeAutoSuggest(selectItem?.auto_suggestion?false:true,selectItem?.id)">บันทึก</button>
        </div>
      </div>
      <!-- <div class="modal-footer">
            
          </div> -->
    </div>
  </div>
</div>
<swal #swalSuccess title="<div class='text-light'>สำเร็จ</div>" type="success" text="" icon="success"></swal>
<swal #swalError title="<div class='text-light'>ไม่สำเร็จ</div>" html="<div class='text-light'>{{res_message}}</div>"
  type="error" icon="error"></swal>
<swal #swalInfo confirmButtonColor="#e4a723" html="<div class='text-light font-2'>{{res_message}}</div>" type="warning"
  icon="warning"></swal>

<app-sign-setting [clickOpen]="clickOpenSignSetting" [boardsignInfo]="selectboardsignInfo"
  (onClose)="closeSignSettingModal()"></app-sign-setting>
<app-boardsign-management [clickOpen]="clickOpenBoardsignManage" [isEditModalOpen]="addModal?._isShown" [isVsw]="true"
  [boardsignInfo]="selectBoardsignManageModal" [getBoard]="isGetBoard" (onClose)="closeBoardsignManageModal()"
  (onClickEditBoardsign)="openEditBoardModal($event)" [isAddSign]="isEditSignAdd" [isEditSign]="isEditSignEdit">
</app-boardsign-management>
<app-favorite [clickOpen]="clickOpenFavorite" [boardItem]="selectBoardsignFavModal" (onClose)="closeFavoriteModal()"
  [isAdd]="isFavoriteAdd" [isEdit]="isFavoriteEdit">
</app-favorite>
<app-change-media-panel [clickOpen]="clickOpenChangeMedia" [board_select_list]="board_select_list" [isVsw]="true"
  (onClose)="closeChangeMediaModal()"></app-change-media-panel>
<!-- <app-ma-management [clickOpen]="clickOpenAddMa" [view]="selectMaView" [maId]="selectMaId" (onClose)="closeAddMaModal()"
    (onSuccess)="addMaSuccess()" [isDescDevice]="true" [isDescMa]="true" [isAddMa]="true" [isMaLog]="true"
    [isAddMaLog]="true"></app-ma-management> -->
<app-ma-management [clickOpen]="clickOpenAddMa" [view]="selectMaView" [typeId]="typeId" [refId]="refId"
  (onClose)="closeAddMaModal()" (onSuccess)="addMaSuccess()" [isDescDevice]="true" [isDescMa]="true" [isAddMa]="true"
  [isMaLog]="true" [isAddMaLog]="true" [boardMaSelect]="boardMaSelect"></app-ma-management>