import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { MultiSelectComponent } from "@progress/kendo-angular-dropdowns";
import * as mapboxgl from 'mapbox-gl';
import { environment as env } from 'src/environments/environment';
import { EventService } from 'src/app/services/user/event.service';
import { StatusEnum } from 'src/app/models/common-enum';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { EventModalComponent } from '../event/event-modal/event-modal.component';
import * as _ from 'lodash';
import { interval } from 'rxjs';
import { CommonService } from 'src/app/services/common.service';
import { ActionEnum } from 'src/app/models/common-enum';
import { DeviceService } from 'src/app/services/user/device.service';
@Component({
  selector: 'app-event-map',
  templateUrl: './event-map.component.html',
  styleUrls: ['./event-map.component.scss']
})
export class EventMapComponent implements OnInit, OnDestroy {
  @ViewChild("multiselect") public route_m!: MultiSelectComponent;
  subscription: any;
  modalRef: BsModalRef;
  
  map: any;
  routeItems: Array<string> = [];
  route: any = [];
  route_select : any[] = []
  sTypeItems: any = [];
  sType: any = [];
  sType_select : any[] = []
  StatusItems: any = [];
  Status: any = [];
  Status_select : any[] = []
  
  eventListFeatures : any[] = []
  lists:any[]=[]
  st_km? : number
  en_km? : number
  ll_min : any = [100,13]
  ll_max : any = [100,13]
  loading : boolean = true
  // statusEnum = StatusEnum;
  // map: mapboxgl.Map;
  // event_layer: any = 'WeightStationLayer';
  // event_src: any = 'WeightStationSrc';
  // event_src_url: any = '/assets/data/station.json';
  // eventListFeatures: any[] = [];
  // eventListAllFeatures: any[] = [];
  // eventList: any = [];
  // is_loading = false;
  // allType: any;
  // list: any;

  // type_list: any[] = [];
  // type_list_old: any[] = [];

  // search_route: string;
  // public data_route: Array<string>;
  // route_list: string[];
  // st_km: any;
  // en_km: any;
  // is_first_load: boolean = true;
  // public allowCustom: boolean = false;
  constructor(
    private eS: EventService, 
    private modalService: BsModalService, 
    private commonService: CommonService) {
    
  }

  ngOnInit(): void {
    // this.initMap();
    // this.getEventMap();
    this.getRoute();
    this.getsType();
    this.getStatus();
    this.initMap();
    // this.subscription = interval(1 * 60000).subscribe(async (x) => {
    //   await this.getEventMap();
    //   this.updateMap()
    // });
  }
  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
  
  async eventMap(data:any){

    this.eventListFeatures = []
    this.lists = []
    let res : any =await this.eS.getmapEvt(data)
    if(res){
      this.lists = _.cloneDeep(res)
      for (const i of res) {
        if(!i.lat || !i.lng) continue;
        if(i.lng-0.2<=this.ll_min[0]) this.ll_min[0] = i.lng - 0.2
        if(i.lat-0.2<=this.ll_min[1]) this.ll_min[1] = i.lat - 0.2
        if(i.lng+0.2>this.ll_max[0]) this.ll_max[0] = i.lng + 0.2
        if(i.lat+0.2>this.ll_max[1]) this.ll_max[1] = i.lat + 0.2
        this.eventListFeatures.push(this.geo_feature(i))
      }
      this.updateMap();
    }else{this.loading = false}
  }
  async getRoute() {
    let res : any = await this.eS.getRoute();
    if(res){
      this.routeItems = res
      this.route_select = _.cloneDeep(this.routeItems);
    }
    // this.commonService.getRoute().subscribe(
    //   res => {
    //     if (res) {
    //       if (res.code == 1) {
    //         if (res.data) {
    //           this.route_list = _.cloneDeep(res.data);
    //           this.data_route = _.cloneDeep(res.data);
    //         }
    //       }
    //     }
    //     //this.is_loading = false;
    //   },
    //   error => {
    //     //this.is_loading = false;
    //   });
  }
  async getsType(){
    let res : any = await this.eS.getmapEvtSubType({})
    if(res){
      this.sTypeItems = res
      this.sType_select = _.cloneDeep(this.sTypeItems);
    }
  }
  async getStatus(){
    let res : any = await this.eS.getmapEvtStatus({})
    if(res){
      this.StatusItems = res
      this.Status_select = _.cloneDeep(this.StatusItems);
    }
  }
  chgRoute(evt:any){
    this.routeItems = _.cloneDeep(this.route_select)
    this.routeItems = this.routeItems.filter((val:any)=>val.toLowerCase().indexOf(evt.toLowerCase()) !== -1)
    this.route_m.toggle(true);
  }
  chgsType(evt:any){
    this.sTypeItems = _.cloneDeep(this.sType_select)
    this.sTypeItems = this.sTypeItems.filter((val:any)=>val.name.toLowerCase().indexOf(evt.toLowerCase()) !== -1)
  }
  chgStatus(evt:any){
    this.StatusItems = _.cloneDeep(this.sType_select)
    this.StatusItems = this.StatusItems.filter((val:any)=>val.name.toLowerCase().indexOf(evt.toLowerCase()) !== -1)
  }
  search(){
    this.loading = true
    let data : any = {
      page:1,limit:100000000,status:'1,2,3'
      // status:'1,2,3',
    }
    if(this.route.length) data.route = this.route.map((x:any)=>Number(x))
    if(this.st_km) data.st_km = this.st_km
    if(this.en_km) data.en_km = this.en_km
    if(this.sType.length) data.subtype_id = this.sType.map((x:any) => x.id);
    if(this.Status.length) data.status = this.Status.map((x:any) => x.id);
    this.eventMap(data)
  }
  flytoI(i:any){
    if(i.lng&&i.lat) this.map.flyTo({ center: [i.lng,i.lat], zoom: 12 });
  }
  //Map ---------------------------------------
  
  async initMap(){
    this.map = new mapboxgl.Map({
      accessToken:env.mapbox.accessToken,
      container: 'map-event',
      style: env.mapbox.style,
      zoom: 8,
      center: [100.523186, 13.736717],
    });
    this.map.dragRotate.disable();

    this.map.on('load', (event:any) => {
      
      this.map.setPaintProperty('water', 'fill-color', '#aad3df');

      this.map.loadImage('assets/img/event/1.png', (error:any, image:any) => {
        if (error) { throw error };
        this.map.addImage(`pin-`, image);
      });
      this.map.loadImage('assets/img/event/1.png', (error:any, image:any) => {
        if (error) { throw error };
        this.map.addImage(`pin-null`, image);
      });
      for(let i = 1;i<=18;i++){
        this.map.loadImage(`assets/img/event/${i}.png`, (error:any, image:any) => {
          if (error) { throw error };
          this.map.addImage(`pin-${i}`, image);
        });
      }

      // -----------------------
      this.map.addSource('MarkersEvt', {
        "type": 'geojson', 
        "data": {
          "type": "FeatureCollection",
          "features": [
            {
              "type": "Feature",
              "properties": {},
              "geometry": {
                "type": "Point",
                "coordinates": [100.946564,13.951342]
              }
            }
          ]
        }
      });
      // -----------------------
      
      this.map.addLayer({
        id: "MarkerLayer",
        source: 'MarkersEvt',
        type: 'symbol',
        layout: {
          'icon-allow-overlap': true,
          'icon-image': `pin-{img}`,
          'symbol-avoid-edges': true,
          'icon-ignore-placement': true,
          // 'icon-image': 'pin-event{src_type_id}{event_subtype_id}',
          "icon-size": 0.1,
          //'text-offset': [0, 0],
          "icon-offset": [0, -20],
          //'text-field': '{route}',
          //'text-size': 12,
          'text-allow-overlap': true,
          'text-transform': 'uppercase',
        },
        paint: {
          "text-color": "#ffffff"
        },
        // "filter": ["==", "$type", "Point"],
      });
      
      // this.eventMap({page:1,limit:100000000,status:'1,2,3'})
      this.eventMap({page:1,limit:100000000,status:'1,2,3'})
      
      const popup = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false, anchor: 'top', offset: [0, -75]
      });
      // ---------------------------------------
      this.map.on('click', "MarkerLayer", (event: any) => {
        this.map.flyTo({ center: event.features[0].geometry.coordinates, zoom: 12 });
        const p = event.features[0].properties;
        this.openModal(p);
      });
      this.map.on('mouseenter', "MarkerLayer", (e:any) => {
        this.map.getCanvas().style.cursor = 'pointer';
        const p = e.features[0].properties;
        const htmltext = `<div class="font-3" style="padding-top: 10px;padding-left: 10px;padding-right: 10px;"><h6>${p.subtype_name||"No subtype"}<br><small>(${p.lat},${p.lng})</smasll></h6></div>`;
        popup.setLngLat(e.features[0].geometry.coordinates)
          .setHTML(htmltext)
          .addTo(this.map);
      });
      this.map.on('mouseleave', "MarkerLayer", (e:any) => {
        this.map.getCanvas().style.cursor = '';
        popup.remove();
      });
      // ---------------------------------------

    })
  }
  
  geo_feature(vals:any){
    return {
      "type": "Feature",
      "properties": vals,
      "geometry": {
        "type": "Point",
        "coordinates": [vals.lng,vals.lat]
      }
    }
    
  }
  updateMap() {
    if (this.map.getSource('MarkersEvt') != null) {
      this.map.getSource('MarkersEvt').setData({
        'type': 'FeatureCollection',
        'features': this.eventListFeatures
      });
      this.map.fitBounds([
        this.ll_min, // southwestern corner of the bounds
        this.ll_max // northeastern corner of the bounds
      ]);
    }
    this.loading = false
  }
  // handleFilterRoute(value) {
  //   if (value.length > 0) {
  //     this.data_route = this.route_list.filter((s) => s.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  //   } else if (value.length == 0) {
  //     this.data_route = _.cloneDeep(this.route_list);
  //   } else {
  //     this.data_route = [];
  //   }
  // }
  // handleValueRoute(value) {
  //   if (value) {
  //     //console.log(value);
  //     this.search_route = value;
  //   } else {
  //     this.search_route = null;
  //   }
  //   this.clickType();
  // }

  // clickType(e?: any) {
  //   this.eventListFeatures = [];
  //   this.eventListAllFeatures.forEach(item => {
  //     const find = this.type_list.find(x => x.id == item.properties.event_group_id && x.checked == true);
  //     if (find) {
  //       this.eventListFeatures.push(item);
  //     }
  //   });
  //   if (this.search_route) {
  //     this.eventListFeatures = this.eventListFeatures.filter(x => parseInt(x.properties.st_route) == parseInt(this.search_route))
  //   }

  //   if (this.st_km) {
  //     //console.log("this.st_km2", this.st_km);
  //     this.eventListFeatures = this.eventListFeatures.filter(x => parseInt(x.properties.st_km) >= parseInt(this.st_km));
  //   }
  //   if (this.en_km) {
  //     //console.log("this.en_km2", this.en_km);
  //     this.eventListFeatures = this.eventListFeatures.filter(x => parseInt(x.properties.st_km) <= parseInt(this.en_km));
  //   }

  //   setTimeout(() => {
  //     this.updateMap()
  //   }, 100);
  // }
  // changeStKm(e) {
  //   this.clickType();
  // }
  // changeEnKm(e) {
  //   this.clickType();
  // }

  // -----------------------------------
  
  openModal(data?: any) {
    const initialState = {
      values: data,
      // list: this.list,
      // inc_type: this.allType
    };

    this.modalRef = this.modalService.show(EventModalComponent, { initialState, class: 'w-80', backdrop: 'static' });
    this.modalRef.content.event.subscribe(data => {
      // if (data) this.getEventMap();
    });
  }

  // async getEventMap() {
  //   this.is_loading = true;
  //   this.eventList = [];
  //   this.eventListFeatures = [];
  //   this.type_list_old = _.cloneDeep(this.type_list);
  //   this.type_list = [];
  //   let res = await this.eventService.getEvt({ _Ismap: 1 }).catch((err) => {
  //     console.log(err);
  //     this.is_loading = false;
  //   });
  //   console.log(">>",res)
  //   if (res) {
  //     this.eventListAllFeatures = [];
  //     let data: any = res;
  //     data.data.forEach(item => {
  //       // item.icon = item.current[0].weather[0].icon;
  //       // item.desc = item.current[0].weather[0].description;
  //       if (item.st_lng && item.st_lat) {
  //         const coordinates = [item.st_lng, item.st_lat];
  //         const geometry = { type: 'Point', coordinates: coordinates };
  //         const feature = { type: 'Feature', geometry: geometry, properties: item };
  //         if(item.id == 53123) console.log(">>>>",item)
  //         this.eventListAllFeatures.push(feature);
  //         if (item.event_group_id && item.event_group_name) {
  //           let checked = true;
  //           const find = this.type_list_old.find(x => x.id == item.event_group_id);
  //           if (find) {
  //             checked = find.checked;
  //           }
  //           this.type_list.push({ id: item.event_group_id, name: item.event_group_name, checked: checked });
  //         }
  //       }
  //     });
  //     this.type_list = _.uniqBy(this.type_list, 'id');
  //     console.log("type",this.type_list)
  //     this.eventListAllFeatures.forEach(item => {
  //       // console.log("0",item)
  //       if(item.geometry.coordinates[0]==13.5332348859049 || item.geometry.coordinates[1]==13.5332348859049) console.log("1",item)
  //       const find = this.type_list.find(x => x.id == item.properties.event_group_id && x.checked == true);
  //       if (find) {
  //         if(item.geometry.coordinates[0]==13.5332348859049 || item.geometry.coordinates[1]==13.5332348859049) console.log("2",item)
  //         if(item.properties.src_type_id==9) console.log("Rain",item)
  //         this.eventListFeatures.push(item);
  //       }
  //     });
  //     if (this.search_route) {
  //       this.eventListFeatures = this.eventListFeatures.filter(x => parseInt(x.properties.st_route) == parseInt(this.search_route));
  //     }

  //     if (this.st_km) {
  //       //console.log("this.st_km1", this.st_km);
  //       this.eventListFeatures = this.eventListFeatures.filter(x => parseInt(x.properties.st_km) >= parseInt(this.st_km));
  //     }
  //     if (this.en_km) {
  //       //console.log("this.en_km1", this.en_km);
  //       this.eventListFeatures = this.eventListFeatures.filter(x => parseInt(x.properties.st_km) <= parseInt(this.en_km));
  //     }
  //     this.eventList = data;

  //     if (this.is_first_load) {
  //       this.is_first_load = false;
  //       setTimeout(() => {
  //         this.updateMap();
  //       }, 500);
  //     }
  //   }
  // }
  // initMap() {
  //   this.map = new mapboxgl.Map({
  //     container: 'map-event',
  //     style: environment.mapbox.style,
  //     zoom: 8,
  //     center: [100.523186, 13.736717],
  //   });
  //   this.map.dragRotate.disable();
  //   this.map.on('load', (event: mapboxgl.MapEvent) => {
  //     this.map.setPaintProperty('water', 'fill-color', '#aad3df');

  //     this.map.loadImage('assets/img/map/inc/pin-incident-orange-55x55.png', (error, image) => {
  //       if (error) { throw error };
  //       this.map.addImage(`pin-event1null`, image);
  //     });
  //     this.map.loadImage('assets/img/map/inc/pin-incident-orange-55x55.png', (error, image) => {
  //       if (error) { throw error };
  //       this.map.addImage(`pin-event2null`, image);
  //     });
  //     this.map.loadImage('assets/img/map/inc/pin-incident-orange-55x55.png', (error, image) => {
  //       if (error) { throw error };
  //       this.map.addImage(`pin-event4null`, image);
  //     });
  //     this.map.loadImage('assets/img/map/inc/pin-incident-orange-55x55.png', (error, image) => {
  //       if (error) { throw error };
  //       this.map.addImage(`pin-event5null`, image);
  //     });
  //     this.map.loadImage('assets/img/map/inc/pin-incident-orange-55x55.png', (error, image) => {
  //       if (error) { throw error };
  //       this.map.addImage(`pin-event80`, image);
  //     });
  //     this.map.loadImage('assets/img/map/inc/pin-incident-blue-55x55.png', (error, image) => {
  //       if (error) { throw error };
  //       this.map.addImage(`pin-event90`, image);
  //     });
  //     this.map.loadImage('assets/img/map/inc/pin-incident-red-55x55.png', (error, image) => {
  //       if (error) { throw error };
  //       this.map.addImage(`pin-event70`, image);
  //     });
  //     this.map.loadImage('assets/img/map/inc/pin-water-one-55x55.png', (error, image) => {
  //       if (error) { throw error };
  //       this.map.addImage(`pin-event37`, image);
  //     });
  //     this.map.loadImage('assets/img/map/inc/pin-water-two-55x55.png', (error, image) => {
  //       if (error) { throw error };
  //       this.map.addImage(`pin-event38`, image);
  //     });
  //     this.map.loadImage('assets/img/map/inc/pin-water-three-55x55.png', (error, image) => {
  //       if (error) { throw error };
  //       this.map.addImage(`pin-event39`, image);
  //     });
  //     // this.map.loadImage('assets/img/map/inc/pin-incident-blue-55x55.png', (error, image) => {
  //     //   if (error) { throw error };
  //     //   this.map.addImage(`pin-event3null`, image);
  //     // });
  //     //------------------------------------------------------------------------------------------//

  //     this.map.addSource(this.event_src, {
  //       "type": 'geojson', "data": {
  //         "type": "FeatureCollection",
  //         "features": this.eventListFeatures
  //       }
  //     });

  //     //icon w-40 h-40
  //     //'icon-image': 'pin-{status}',
  //     this.map.addLayer({
  //       id: this.event_layer,
  //       source: this.event_src,
  //       type: 'symbol',
  //       layout: {
  //         'icon-allow-overlap': true,
  //         'icon-image': `pin-event{event_group_id}{event_subtype_id}`,
  //         // 'icon-image': 'pin-event{src_type_id}{event_subtype_id}',
  //         "icon-size": 0.8,
  //         //'text-offset': [0, 0],
  //         "icon-offset": [0, -20],
  //         //'text-field': '{route}',
  //         //'text-size': 12,
  //         'text-allow-overlap': true,
  //         'text-transform': 'uppercase'
  //       },
  //       paint: {
  //         "text-color": "#ffffff"
  //       },
  //       "filter": ["==", "$type", "Point"],
  //     });

  //     const popup = new mapboxgl.Popup({
  //       closeButton: false,
  //       closeOnClick: false, anchor: 'top', offset: [0, -75]
  //     });

  //     this.map.on('click', this.event_layer, (event: mapboxgl.MapEvent) => {
  //       this.map.flyTo({ center: event.features[0].geometry.coordinates, zoom: 12 });
  //       const p = event.features[0].properties;
  //       this.openModal(p);
  //     });
  //     this.map.on('mouseenter', this.event_layer, (e: mapboxgl.MapEvent) => {
  //       this.map.getCanvas().style.cursor = 'pointer';
  //       const p = e.features[0].properties;
  //       const htmltext = `<div class="font-3" style="padding-top: 10px;padding-left: 10px;padding-right: 10px;"><h6>${p.event_group_name}</h6></div>`;
  //       popup.setLngLat(e.features[0].geometry.coordinates)
  //         .setHTML(htmltext)
  //         .addTo(this.map);
  //     });
  //     this.map.on('mouseleave', this.event_layer, (e: mapboxgl.MapEvent) => {
  //       this.map.getCanvas().style.cursor = '';
  //       popup.remove();
  //     });
  //     this.updateMap();
  //   });

  // }
  // updateMap() {
  //   if (this.map.getSource(this.event_src) != null) {
  //     this.map.getSource(this.event_src).setData({
  //       'type': 'FeatureCollection',
  //       'features': this.eventListFeatures
  //     });
  //   }
  // }

}
