import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { StationService } from 'src/app/services/assets-management/station.service';
import { Station, ReqAddStation } from 'src/app/models/assets-management/station';
import { AddBoardsign, BoardsignInfo, FirmwareInfo } from 'src/app/models/sign-control/boardsign';
import { StatusEnum, RouteDirectionEnum, StationTypeEnum, ConnectionStatusEnum, MaStatusEnum, ActionEnum } from 'src/app/models/common-enum';
import { ConnectionStatus, MediaType, SignDirection } from 'src/app/models/sign-control/enum';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import * as _ from 'lodash';
import * as mapboxgl from 'mapbox-gl';
import { environment } from 'src/environments/environment';
import { from } from 'rxjs';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { BoardsignService } from 'src/app/services/sign-control/boardsign.service';
import { DeviceGroupService } from 'src/app/services/assets-management/device-group.service';
import { DeviceGroup } from 'src/app/models/assets-management/device-group';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { permission } from 'src/app/views/sign-control/_menu';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { async } from 'rxjs/internal/scheduler/async';
import { SignSettingService } from 'src/app/services/sign-control/sign-setting.service';
import { DeviceService } from 'src/app/services/assets-management/device.service';
import { DeviceInfo, ReqAddDevice } from 'src/app/models/assets-management/device';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-vsw',
  templateUrl: './vsw.component.html',
  styleUrls: ['./vsw.component.scss']
})
export class VSWComponent implements OnInit {

  @ViewChild('changeAutoSuggestModal', { static: false }) public changeAutoSuggestModal: ModalDirective;
  @ViewChild('firmwareModal', { static: false }) public firmwareModal: ModalDirective;
  @ViewChild('uploadFirmwareModal', { static: false }) public uploadFirmwareModal: ModalDirective;
  @ViewChild('addModal', { static: false }) public addModal: ModalDirective;
  @ViewChild('deleteModal', { static: false }) public deleteModal: ModalDirective;
  @ViewChild('swalSuccess', { static: false }) private swalSuccess: SwalComponent;
  @ViewChild('swalError', { static: false }) private swalError: SwalComponent;
  @ViewChild('swalInfo', { static: false }) private swalInfo: SwalComponent;
  map_board: mapboxgl.Map;
  viewType = 1; //1:list,2:map,3:manage
  statusEnum = StatusEnum;
  routeDirectionEnum = RouteDirectionEnum;
  stationTypeEnum = StationTypeEnum;
  connectionStatusEnum = ConnectionStatusEnum;
  mediaType = MediaType;
  signDir = SignDirection;
  myDate = new Date();
  currentPage: any = 1;
  itemsPerPage: any = 10;
  totalItems: any = 0;
  data_list: Station[] = [];

  is_loading: boolean = false;
  search_route: string;
  data_route: Array<string> = [];
  route_list: string[] = [];

  allowCustom: boolean = false;
  res_message: any;

  search_name: any;
  search_km_st: any;
  search_km_en: any;
  search_m_st: any;
  search_m_en: any;
  status_list = [
    { id: StatusEnum.Active, name: "Active" },
    { id: StatusEnum.Inactive, name: "Inactive" },
    { id: StatusEnum.Ma, name: "MA" }
  ];
  search_status: any;
  status_modal: any;

  lane_modal: number;
  lane_list = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  selectItem: AddBoardsign = {
    name: null,
    auto_suggestion: false
  };
  station_modal: Station;
  data_station_modal: Station[] = [];
  station_list: Station[] = [];
  device_group_modal: DeviceGroup;
  data_device_group_modal: DeviceGroup[] = [];
  device_group_list: DeviceGroup[] = [];

  //เลือกป้ายเพื่อเปลี่ยน
  board_select_list: any[] = [];
  itemList: BoardsignInfo[] = [];

  boardItem: BoardsignInfo = new BoardsignInfo();
  actionType: number; // 1 = ADD , 2 = EDIT, 3 = DELETE
  set_sign_height: number = 100;
  set_sign_height_fav: number = 50;
  set_sign_height_small: number = 48;
  select_station_id: number = null;
  select_group_id: number = null;
  select_amount_lane: number = null;

  is_loading_fav: boolean = false;

  firmware_select: FirmwareInfo;
  firmware_select_list: FirmwareInfo[] = [];
  firmware_input_file: File;
  firmware_input_desc: string;
  firmware_input_version: string;

  vsw_group_id = environment.vsw_group_id;
  vsw_device_type_id = environment.vsw_device_type;
  vsw_device_subtype_id = environment.vsw_device_subtype;
  constructor(private commonService: CommonService, private stationService: StationService, private boardsignService: BoardsignService, private deviceGroupService: DeviceGroupService,
    private signSettingService: SignSettingService,
    private router: Router,
    private route: ActivatedRoute,
    private deviceService: DeviceService
  ) {
    mapboxgl.accessToken = environment.mapbox.accessToken;
    this.commonService.activityLog(ActionEnum.Get, 'Sign Control ➡ VSW').subscribe(res => { }, error => { console.log(error); });
  }
  isAddSign: boolean;
  isAutoSuggest: boolean;
  isFirmware: boolean;
  isSignSetting: boolean;
  isFavorite: boolean;
  isFavoriteAdd: boolean;
  isFavoriteEdit: boolean;
  isCalendar: boolean;
  isMa: boolean;
  isEditSign: boolean;
  isEditSignAdd: boolean;
  isEditSignEdit: boolean;
  isDeleteSign: boolean;
  isChangeMedia: boolean;
  async ngOnInit() {
    this.is_loading = true;
    this.getDeviceGroup();
    //this.getBoardsign(this.currentPage, this.itemsPerPage);
    //this.getRoute();
    //this.getStation();
    //this.getStationSearch();
    //this.initBoardsignMap();
    this.isAddSign = await this.commonService.getPermission(permission.vsw.add_sign_id).toPromise();
    this.isAutoSuggest = await this.commonService.getPermission(permission.vsw.auto_suggest_id).toPromise();
    this.isFirmware = await this.commonService.getPermission(permission.vsw.firmware_id).toPromise();
    this.isSignSetting = await this.commonService.getPermission(permission.vsw.sign_setting_id).toPromise();
    this.isFavorite = await this.commonService.getPermission(permission.vsw.favorite_id).toPromise();
    this.isFavoriteAdd = await this.commonService.getPermission(permission.vsw.favorite.add_id).toPromise();
    this.isFavoriteEdit = await this.commonService.getPermission(permission.vsw.favorite.edit_id).toPromise();
    this.isMa = await this.commonService.getPermission(permission.vsw.ma_id).toPromise();
    this.isEditSign = await this.commonService.getPermission(permission.vsw.edit_sign_id).toPromise();
    this.isEditSignAdd = await this.commonService.getPermission(permission.vsw.edit_sign.add_id).toPromise();
    this.isEditSignEdit = await this.commonService.getPermission(permission.vsw.edit_sign.edit_id).toPromise();
    this.isDeleteSign = await this.commonService.getPermission(permission.vsw.delete_sign_id).toPromise();
    this.isChangeMedia = await this.commonService.getPermission(permission.vsw.change_sign_id).toPromise();
    this.isCalendar = false;
    const calendar = await this.commonService.getPermission(700).toPromise();
    if (calendar) {
      this.isCalendar = await this.commonService.getPermission(permission.vsw.calendar_id).toPromise();
    }
    if (this.router.url.match('fromIncident=true;')) this.getParams();
  }
  onImgError(event) {
    event.target.src = './assets/img/black1.png'
    //Do other stuff with the event.target
  }
  params_list: any
  async getParams() {
    let result: any = this.route.params.subscribe(params => {
      this.params_list = params
      if (params['route']) this.search_route = params['route']
      if (params['st_km']) this.search_km_st = params['st_km']
      if (params['en_km']) this.search_km_en = params['en_km']
      if (params['st_m']) this.search_m_st = params['st_m']
      if (params['en_m']) this.search_m_en = params['en_m']
    });
    result.unsubscribe();
    // this.search()
  }
  handleValueStatus(value) {
    if (value) {
      //console.log(value);
      this.search_status = value;
    } else {
      this.search_status = null;
    }
  }
  handleValueStatusModal(value) {
    if (value) {
      //console.log(value);
      this.status_modal = value;
    } else {
      this.status_modal = null;
    }
  }
  handleFilterRoute(value) {
    if (value.length > 0) {
      this.data_route = this.route_list.filter((s) => s.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else if (value.length == 0) {
      this.data_route = _.cloneDeep(this.route_list);
    } else {
      this.data_route = [];
    }
  }
  handleValueRoute(value) {
    if (value) {
      //console.log(value);
      this.search_route = value;
    } else {
      this.search_route = null;
    }
  }

  handleFilterStationModal(value) {
    if (value.length > 0) {
      this.data_station_modal = this.station_list.filter(x => x.name != null).filter((s) => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else if (value.length == 0) {
      this.data_station_modal = _.cloneDeep(this.station_list);
    } else {
      this.data_station_modal = [];
    }
  }
  handleValueStationModal(value) {
    if (value) {
      this.station_modal = value;
    } else {
      this.station_modal = null;
    }
  }
  handleValueLaneModal(value) {
    if (value) {
      this.lane_modal = value;
    } else {
      this.lane_modal = null;
    }
  }
  handleFilterDeviceGroupModal(value) {
    if (value.length > 0) {
      this.data_device_group_modal = this.device_group_list.filter((s) => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else if (value.length == 0) {
      this.data_device_group_modal = _.cloneDeep(this.device_group_list);
    } else {
      this.data_device_group_modal = [];
    }
  }
  handleValueDeviceGroupModal(value) {
    if (value) {
      this.device_group_modal = value;
    } else {
      this.device_group_modal = null;
    }
  }
  changeView(id) {
    if (this.vsw_group_id) {
      if (id == 1) {
        this.getBoardsign(this.currentPage, this.itemsPerPage);
      } else {
        this.getBoardsignMap();
        setTimeout(() => { this.updateMap(); this.map_board.resize(); this.is_loading = false; }, 1000);

      }
    } else {
      this.res_message = 'ไม่พบ กลุ่มอุปกรณ์ VSW <br> กรุณาเพิ่ม กลุ่มอุปกรณ์ VSW';
      setTimeout(() => { this.swalInfo.fire(); }, 100);
    }

  }

  pageChanged(event: any): void {
    if (this.currentPage != event.page) {
      this.currentPage = event.page;
      this.getBoardsign(this.currentPage, this.itemsPerPage);
    }
  }

  search() {
    if (this.vsw_group_id) {
      if (this.viewType == 1) {
        this.currentPage = 1;
        this.getBoardsign(this.currentPage, this.itemsPerPage);
      } else {
        this.getBoardsignMap();
        setTimeout(() => { this.updateMap(); this.map_board.resize(); this.is_loading = false; }, 1000);
      }
    } else {
      this.res_message = 'ไม่พบ กลุ่มอุปกรณ์ VSW <br> กรุณาเพิ่ม กลุ่มอุปกรณ์ VSW';
      setTimeout(() => { this.swalInfo.fire(); }, 100);
    }

  }
  searchBoard() {
    if (this.viewType == 1) {
      this.getBoardsign(this.currentPage, this.itemsPerPage);
    } else {
      this.getBoardsignMap();
      setTimeout(() => { this.updateMap(); this.map_board.resize(); this.is_loading = false; }, 1000);
    }
  }
  getRoute() {
    this.commonService.getRoute().subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            if (res.data) {
              this.route_list = _.cloneDeep(res.data);
              this.data_route = _.cloneDeep(res.data);
            }
          }
        }
        //this.is_loading = false;
      },
      error => {
        //this.is_loading = false;
      });
  }

  getStationSearch() {
    this.stationService.getStation(null, null, 1).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            if (res.data) {
              this.station_list = _.cloneDeep(res.data.data);
              this.data_station_modal = _.cloneDeep(res.data.data);
            }
          }
        }
        //this.is_loading = false;
      },
      error => {
        //this.is_loading = false;
      });
  }

  getDeviceGroup() {
    this.deviceGroupService.getDeviceGroup(null, null, null, null, 1).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            if (res.data) {
              this.device_group_list = _.cloneDeep(res.data.data);
              this.data_device_group_modal = _.cloneDeep(res.data.data);
              const group = this.device_group_list.find(x => x.id == this.vsw_group_id);
              if (group) {
                this.getBoardsign(this.currentPage, this.itemsPerPage);
                this.getRoute();
                this.getStation();
                this.getStationSearch();
                this.initBoardsignMap();
              } else {
                this.res_message = `ไม่พบ กลุ่มอุปกรณ์ VSW <br> กรุณาเพิ่ม กลุ่มอุปกรณ์ VSW โดยตั้ง ID เป็น ${this.vsw_group_id}`;
                setTimeout(() => { this.swalInfo.fire(); }, 100);
                this.is_loading = false;
              }

            }
          }
        }
        //this.is_loading = false;
      },
      error => {
        //this.is_loading = false;
      });
  }

  getStation() {
    this.data_list = [];
    this.totalItems = 0;
    let status = null;
    let route = null;
    let station = null;
    let owner = null;
    let vendor = null;
    let device_type = null;
    let device_group = null;
    if (this.search_status)
      status = this.search_status.id;
    if (this.search_route)
      route = this.search_route;
    // if (this.search_station)
    //   station = this.search_station.id;
    // if (this.search_owner)
    //   owner = this.search_owner.id;
    // if (this.search_vendor)
    //   vendor = this.search_vendor.vendor_id;
    // if (this.search_device_group)
    //   device_group = this.search_device_group.id;
    // if (this.search_device_type)
    //   device_type = this.search_device_type.id;
    // this.stationService.getStation(this.currentPage, this.itemsPerPage, null, route, this.search_km_st, this.search_km_en, this.search_m_st, this.search_m_en, status, station, owner, vendor, device_group, device_type).subscribe(
    //   res => {
    //     if (res) {
    //       if (res.code == 1) {
    //         if (res.data) {
    //           this.data_list = res.data.data;
    //           this.totalItems = res.data.total_items;
    //           console.log(this.data_list);

    //         }
    //       }
    //     }
    //     this.is_loading = false;
    //   },
    //   error => {
    //     this.is_loading = false;
    //   });
  }
  openAddBoardModal() {
    if (this.vsw_group_id) {
      this.selectItem = new ReqAddStation();
      this.selectItem.name = null;
      this.selectItem.auto_suggestion = false;
      this.status_modal = this.status_list.find(x => x.id == StatusEnum.Active);
      this.addModal.show();
    } else {
      this.res_message = 'ไม่พบ กลุ่มอุปกรณ์ VSW <br> กรุณาเพิ่ม กลุ่มอุปกรณ์ VSW';
      setTimeout(() => { this.swalInfo.fire(); }, 100);
    }

  }

  openEditBoardPanel(item?: BoardsignInfo) {
    this.selectItem = new ReqAddStation();
    this.selectItem.id = item.id;
    this.selectItem.name = item.name;
    this.selectItem.auto_suggestion = item.auto_suggestion;
    this.status_modal = this.status_list.find(x => x.id == item.status);
    if (item.station_id)
      this.station_modal = this.station_list.find(x => x.id == item.station_id);
    if (item.lane_count)
      this.lane_modal = this.lane_list.find(x => x == item.lane_count);
    //open panel
    //get sign by boardsign_id
    //add edit delete sign

  }

  openEditBoardModal(item?: BoardsignInfo) {
    //let item = _.cloneDeep(this.selectBoardsignManageModal);
    this.selectItem = _.cloneDeep(item);
    // this.selectItem.id = item.id;
    // this.selectItem.name = item.name;
    // this.selectItem.auto_suggestion = item.auto_suggestion;
    this.status_modal = this.status_list.find(x => x.id == this.selectItem.status);
    if (this.selectItem.station_id)
      this.station_modal = this.station_list.find(x => x.id == this.selectItem.station_id);
    if (this.selectItem.lane_count)
      this.lane_modal = this.lane_list.find(x => x == this.selectItem.lane_count);
    if (this.selectItem.group_id) {
      this.device_group_modal = this.device_group_list.find(x => x.id == this.selectItem.group_id);
    }
    this.addModal.show();
  }
  openDeleteBoardModal(item?: BoardsignInfo) {
    this.selectItem = item;
    this.selectItem.id = item.id;
    this.selectItem.name = item.name;
    this.selectItem.auto_suggestion = item.auto_suggestion;
    this.status_modal = this.status_list.find(x => x.id == item.status);
    if (item.station_id)
      this.station_modal = this.station_list.find(x => x.id == item.station_id);
    if (item.lane_count)
      this.lane_modal = this.lane_list.find(x => x == item.lane_count);
    if (item.group_id) {
      this.device_group_modal = this.device_group_list.find(x => x.id == item.group_id);
    }
    this.deleteModal.show();
  }
  clearData() {
    this.selectItem = new ReqAddStation();
  }

  addBoardAndVswDevice() {
    if (this.selectItem) {
      let req = new AddBoardsign();
      req.name = this.selectItem.name;
      req.group_id = +this.vsw_group_id;
      req.station_id = +this.station_modal.id;
      req.lane_count = +this.lane_modal;
      req.auto_suggestion = this.selectItem.auto_suggestion;
      req.status = +this.status_modal.id;

      let req_device = new ReqAddDevice();
      let d = new DeviceInfo();
      d.name = this.selectItem.name;
      d.ip = "";
      d.serial_no = "";
      d.device_group_id = +this.vsw_group_id;
      d.device_type_id = +this.vsw_device_type_id;
      d.device_sub_type_id = +this.vsw_device_subtype_id;
      d.status = +this.status_modal.id;
      d.station_id = +this.station_modal.id;
      req_device.device = d;
      let steaming = [];
      req_device.steaming = steaming;
      this.res_message = "";
      const addBoard = this.boardsignService.addBoardsign(req);
      const addDevice = this.deviceService.addDevice(req_device);
      const x = forkJoin([addBoard, addDevice]).pipe(
        map(([board, device]) => {
          // forkJoin returns an array of values, here we map those values to an object
          return { board, device };
        })
      ).subscribe(
        res => {
          if (res) {
            if (res.board.code == 1 && res.device.code == 1) {
              const log_req_data = { url: `${this.boardsignService.domain}/${this.boardsignService.uri}`, request: req, response: res };
              this.commonService.activityLog(ActionEnum.Add, `Sign Control ➡ VSW`, JSON.stringify(log_req_data)).subscribe(res => { }, error => { console.log(error); });
              this.res_message = `เพิ่มป้ายสำเร็จ`;
              setTimeout(() => {
                this.swalSuccess.fire();
                this.is_loading = false;
                this.searchBoard();
              }, 100);
              this.addModal.hide();
            } else {
              if (res.board.code != 1 && res.device.code == 1) {
                this.res_message = res.board.message;
              } else if (res.board.code == 1 && res.device.code != 1) {
                this.res_message = res.device.message;
              } else {
                this.res_message = `${res.board.message} </br> ${res.device.message}`;
              }
              setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
            }
          } else {
            setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
          }
        },
        error => {
          console.log(error);
          if (error) {
            if (error.status == 403) {

            } else {
              const error_text = error.error.message || error.statusText;
              this.res_message = error_text;
              this.is_loading = false;
              setTimeout(() => { this.swalError.fire() }, 1000);
            }
          } else {
            setTimeout(() => { this.swalError.fire() }, 1000);
          }
          this.is_loading = false;
        });
    }

  }
  addBoard() {
    if (this.selectItem) {
      let req = new AddBoardsign();
      req.name = this.selectItem.name;
      req.group_id = +this.vsw_group_id;
      req.station_id = +this.station_modal.id;
      req.lane_count = +this.lane_modal;
      req.auto_suggestion = this.selectItem.auto_suggestion;
      req.status = +this.status_modal.id;
      this.boardsignService.addBoardsign(req).subscribe(
        res => {
          if (res) {
            if (res.code == 1) {
              this.res_message = `เพิ่มป้ายสำเร็จ`;
              setTimeout(() => {
                this.swalSuccess.fire();
                this.is_loading = false;
                this.searchBoard();
              }, 100);
              this.addModal.hide();
            } else {
              this.res_message = res.message;
              setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
            }
          } else {
            setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
          }
        },
        error => {
          console.log(error);
          if (error) {
            if (error.status == 403) {

            } else {
              const error_text = error.error.message || error.statusText;
              this.res_message = error_text;
              this.is_loading = false;
              setTimeout(() => { this.swalError.fire() }, 1000);
            }
          } else {
            setTimeout(() => { this.swalError.fire() }, 1000);
          }
          this.is_loading = false;
        });
    }

  }

  addVSWDevice() {
    this.is_loading = true;
    let req = new ReqAddDevice();
    let d = new DeviceInfo();
    d.name = this.selectItem.name;
    d.ip = "";
    d.serial_no = "";
    d.device_group_id = +this.vsw_group_id;
    d.device_type_id = +this.vsw_device_type_id;
    d.device_sub_type_id = +this.vsw_device_subtype_id;
    d.status = +this.status_modal.id;
    d.station_id = +this.station_modal.id;
    req.device = d;
    let steaming = [];
    req.steaming = steaming;
    this.res_message = "";
    this.deviceService.addDevice(req).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            this.res_message = `เพิ่มอุปกรณ์สำเร็จ`;
            setTimeout(() => {
              this.swalSuccess.fire();
              this.is_loading = false;
              //this.getDevice();
            }, 100);
            this.addModal.hide();
          } else {
            this.res_message = res.message;
            setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
          }
        } else {
          setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
        }
      },
      error => {
        console.log(error);
        if (error) {
          if (error.status == 403) {

          } else {
            const error_text = error.error.message || error.statusText;
            this.res_message = error_text;
            this.is_loading = false;
            setTimeout(() => { this.swalError.fire() }, 1000);
          }
        } else {
          setTimeout(() => { this.swalError.fire() }, 1000);
        }
        this.is_loading = false;
      });
  }
  isGetBoard = false;
  editBoard() {
    this.isGetBoard = false;
    let req = new AddBoardsign();
    req.name = this.selectItem.name;
    req.group_id = +this.vsw_group_id;
    req.station_id = +this.station_modal.id;
    req.lane_count = +this.lane_modal;
    req.auto_suggestion = this.selectItem.auto_suggestion;
    req.status = +this.status_modal.id;
    console.log(this.selectItem);
    const board_id = this.selectItem.id;
    this.boardsignService.editBoardsign(req, this.selectItem.id).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            const log_req_data = { id: this.selectItem.id, request: req, response: res, url: `${this.boardsignService.domain}/${this.boardsignService.uri}/${this.selectItem.id}` };
            this.commonService.activityLog(ActionEnum.Edit, `Sign Control ➡ VSW`, JSON.stringify(log_req_data)).subscribe(res => { }, error => { console.log(error); });
            this.res_message = `แก้ไขป้ายสำเร็จ`;
            setTimeout(() => {
              this.swalSuccess.fire();
              this.is_loading = false;
              //this.getBoardsign(this.currentPage, this.itemsPerPage);
              this.isGetBoard = true;
              this.getBoardsignByID(board_id);

              // setTimeout(() => {
              //   this.isGetBoard = false;
              // }, 500);
            }, 100);
            this.addModal.hide();
          } else {
            this.res_message = res.message;
            setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
          }
        } else {
          setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
        }
      },
      error => {
        console.log(error);
        if (error) {
          if (error.status == 403) {

          } else {
            const error_text = error.error.message || error.statusText;
            this.res_message = error_text;
            this.is_loading = false;
            setTimeout(() => { this.swalError.fire() }, 1000);
          }
        } else {
          setTimeout(() => { this.swalError.fire() }, 1000);
        }
        this.is_loading = false;
      });
  }

  deleteBoard() {
    this.is_loading = true;
    let req = new AddBoardsign();
    req.name = this.selectItem.name;
    req.group_id = +this.vsw_group_id;
    req.station_id = +this.station_modal.id;
    req.lane_count = +this.lane_modal;
    //req.auto_suggestion = this.selectItem.auto_suggestion;
    req.auto_suggestion = false;
    if (this.selectItem.auto_suggestion) {
      req.auto_suggestion = true;
    }
    req.status = StatusEnum.Delete;
    this.res_message = "";
    this.boardsignService.deleteBoardsignEditStatus(req, this.selectItem.id).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            const log_req_data = { id: this.selectItem.id, request: req, response: res, url: `${this.boardsignService.domain}/${this.boardsignService.uri}/${this.selectItem.id}` };
            this.commonService.activityLog(ActionEnum.Delete, `Sign Control ➡ VSW`, JSON.stringify(log_req_data)).subscribe(res => { }, error => { console.log(error); });
            this.res_message = `ลบป้ายสำเร็จ`;
            setTimeout(() => {
              this.swalSuccess.fire();
              this.is_loading = false;
              this.searchBoard();
            }, 100);
            this.deleteModal.hide();
          } else {
            this.res_message = res.message;
            setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
          }
        } else {
          setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
        }
      },
      error => {
        console.log(error);
        if (error) {
          if (error.status == 403) {

          } else {
            const error_text = error.error.message || error.statusText;
            this.res_message = error_text;
            this.is_loading = false;
            setTimeout(() => { this.swalError.fire() }, 1000);
          }
        } else {
          setTimeout(() => { this.swalError.fire() }, 1000);
        }
        this.is_loading = false;
      });
  }
  openFirmwareModal(item?: BoardsignInfo) {
    //Get Firmware by id
    this.firmware_select = null;
    this.boardsignService.getFirmware([item.id]).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            if (res.data) {
              if (res.data.length > 0) {
                this.firmware_select = res.data[0];

              }
            }
          }
        }
      },
      error => { });
    this.firmwareModal.show();
  }
  detectFirmwareFiles(event) {
    //let files = event.target.files;
    this.firmware_input_file = event.target.files[0];
  }
  openUploadFirmwareModal(ids?: number[]) {
    this.firmware_input_file = null;
    this.firmware_input_desc = null;
    this.firmware_input_version = null;
    this.firmware_select_list = [];
    // this.firmware_select_list = [
    //   {
    //     boardsign_id : 1,
    //     boardsign_name: "boardsign name 1",
    //     ts: new Date(),
    //     firmware_url: "http://test",
    //     version: "1.0.0",
    //     desc: "รายละเอียด 1"
    //   },
    //   {
    //     boardsign_id : 2,
    //     boardsign_name: "boardsign name 1",
    //     ts: new Date(),
    //     firmware_url: "http://test",
    //     version: "1.0.0",
    //     desc: "รายละเอียด 2"
    //   }
    // ];
    this.boardsignService.getFirmware(ids).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            if (res.data) {
              if (res.data.length > 0) {
                this.firmware_select_list = res.data;
              }
            }
          }
        }
      },
      error => { });
    this.uploadFirmwareModal.show();
  }
  UploadFirmware(ids?: number[]) {
    console.log(ids);
    this.boardsignService.uploadFirmware(this.firmware_input_file, this.firmware_input_version, this.firmware_input_desc, ids).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            const log_req_data = { request: { firmware: this.firmware_input_file.name, version: this.firmware_input_version, desc: this.firmware_input_desc, boardsign_ids: ids }, response: res, url: `${this.boardsignService.domain}/firmware` };
            this.commonService.activityLog(ActionEnum.Edit, `Sign Control ➡ VSW ➡ Firmware update`, JSON.stringify(log_req_data)).subscribe(res => { }, error => { console.log(error); });
            this.res_message = `Upload firmware สำเร็จ`;
            setTimeout(() => {
              this.swalSuccess.fire();
              this.is_loading = false;
            }, 100);
            this.uploadFirmwareModal.hide();
          } else {
            this.res_message = res.message;
            setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
          }
        } else {
          setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
        }
      },
      error => {
        console.log(error);
        if (error) {
          if (error.status == 403) {

          } else {
            const error_text = error.error.message || error.statusText;
            this.res_message = error_text;
            this.is_loading = false;
            setTimeout(() => { this.swalError.fire() }, 1000);
          }
        } else {
          setTimeout(() => { this.swalError.fire() }, 1000);
        }
        this.is_loading = false;
      });
  }
  clickOpenSignSetting: boolean = false;
  selectboardsignInfo: BoardsignInfo;
  openSignSettingModal(item?: BoardsignInfo) {
    this.selectboardsignInfo = item;
    this.clickOpenSignSetting = true;
  }
  closeSignSettingModal() {
    this.clickOpenSignSetting = false;
    this.selectboardsignInfo = null;
    this.searchBoard();
  }

  clickOpenBoardsignManage: boolean = false;
  selectBoardsignManageModal: BoardsignInfo;
  openBoardsignManageModal(item?: BoardsignInfo) {
    this.selectBoardsignManageModal = item;
    this.clickOpenBoardsignManage = true;
  }
  closeBoardsignManageModal() {
    this.clickOpenBoardsignManage = false;
    this.selectBoardsignManageModal = null;
    this.searchBoard();
  }

  clickOpenFavorite: boolean = false;
  selectBoardsignFavModal: BoardsignInfo;
  openFavoriteModal(item?: BoardsignInfo) {
    this.selectBoardsignFavModal = item;
    this.clickOpenFavorite = true;
  }
  closeFavoriteModal() {
    this.clickOpenFavorite = false;
    this.selectBoardsignFavModal = null;
    this.searchBoard();
  }

  clickOpenChangeMedia: boolean = false;
  openChangeMediaModal() {
    this.clickOpenChangeMedia = true;
  }
  closeChangeMediaModal() {
    this.clickOpenChangeMedia = false;
    this.searchBoard();
  }

  //--------------------------------------------------------------------------------------------//
  async getBoardsign(skip?: number, take?: number) {
    this.is_loading = true;
    this.itemList = [];
    let name = null;
    let route = null;
    let status = null;
    let st_km = null;
    let en_km = null;
    let st_m = null;
    let en_m = null;
    if (this.search_name)
      name = this.search_name;
    if (this.search_route)
      route = this.search_route;
    if (this.search_status)
      status = this.search_status.id;
    if (this.search_km_st)
      st_km = parseInt(this.search_km_st);
    if (this.search_km_en)
      en_km = parseInt(this.search_km_en);
    if (this.search_m_st)
      st_m = parseInt(this.search_m_st);
    if (this.search_m_en)
      en_m = parseInt(this.search_m_en);
    let res = await this.boardsignService.getBoardsign(skip, take, name, route, null, status, st_km, en_km, st_m, en_m, null, this.vsw_group_id).toPromise().catch((err) => {
      console.log(err);
      this.is_loading = false;
    });
    this.itemList = [];
    if (res) {
      if (res.code == 1) {
        this.itemList = await this.genSignsLayerView(res.data.data, false);
        this.itemList.forEach(async (item) => {
          if (item.sensor_id && item.signs) {
            const res_config_road = await this.signSettingService.getConfigRoad(item.sensor_id).toPromise();
            item.config_road = res_config_road.data;
          }
        });
        this.totalItems = res.data.total_items;
      }
    }
    this.is_loading = false;
  }

  async getBoardsignByID(id?: number) {
    console.log(id);
    this.boardsignService.getBoardsignByID(id).subscribe(
      res => {
        if (res.code == 1) {
          console.log(res.data);
          this.selectboardsignInfo = _.cloneDeep(res.data);
          this.selectItem = _.cloneDeep(res.data);
          this.isGetBoard = false;
        }
      },
      err => {
        console.log(JSON.stringify(err.statusText));
      });
  }

  async genSignsLayerView(boards?: BoardsignInfo[], is_suggest?: boolean) {
    boards.forEach(board => {
      if (board.signs) {
        board.signs = board.signs.filter(x => x.status == StatusEnum.Active);
        if (board.signs.length > 0 && board.station.boardsign_structure) {
          // layers
          //const layers_text = board.station.boardsign_structure.split("|");
          const layer_no_list = _.sortedUniq(board.signs.map(a => a.layer_no));
          let layers = [];
          let layers_new = [];
          let layers_sug = [];
          let is_dir_back = false;
          const find_sign_back = board.signs.filter(x => x.direction == this.signDir.Back);
          if (find_sign_back.length > 0)
            is_dir_back = true;
          layer_no_list.forEach((l, index) => {
            // signs
            let signs = [];
            let signs_new = [];
            let find_sign = board.signs.filter(x => x.layer_no == l);

            if (find_sign) {
              find_sign.forEach(s => {
                if (s.media) {
                  let d = { id: s.id, sign_no: s.sign_no, sign_type_id: s.sign_type_id, ratio: s.sign_type_object.aspect_ratio, sign_type_object: s.sign_type_object, dir: s.direction, media: s.media };
                  signs.push(d);
                } else {
                  let d = { id: s.id, sign_no: s.sign_no, sign_type_id: s.sign_type_id, ratio: s.sign_type_object.aspect_ratio, sign_type_object: s.sign_type_object, dir: s.direction, media: null };
                  signs.push(d);
                }
                if (is_suggest) {
                  signs_new.push({ id: s.id, sign_no: s.sign_no, sign_type_id: s.sign_type_id, ratio: s.sign_type_object.aspect_ratio, sign_type_object: s.sign_type_object, dir: s.direction, media: null });
                }
              });
              signs = _.sortBy(signs, ['sign_no']);
              layers.push(signs);
              if (is_suggest) {
                signs_new = _.sortBy(signs_new, ['sign_no']);
                layers_new.push(signs_new);
              }
            }

            if (is_suggest) {
              board.signs_suggest = board.signs_suggest.filter(x => x.status == StatusEnum.Active);
              let find_sign_sug = board.signs_suggest.filter(x => x.layer_no == l);
              let signs_sug = [];
              if (find_sign_sug) {
                find_sign_sug.forEach(s => {
                  if (s.media) {
                    let d = { id: s.id, sign_no: s.sign_no, sign_type_id: s.sign_type_id, ratio: s.sign_type_object.aspect_ratio, dir: s.direction, media: s.media };
                    signs_sug.push(d);
                  } else {
                    let d = { id: s.id, sign_no: s.sign_no, sign_type_id: s.sign_type_id, ratio: s.sign_type_object.aspect_ratio, dir: s.direction, media: null };
                    signs_sug.push(d);
                  }
                });
                signs_sug = _.sortBy(signs_sug, ['sign_no']);
                layers_sug.push(signs_sug);
              }
            }
          });
          board.is_dir_back = is_dir_back;
          board.layers = layers;
          if (is_suggest) {
            board.layers_suggest = _.cloneDeep(layers_sug);
            board.layers_new = _.cloneDeep(layers_new);
          }
        } else {
          board.signs = [];
          board.layers = [];
          board.layers_suggest = [];
          board.layers_new = [];
        }
      } else {
        board.signs = [];
        board.layers = [];
        board.layers_suggest = [];
        board.layers_new = [];
      }
      const find_select = this.board_select_list.find(x => x == board.id);
      if (find_select) {
        board.check = true;
      } else {
        board.check = false;
      }
    });
    return boards;
  }
  //------------------------------------------------------------------------------//

  checkValue(e: any, id: number) {
    const list = this.itemList.filter(x => x.check == true);
    this.board_select_list = list.map(a => a.id);
  }

  clickClearBoardsignSelect() {
    this.board_select_list = [];
    this.itemList.forEach(item => {
      item.check = false;
    });
    this.boardListFeatures.forEach(item => {
      item.check = false;
    });
    if (this.viewType == 2) {
      this.boardListFeatures.forEach(item => {
        let sta: any = "null";
        let con_sta: any = "null";
        if (item.properties.status != null)
          sta = item.properties.status;
        if (item.properties.connection_status != null)
          con_sta = item.properties.connection_status;
        item.properties.check = false;
        item.properties.icon = `${item.properties.check}-${sta}-${con_sta}`;

      });
      setTimeout(() => {
        this.updateMap();
      }, 500);
    }
  }

  goToSchedule(boardsign_id?: number) {
    this.router.navigate(['/sign-control/schedule/' + boardsign_id]);
  }
  clickItemOpenInfoLayer(item?: BoardsignInfo) {
    // this.getSignType();

    // //open Boardsign Information Layer
    // this.boardItem = _.cloneDeep(item);
    // for (let i = 0; i < this.boardItem.lane_count; i++) {
    //   const lane = { id: i + 1, check: false }
    //   this.sign_cover_lane_list.push(lane);
    // }

    // this.boardsign_toggle_layer = true;
    // this.layerType = this.boardViewType.BoardInfo;
  }
  boardsign_toggle_layer: any = false;
  clickItem(item?: BoardsignInfo, type?: number) {
    // this.actionType = type;

    // if (type == 1) {
    //   this.boardItem = new BoardsignInfo();
    //   this.boardItem.status = this.deviceStatus.Active;
    //   this.auto_suggestion = false;
    //   this.select_group_id = null;
    //   this.select_station_id = null;
    //   this.select_amount_lane = null;
    //   this.getStationList();
    //   this.getDeviceGroup();
    //   this.addBoardModal.show();
    // } else if (type == 2) {
    //   this.boardItem = _.cloneDeep(item);
    //   this.getStationList();
    //   this.getDeviceGroup();
    //   this.select_group_id = this.boardItem.group_id;
    //   this.select_station_id = this.boardItem.station_id;
    //   this.select_amount_lane = this.boardItem.lane_count;
    //   this.addBoardModal.show();
    // } else if (type == 3) {
    //   this.boardItem = _.cloneDeep(item);
    //   this.deleteBoardModal.show();
    // }
  }
  get_sign_width(ratio: string, set_sign_height) {
    if (ratio) {
      const wh = ratio.split(':');
      const w = +wh[0];
      const h = +wh[1];
      const r = w / h;
      return set_sign_height * r;
    } else {
      return 0;
    }
  }


  multiFilter(array, filters) {
    const filterKeys = Object.keys(filters);
    // filters all elements passing the criteria
    return array.filter((item) => {
      // dynamically validate all filter criteria
      return filterKeys.every(key => !!~filters[key].indexOf(item[key]));
    });
  }


  //----------------------------------------------------------------------------------------------------------//
  // //MA

  selectMaItem: any;
  selectMaView: any;
  selectMaId: any;
  clickOpenAddMa = false;
  typeId: any;
  refId: any;
  boardMaSelect: any;
  openAddMaModal(item?: any) {
    this.selectMaItem = null;
    this.boardMaSelect = item;
    this.refId = item.id;
    if (item.status == StatusEnum.Ma) {
      this.selectMaView = 3;
    } else {
      this.selectMaView = 2;
    }
    this.typeId = 3;
    this.clickOpenAddMa = true;
  }
  closeAddMaModal() {
    this.typeId = null;
    this.refId = null;
    this.clickOpenAddMa = false;
    this.selectMaId = null;
  }
  openAddMaLogModal(item?: any) {
    this.selectMaView = 3;
    this.selectMaItem = item;
    this.selectMaId = this.selectMaItem.ma_id;
    this.clickOpenAddMa = true;
  }
  addMaSuccess() {
    this.searchBoard();
  }
  trackByIndex(index: number, obj: any): any {
    return index;
  }
  async openAddMa(type?: number, ref?: number, status?: number) {
    this.typeId = type;
    this.refId = ref;
    if (status == StatusEnum.Ma) {
      this.selectMaView = 3;
    } else {
      this.selectMaView = 2;
    }
    console.log(this.refId);

    this.clickOpenAddMa = true;
  }
  closeAddMa() {
    this.typeId = null;
    this.refId = null;
    this.clickOpenAddMa = false;
  }
  //--------------------------------------------------------------------------------------------//
  //Map
  board_layer: any = 'BoardsignLayer';
  board_src: any = 'BoardsignSrc';
  boardListFeatures: any[] = [];
  boardList: any = [];
  async getBoardsignMap() {
    // this.is_loading = true;
    // this.boardList = [];
    // this.boardListFeatures = [];
    // this.data_list = [];
    // this.totalItems = 0;
    // let status = null;
    // let route = null;
    // let station = null;
    // let owner = null;
    // let vendor = null;
    // let device_type = null;
    // let device_group = null;
    // if (this.search_status)
    //   status = this.search_status.id;
    // if (this.search_route)
    //   route = this.search_route;
    // if (this.search_station)
    //   station = this.search_station.id;
    // if (this.search_owner)
    //   owner = this.search_owner.id;
    // if (this.search_vendor)
    //   vendor = this.search_vendor.vendor_id;
    // if (this.search_device_group)
    //   device_group = this.search_device_group.id;
    // if (this.search_device_type)
    //   device_type = this.search_device_type.id;
    // this.stationService.getStation(null, null, null, route, this.search_km_st, this.search_km_en, this.search_m_st, this.search_m_en, status, station, owner, vendor, device_group, device_type).subscribe(
    //   res => {
    //     if(res){
    //       res.data.data.forEach(item => {
    //         // item.icon = item.current[0].weather[0].icon;
    //         // item.desc = item.current[0].weather[0].description;
    //         if (item.lng && item.lat) {
    //           const coordinates = [item.lng, item.lat];

    //           const geometry = { type: 'Point', coordinates: coordinates };
    //           const feature = { type: 'Feature', geometry: geometry, properties: item };
    //           this.stationListFeatures.push(feature);
    //         }
    //       });
    //       this.stationList = res.data.data;
    //     }
    //   },
    //   err => {
    //     console.log(JSON.stringify(err.statusText));
    //   });

    this.is_loading = true;
    this.boardList = [];
    this.boardListFeatures = [];
    let name = null;
    let route = null;
    let status = null;
    let st_km = null;
    let en_km = null;
    let st_m = null;
    let en_m = null;
    if (this.search_name)
      name = this.search_name;
    if (this.search_route)
      route = this.search_route;
    if (this.search_status)
      status = this.search_status.id;
    if (this.search_km_st)
      st_km = parseInt(this.search_km_st);
    if (this.search_km_en)
      en_km = parseInt(this.search_km_en);
    if (this.search_m_st)
      st_m = parseInt(this.search_m_st);
    if (this.search_m_en)
      en_m = parseInt(this.search_m_en);
    this.boardsignService.getBoardsign(null, null, name, route, null, status, st_km, en_km, st_m, en_m, null, this.vsw_group_id).subscribe(
      res => {
        if (res) {
          res.data.data.forEach(item => {
            if (item.signs) {
              item.signs = item.signs.filter(x => x.status == StatusEnum.Active);
              if (item.signs.length > 0 && item.station.boardsign_structure) {
                // layers
                //const layers_text = item.station.boardsign_structure.split("|");
                const layer_no_list = _.sortedUniq(item.signs.map(a => a.layer_no));
                let layers = [];
                layer_no_list.forEach((l, index) => {
                  // signs
                  let signs = [];
                  let find_sign = item.signs.filter(x => x.layer_no == l);

                  if (find_sign) {
                    find_sign.forEach(s => {
                      if (s.media) {
                        let d = { id: s.id, sign_no: s.sign_no, sign_type_id: s.sign_type_id, ratio: s.sign_type_object.aspect_ratio, dir: s.direction, media: s.media };
                        signs.push(d);
                      } else {
                        let d = { id: s.id, sign_no: s.sign_no, sign_type_id: s.sign_type_id, ratio: s.sign_type_object.aspect_ratio, dir: s.direction, media: null };
                        signs.push(d);
                      }
                    });
                    signs = _.sortBy(signs, ['sign_no']);
                    layers.push(signs);
                  }
                });
                item.layers = layers;
              } else {
                item.layers = [];
              }
            } else {
              item.layers = [];
            }

            let sta: any = "null";
            let con_sta: any = "null";
            if (item.status != null)
              sta = item.status;
            if (item.connection_status != null)
              con_sta = item.connection_status;
            const find_select = this.board_select_list.find(x => x == item.id);
            if (find_select) {
              item.check = true;
            } else {
              item.check = false;
            }
            item.icon = `${item.check}-${sta}-${con_sta}`;
            if (item.station) {
              if (item.station.longitude && item.station.latitude) {
                const coordinates = [item.station.longitude, item.station.latitude];
                const geometry = { type: 'Point', coordinates: coordinates };
                const feature = { type: 'Feature', geometry: geometry, properties: item };
                this.boardListFeatures.push(feature);
              }
            }
          });

          this.updateMap(); this.map_board.resize(); this.is_loading = false;
          this.boardList = res.data.data;
        }
      },
      err => {
        console.log(JSON.stringify(err.statusText));
        this.is_loading = false;
      });
  }
  initBoardsignMap() {
    this.map_board = new mapboxgl.Map({
      container: 'map-sign',
      style: environment.mapbox.style,
      zoom: 7,
      center: [100.523186, 13.736717],
    });
    this.map_board.dragRotate.disable();
    this.map_board.on('load', (event: mapboxgl.MapEvent) => {
      this.map_board.setPaintProperty('water', 'fill-color', '#aad3df');

      this.map_board.loadImage('assets/img/marker/pin-gray.png', (error, image) => {
        if (error) { throw error };
        this.map_board.addImage(`false-null-null`, image);
      });
      this.map_board.loadImage('assets/img/marker/pin-gray.png', (error, image) => {
        if (error) { throw error };
        this.map_board.addImage(`false-null-${ConnectionStatus.Offline}`, image);
      });
      this.map_board.loadImage('assets/img/marker/pin-gray.png', (error, image) => {
        if (error) { throw error };
        this.map_board.addImage(`false-null-${ConnectionStatus.Online}`, image);
      });

      this.map_board.loadImage('assets/img/marker/pin-green.png', (error, image) => {
        if (error) { throw error };
        this.map_board.addImage(`false-${StatusEnum.Active}-null`, image);
      });
      this.map_board.loadImage('assets/img/marker/pin-green-offline.png', (error, image) => {
        if (error) { throw error };
        this.map_board.addImage(`false-${StatusEnum.Active}-${ConnectionStatus.Offline}`, image);
      });
      this.map_board.loadImage('assets/img/marker/pin-green-online.png', (error, image) => {
        if (error) { throw error };
        this.map_board.addImage(`false-${StatusEnum.Active}-${ConnectionStatus.Online}`, image);
      });

      this.map_board.loadImage('assets/img/marker/pin-red.png', (error, image) => {
        if (error) { throw error };
        this.map_board.addImage(`false-${StatusEnum.Inactive}-null`, image);
      });
      this.map_board.loadImage('assets/img/marker/pin-red-offline.png', (error, image) => {
        if (error) { throw error };
        this.map_board.addImage(`false-${StatusEnum.Inactive}-${ConnectionStatus.Offline}`, image);
      });
      this.map_board.loadImage('assets/img/marker/pin-red-online.png', (error, image) => {
        if (error) { throw error };
        this.map_board.addImage(`false-${StatusEnum.Inactive}-${ConnectionStatus.Online}`, image);
      });

      this.map_board.loadImage('assets/img/marker/pin-yellow.png', (error, image) => {
        if (error) { throw error };
        this.map_board.addImage(`false-${StatusEnum.Ma}-null`, image);
      });
      this.map_board.loadImage('assets/img/marker/pin-yellow-offline.png', (error, image) => {
        if (error) { throw error };
        this.map_board.addImage(`false-${StatusEnum.Ma}-${ConnectionStatus.Offline}`, image);
      });
      this.map_board.loadImage('assets/img/marker/pin-yellow-online.png', (error, image) => {
        if (error) { throw error };
        this.map_board.addImage(`false-${StatusEnum.Ma}-${ConnectionStatus.Online}`, image);
      });
      //---------------------//
      this.map_board.loadImage('assets/img/marker/pin-gray-check.png', (error, image) => {
        if (error) { throw error };
        this.map_board.addImage(`true-null-null`, image);
      });
      this.map_board.loadImage('assets/img/marker/pin-gray-check.png', (error, image) => {
        if (error) { throw error };
        this.map_board.addImage(`true-null-${ConnectionStatus.Offline}`, image);
      });
      this.map_board.loadImage('assets/img/marker/pin-gray-check.png', (error, image) => {
        if (error) { throw error };
        this.map_board.addImage(`true-null-${ConnectionStatus.Online}`, image);
      });

      this.map_board.loadImage('assets/img/marker/pin-green-check.png', (error, image) => {
        if (error) { throw error };
        this.map_board.addImage(`true-${StatusEnum.Active}-null`, image);
      });
      this.map_board.loadImage('assets/img/marker/pin-green-offline-check.png', (error, image) => {
        if (error) { throw error };
        this.map_board.addImage(`true-${StatusEnum.Active}-${ConnectionStatus.Offline}`, image);
      });
      this.map_board.loadImage('assets/img/marker/pin-green-online-check.png', (error, image) => {
        if (error) { throw error };
        this.map_board.addImage(`true-${StatusEnum.Active}-${ConnectionStatus.Online}`, image);
      });

      this.map_board.loadImage('assets/img/marker/pin-red-check.png', (error, image) => {
        if (error) { throw error };
        this.map_board.addImage(`true-${StatusEnum.Inactive}-null`, image);
      });
      this.map_board.loadImage('assets/img/marker/pin-red-offline-check.png', (error, image) => {
        if (error) { throw error };
        this.map_board.addImage(`true-${StatusEnum.Inactive}-${ConnectionStatus.Offline}`, image);
      });
      this.map_board.loadImage('assets/img/marker/pin-red-online-check.png', (error, image) => {
        if (error) { throw error };
        this.map_board.addImage(`true-${StatusEnum.Inactive}-${ConnectionStatus.Online}`, image);
      });

      this.map_board.loadImage('assets/img/marker/pin-yellow-check.png', (error, image) => {
        if (error) { throw error };
        this.map_board.addImage(`true-${StatusEnum.Ma}-null`, image);
      });
      this.map_board.loadImage('assets/img/marker/pin-yellow-offline-check.png', (error, image) => {
        if (error) { throw error };
        this.map_board.addImage(`true-${StatusEnum.Ma}-${ConnectionStatus.Offline}`, image);
      });
      this.map_board.loadImage('assets/img/marker/pin-yellow-online-check.png', (error, image) => {
        if (error) { throw error };
        this.map_board.addImage(`true-${StatusEnum.Ma}-${ConnectionStatus.Online}`, image);
      });
      //------------------------------------------------------------------------------------------//

      this.map_board.addSource(this.board_src, {
        "type": 'geojson', "data": {
          "type": "FeatureCollection",
          "features": this.boardListFeatures
        }
      });

      //icon w-40 h-40
      this.map_board.addLayer({
        id: this.board_layer,
        source: this.board_src,
        type: 'symbol',
        layout: {
          'icon-allow-overlap': true,
          'icon-image': '{icon}',
          "icon-size": 0.8,
          "icon-offset": [0, 0], //[x,y]
          'text-offset': [0, 0],
          'text-field': '{route}',
          'text-size': 12,
          'text-allow-overlap': true,
          'text-transform': 'uppercase',
        },
        paint: {
          "text-color": "#ffffff"
        },
        "filter": ["==", "$type", "Point"],
      });

      const popup = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false, anchor: 'top', offset: [0, -52]
      });

      this.map_board.on('click', this.board_layer, (event: mapboxgl.MapEvent) => {
        //this.map.flyTo({ center: event.features[0].geometry.coordinates, zoom: 15 });
        this.map_board.flyTo({ center: event.features[0].geometry.coordinates });
        const p = event.features[0].properties;
        this.checkValueMap(p.check, p.id);
      });
      this.map_board.on('mouseenter', this.board_layer, (e: mapboxgl.MapEvent) => {
        this.map_board.getCanvas().style.cursor = 'pointer';
        const p = e.features[0].properties;
        const htmltext = `<div class="font-3" style="padding-top: 10px;padding-left: 10px;padding-right: 10px;"><h6>${p.name}</h6></div>`;
        popup.setLngLat(e.features[0].geometry.coordinates)
          .setHTML(htmltext)
          .addTo(this.map_board);
      });
      this.map_board.on('mouseleave', this.board_layer, (e: mapboxgl.MapEvent) => {
        this.map_board.getCanvas().style.cursor = '';
        popup.remove();
      });

      let contextmenu_board = new mapboxgl.Marker();
      this.map_board.on('contextmenu', this.board_layer, (event: mapboxgl.MapEvent) => {
        let p = event.features[0].properties;
        p.device_group = JSON.parse(p.device_group);
        p.layers = JSON.parse(p.layers);
        p.signs = JSON.parse(p.signs);
        p.station = JSON.parse(p.station);
        let disableFavBtn = true;
        if (p.signs.length > 0) {
          disableFavBtn = false;
        }
        contextmenu_board.remove();
        this.map_board.getCanvas().style.cursor = 'pointer';
        const el = document.createElement('div');
        let context = this.getContexthtml(disableFavBtn);
        el.innerHTML = context;
        el.style.backgroundColor = '#161616';
        el.style.borderRadius = '5px';
        contextmenu_board = new mapboxgl.Marker(el, { offset: [155, 0] }).setLngLat(event.features[0].geometry.coordinates).addTo(this.map_board);
        document.getElementById('firmwarebt').addEventListener('click', () => {
          this.openFirmwareModal(p);
        });
        // document.getElementById('signSettingbt').addEventListener('click', () => {
        //   this.openSignSettingModal(p);
        // });
        // document.getElementById('openManageFav').addEventListener('click', () => {
        //   this.openFavoriteModal(p);
        // });
        document.getElementById('goToSchedule').addEventListener('click', () => {
          this.goToSchedule(p.id);
        });
        document.getElementById('openMA').addEventListener('click', () => {
          this.openAddMaModal(p);
        });
        document.getElementById('clickEdit').addEventListener('click', () => {
          this.openBoardsignManageModal(p);
        });
        document.getElementById('clickDelete').addEventListener('click', () => {
          this.openDeleteBoardModal(p);
        });

      });
      this.map_board.on('click', (event: mapboxgl.MapEvent) => {
        contextmenu_board.remove();
      });
      this.map_board.on('zoom', (event: mapboxgl.MapEvent) => {
        contextmenu_board.remove();
      });
      this.updateMap();
    });

  }
  updateMap() {
    if (this.map_board.getSource(this.board_src) != null) {
      this.map_board.getSource(this.board_src).setData({
        'type': 'FeatureCollection',
        'features': this.boardListFeatures
      });
    }
  }
  // <button id="signSettingbt" [disabled]="${this.isSignSetting}" title="Sign Setting" type="button"
  //     class="btn btn-sm bg-purple ml-1 text-light">
  //     <i class="fa fa-cog"></i>
  //   </button>
  //   <button id="openManageFav" [disabled]="${disabledFav}" title="Favorite"
  //     type="button" class="btn btn-sm btn-primary1 bg-dark-blue ml-1 text-light">
  //     <i class="icon-star"></i>
  //   </button>
  getContexthtml(disable?: boolean) {
    let disabledFav = disable;
    if (!this.isFavorite) {
      disabledFav = true;
    }
    return `<div><div class="d-flex align-items-center justify-content-center m-2">
    <button id="firmwarebt" [disabled]="${this.isFirmware}" title="Firmware" type="button"
      class="btn btn-sm bg-blue ml-1 b-0 ml-1 text-light">
      <i class="icon-cloud-upload"></i>
    </button>
    <button id="goToSchedule" [disabled]="${this.isCalendar}" title="Schedule" type="button"
      class="btn btn-sm btn-success ml-1 text-light">
      <i class="icon-calendar"></i>
    </button>
    <button id="openMA" [disabled]="${this.isMa}" title="แจ้งซ่อม" type="button"
      class="btn btn-sm btn-info ml-1 text-light">
      <i class="cui-wrench icons"></i>
    </button>
    <button id="clickEdit" [disabled]="${this.isAddSign}" title="แก้ไข" type="button"
      class="btn btn-sm btn-warning text-light ml-1">
      <i class="cui-note icons"></i>
    </button>
    <button id="clickDelete" [disabled]="${this.isEditSign}" title="ลบ" type="button" class="btn btn-sm btn-danger ml-1">
      <i class="cui-trash icons"></i>
    </button>
  </div></div>`
  }
  checkValueMap(e: any, id: number) {
    // if (e == false) {
    //   this.board_select_list.push(+id);
    // } else {
    //   const index: number = this.board_select_list.indexOf(id);
    //   if (index !== -1) {
    //     this.board_select_list.splice(index, 1);
    //   }
    // }



    // this.lmsListFeatures.forEach(item => {
    //   if (item.properties.id == id) {
    //     if (item.properties.check) {
    //       item.properties.check = false;
    //       if (item.properties.connection_status == this.connectionStatus.Online) {
    //         item.properties.icon_map_id = 3;
    //       } else {
    //         item.properties.icon_map_id = 4;
    //       }
    //     } else {
    //       item.properties.check = true;
    //       if (item.properties.connection_status == this.connectionStatus.Online) {
    //         item.properties.icon_map_id = 1;
    //       } else {
    //         item.properties.icon_map_id = 2;
    //       }
    //     }
    //   }
    // });

    // setTimeout(() => {
    //   const list = this.lmsListFeatures.filter(x => x.properties.check == true);
    //   this.board_select_list = list.map(a => a.properties.id);
    //   this.updateMap();
    // }, 100);

    this.boardListFeatures.forEach(item => {

      if (item.properties.id == id) {
        let sta: any = "null";
        let con_sta: any = "null";
        if (item.properties.status != null)
          sta = item.properties.status;
        if (item.properties.connection_status != null)
          con_sta = item.properties.connection_status;
        if (e == true) {
          item.properties.check = false;

        } else {
          item.properties.check = true;
        }
        item.properties.icon = `${item.properties.check}-${sta}-${con_sta}`;
      }
    });
    if (e == false) {
      this.board_select_list.push(+id);
    } else {
      const index: number = this.board_select_list.indexOf(+id);
      if (index !== -1) {
        this.board_select_list.splice(index, 1);
      }
    }
    setTimeout(() => {
      this.updateMap();
    }, 100);
  }

  //---------------------------------------------------------------------------------------//
  openChangeAutoSuggestModal(item?: BoardsignInfo) {
    console.log(item);
    this.selectItem = item;
    this.changeAutoSuggestModal.show();
  }
  changeAutoSuggest(is_autosuggest?: boolean, boardsign_id?: number) {
    this.res_message = "";
    this.boardsignService.changeAutoSuggestMode(is_autosuggest, boardsign_id).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            this.res_message = `ปิด Auto Suggest Mode สำเร็จ`;
            if (is_autosuggest) {
              this.res_message = `เปิด Auto Suggest Mode สำเร็จ`;
            }
            setTimeout(() => {
              this.swalSuccess.fire();
              this.is_loading = false;
              this.changeAutoSuggestModal.hide();

            }, 100);
          } else {
            this.res_message = res.message;
            setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
          }
        } else {
          setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
        }
        this.searchBoard();
      },
      error => {
        console.log(error);
        if (error) {
          if (error.status == 403) {

          } else {
            const error_text = error.error.message || error.statusText;
            this.res_message = error_text;
            this.is_loading = false;
            setTimeout(() => { this.swalError.fire() }, 1000);
          }
        } else {
          setTimeout(() => { this.swalError.fire() }, 1000);
        }
        this.is_loading = false;
      });

  }
  changeSuggestMode(e: any, boardsign_id: number) {
    // let req = new ReqAutoSuggestion();
    // req.auto_suggestion = e.target.checked;
    // req.boardsign_id = boardsign_id;
    // this.boardsignService.changeAutoSuggestMode(req).subscribe(
    //   res => {
    //     if (res.code == 1) {
    //       this.sweetalertOpen(res.message, res.code)
    //     } else {
    //       let board = this.itemList.find(x => x.id == boardsign_id);
    //       board.auto_suggestion = !e.target.checked;
    //       this.sweetalertOpen(res.message, 2)
    //     }
    //   },
    //   err => {
    //     this.sweetalertOpen(JSON.stringify(err.statusText), 2)
    //     let board = this.itemList.find(x => x.id == boardsign_id);
    //     board.auto_suggestion = !e.target.checked;
    //   });
  }
  switch_show = true;
  onHideChangeAutoSuggest() {
    this.searchBoard();
    this.switch_show = false;
    setTimeout(() => {
      this.switch_show = true;
    }, 100);
  }

}