import { Component, OnInit } from '@angular/core';
import { ReportService } from 'src/app/services/user/report.service';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';
import { RouteDirectionEnum } from '../../../models/common-enum';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Router } from '@angular/router';
import * as ExcelJS from 'exceljs';
import * as FileSaver from 'file-saver';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { CommonService } from 'src/app/services/common.service';
import { ActionEnum } from 'src/app/models/common-enum';

@Component({
  selector: 'app-sensor-measurement-new',
  templateUrl: './sensor-measurement-new.component.html',
  styleUrls: ['./sensor-measurement-new.component.scss']
})
export class SensorMeasurementNewComponent implements OnInit {
  public allowCustom: boolean = false;
  values: any = null
  disCSV: boolean = false;
  cumulLabelItem: any[] = []
  cumulData: any[] = []
  feqData: number[] = [];
  feqLabelItem: string[] = []
  feq_max: number = 0
  feq_min: number = 0
  stationList: any
  laneChecked: any = [null, true, true, true, true]
  laneChecked_IN: any = [null, true, true, true, true]
  laneChecked_OUT: any = [null, true, true, true, true]
  classList: any = []

  public staList: Array<{ name: string, id: number }>;
  sta: any;
  directionEnum = RouteDirectionEnum;
  dir: number = this.directionEnum.IN;
  public data_sta: Array<{ name: string, id: number }>;
  st_dt: any;
  en_dt: any;
  classval: any = [];
  noData: boolean = false
  selectedId: number
  nums_lane: any
  disedSea: boolean = false;
  constructor(
    private reportService: ReportService,
    private dashboardService: DashboardService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location, 
    private commonService: CommonService) {
    this.commonService.activityLog(ActionEnum.Get, 'Traffic Information ➡ รายงานรายคันจาก SMC').subscribe(res => { }, error => { console.log(error); }); 
  }

  getId: any
  ngOnInit() {
    // this.search()
    this.noData = true

    this.route.paramMap.subscribe(params => {
      console.log(new Date(params.get("st_dt")))
      this.selectedId = Number(params.get("id"));
      if (params.get("st_dt")) this.st_dt = new Date(params.get("st_dt"));
      if (params.get("en_dt")) this.en_dt = new Date(params.get("en_dt"));

      if (!this.selectedId) {
        this.st_dt = null
        this.en_dt = null
        this.getStationList();
        setTimeout(() => {
          this.data_sta = _.cloneDeep(this.staList);
          // this.sta = this.data_sta[0];
        }, 1000);
      } else {
        this.getStationPER_();
      }
    });
  }
  backClicked() {
    console.log("Clicked")
    this.location.back();
  }
  handleFilter(value) {
    if (value.length > 0) {
      this.data_sta = this.data_sta.filter(s => s.name != null).filter((s) => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else if (value.length == 0) {
      this.data_sta = _.cloneDeep(this.staList);
    } else {
      this.data_sta = [];
    }
  }
  handleValue(value) {
    if (value) {
      this.sta = value;
      this.dashboardService.searchVehClass(this.sta.id).subscribe(
        res => {
          if (res.code == 1) {

            if (!_.isEmpty(res.data)) {
              this.classList = res.data
            } else {
              this.classList = []
            }
          }
        },
        err => {
          this.classList = []
          console.log(JSON.stringify(err.statusText));
        });
    } else {
      this.sta = null;
    }
  }
  async getStationList() {
    let point = [];
    this.dashboardService.getStationlistPER().subscribe(
      res => {
        if (res.code == 1) {
          this.staList = _.cloneDeep(res.data);
          this.data_sta = _.cloneDeep(res.data);

          this.stationList = _.cloneDeep(point);
        }
      },
      err => {
        console.log(JSON.stringify(err.statusText));
      });
  }
  async getStationPER_(data?: any) {
    let point = [];
    this.dashboardService.getStationPER_(data).subscribe(
      res => {
        if (res.code == 1) {
          this.staList = _.cloneDeep(res.data);
          this.data_sta = _.cloneDeep(res.data);


          setTimeout(() => {
            // this.data_sta = _.cloneDeep(this.staList);
            let filter_data = _.filter(this.staList, { id: Number(this.selectedId) });
            if (filter_data.length) {
              this.sta = filter_data[0]
              this.nums_lane = Array(Number(this.sta.num_lane)).fill(1).map((x, i) => i);
            }
          }, 1000);
          this.stationList = _.cloneDeep(point);
        }
      },
      err => {
        console.log(JSON.stringify(err.statusText));
      });
    this.dashboardService.searchVehClassPer().subscribe(
      res => {
        if (res.code == 1) {
          this.classList = res.data
        }
      },
      err => {
        console.log(JSON.stringify(err.statusText));
      });
  }
  search_data: any
  search() {
    this.disCSV = true
    this.disedSea = true
    this.noData = false
    this.classval = []
    this.feq_max = 0
    this.feq_min = 0
    this.feqLabelItem = []
    this.feqData = []
    this.cumulLabelItem = []
    this.cumulData = []
    this.values = null
    for (const i of this.classList) {
      if (i.checked) this.classval.push(i.veh_class_id)
    }
    let lane_: any = [];
    let data: any = {
      station_id: (this.sta) ? this.sta.id : null,
      veh_class: this.classval,
    }
    if (!this.selectedId) {
      for (const key in this.laneChecked) {
        if (Object.prototype.hasOwnProperty.call(this.laneChecked, key)) {
          const element = this.laneChecked[key];
          if (element) lane_.push(+key)
        }
      }
      data.lane = lane_;
    } else {
      lane_ = []
      for (const key in this.laneChecked_IN) {
        if (Object.prototype.hasOwnProperty.call(this.laneChecked_IN, key)) {
          const element = this.laneChecked_IN[key];
          if (element) lane_.push(+key)
        }
      }
      data.lane_in = lane_;
      lane_ = []
      for (const key in this.laneChecked_OUT) {
        if (Object.prototype.hasOwnProperty.call(this.laneChecked_OUT, key)) {
          const element = this.laneChecked_OUT[key];
          if (element) lane_.push(+key)
        }
      }
      data.lane_out = lane_;
    }
    if (this.st_dt) data.start_datetime = (this.st_dt) ? moment(this.st_dt, "DD/MM/YYYY").add(7, "hours").toISOString() : null
    if (this.en_dt) data.end_datetime = (this.en_dt) ? moment(this.en_dt, "DD/MM/YYYY").add(7, "hours").toISOString() : null
    this.search_data = data
    if (!this.selectedId) {
      this.dashboardService.searchSpeedMeasure(data).subscribe(
        res => {
          this.disCSV = false
          this.disedSea = false
          if (res.code == 1) {
            this.values = res.data
            this.noData = _.isEmpty(this.values)
            if (!this.noData) {

              for (const i of this.values.graph.freq) {
                this.feqLabelItem.push(i.label)
                this.feqData.push(i.value)
                if (i.value > this.feq_max) this.feq_max = i.value
                if (i.value < this.feq_min) this.feq_min = i.value
              }
              for (const i of this.values.graph.qumu) {
                this.cumulLabelItem.push(i.label)
                this.cumulData.push(i.value)
              }
            }
          }
        },
        err => {
          this.disCSV = false
          this.disedSea = false
          this.values = {}
          this.noData = true
          console.log(JSON.stringify(err.statusText));
        });

    } else {
      this.dashboardService.searchSpeedMeasure_Per(data).subscribe(
        res => {
          if (res.code == 1) {
            this.values = res.data
            this.noData = _.isEmpty(this.values)
            if (!this.noData) {

              for (const i of this.values.graph.freq) {
                this.feqLabelItem.push(i.label)
                this.feqData.push(i.value)
                if (i.value > this.feq_max) this.feq_max = i.value
                if (i.value < this.feq_min) this.feq_min = i.value
              }
              for (const i of this.values.graph.qumu) {
                this.cumulLabelItem.push(i.label)
                this.cumulData.push(i.value)
              }
            }
          }
        },
        err => {
          this.values = {}
          this.noData = true
          console.log(JSON.stringify(err.statusText));
        });
    }

    this.disCSV = false
    this.disedSea = false
  }
  changeClass() {
    // this.classval = this.classval.filter((item) => item.selected)
    console.log(this.classList)
  }

  async exportCleanLog() {
    this.disCSV = true;
    if (this.selectedId) this.search_data.per = 1
    let data: any = await this.getCSV(this.search_data);
    // this.is_loading_excel_log = true;
    if (!data) return this.disCSV = false;
    // const header = ["#", "site_name", "traffic_id", "traffic_timestamp", "speed", "volume", "vol_mid", "vol_long", "vol_extra_long", "vol_mid2", "vol_long2", "vol_other", "occupancy", "gap", "headway"];
    let lists: any = []
    const header = [];
    let num = 0
    for (const i of data) {
      if (i.datetime) i.datetime = moment(i.datetime, "DD/MM/YYYY HH:mm:ss,SSS").format("DD/MM/YYYY HH:mm:ss,SSS")
      if (i.sensor_ts) i.sensor_ts = moment(i.sensor_ts, "DD/MM/YYYY HH:mm:ss,SSS").format("DD/MM/YYYY HH:mm:ss,SSS")
      if (i.server_ts) i.server_ts = moment(i.server_ts, "DD/MM/YYYY HH:mm:ss,SSS").format("DD/MM/YYYY HH:mm:ss,SSS")
      let row = []
      for (const key in i) {
        if (num == 0) header.push(key)
        row.push(i[key])
      }
      lists.push(row)
      num++
    }
    //Create workbook and worksheet
    let workbook = new ExcelJS.Workbook();
    let worksheet = workbook.addWorksheet('Cleanlog');
    //Add Header Row
    worksheet.addRow(header);

    for (const i of lists) {
      worksheet.addRow(i);
    }
    workbook.views = [
      {
        x: 0, y: 0, width: 10000, height: 20000,
        firstSheet: 0, activeTab: 1, visibility: 'visible'
      }
    ]
    // });
    try {
      setTimeout(() => {
        // this.is_loading_excel_log = false;
        workbook.csv.writeBuffer().then((data) => {
          // data.setHeader('Content-Type', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
          let blob = new Blob([data], { type: "text/csv;charset=utf-8" });
          FileSaver.saveAs(blob, 'Speed Measurement.csv');
        });

        this.disCSV = false;
      }, 100);

    } catch (error) {
      console.log(error);
      this.disCSV = false;
    }

  }
  async getCSV(data) {
    return new Promise((resolve, reject) => {
      this.dashboardService.rawSpeedMeasure(data).subscribe(
        res => {
          if (res.code == 1) {
            resolve(res.data)
          } else {
            resolve(false)
          }
        },
        err => {
          console.log(JSON.stringify(err.statusText));
          resolve(false)
        });
    })
  }
}
