import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class DeviceService {
  rout_list : any = []
  constructor(
    private api : ApiService,
  ) { }
      
  async getRoute(req?:any){
    return new Promise((reslove,reject)=>{
      if(this.rout_list.length) return reslove(this.rout_list)
      this.api.getRoute().subscribe((res:any)=>{
        if(res && res.code == 1){
          this.rout_list = res.data;
          return reslove(res.data);
        }else{
          return reslove(false);
        }
      })
    })
  }
  async getDevicePermission(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.getDevicePermission(req).subscribe((res:any)=>{
        if(res && res.code == 1){
          return reslove(res.data);
        }else{
          return reslove(false);
        }
      })
    })
  }
  async getDeviceSmc(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.getDeviceSmc(req).subscribe((res:any)=>{
        if(res && res.code == 1){
          return reslove(res.data.data);
        }else{
          return reslove(false);
        }
      })
    })
  }
  async getTicket(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.getTicket(req).subscribe((res:any)=>{
        if(res && res.code == 1){
          return reslove(res.data.data);
        }else{
          return reslove(false);
        }
      })
    })
  }
}