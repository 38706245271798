<div class="menu-head gray-BG pl-4 pr-4 pt-4">
    <h5>
        <i class="fa fa-th-list pri-txt"></i> &ensp;
        Event Management List
    </h5>
</div>
<div class="modal-header menu-BG">
    <button *ngIf="isEditDetail" class="btn menu-BT" [ngClass]="{'active': chkMenu == 1}" (click)="chkMenu = 1">ข้อมูลเหตุการณ์</button>
    <button *ngIf="isActivityLog && values" class="btn menu-BT" [ngClass]="{'active': chkMenu == 2}" (click)="chkMenu = 2">การดำเนินการ</button>
    <!-- <button *ngIf="isNews" class="btn menu-BT" [ngClass]="{'active': chkMenu == 3}" (click)="chkMenu = 3">ข่าวสาร</button>
    <button *ngIf="isSignControl && values" class="btn menu-BT" [ngClass]="{'active': chkMenu == 4}" (click)="chkMenu = 4;">สรุปเหตุการณ์</button> -->
    <!-- <button *ngIf="isSignControl && values" class="btn menu-BT" [ngClass]="{'active': chkMenu == 4}" (click)="chkMenu = 4;gotoSign()">Sign Control</button> -->
</div>
<!-- 4=========== -->
<div *ngIf="chkMenu == 4" class="modal-body text-left">
    <p>เหตุการณ์ : {{values.event_type_name || ''}}</p>
    <p>เมื่อ : {{values?.occur_datetime | date:'HH:mm dd/MM/yyyy'}}</p>
    <p>ที่มา : {{values.src_type_name}}</p>
    <p>ตำแหน่งอุปกรณ์ : เส้นทาง ทล. {{values.route}} {{values.km}}+{{values.m}}</p>
    <br>
    <p>การตรวจสอบ : {{values.event_confirm_name||''}}</p>
    <br>
    <p>การดำเนินการ : </p>
    <p *ngFor="let i of act_list"><span class="bold pr-4" style="font-size: 20px;">{{i.created_datetime | date:'dd/MM/yyyy HH:mm':'dd/MM/yyyy +0700'}} &emsp; </span><span>{{i.msg}}</span></p>
</div>
<div class="modal-footer" *ngIf="chkMenu == 4">
    <button type="button" class="btn btn-danger mr-1" (click)="exportPdf()"><i class="fa fa-file-pdf-o"></i> พิมพ์รายงาน
      <i *ngIf="is_loading_pdf"
        style="color:#FFF" class="fa fa-spinner fa-spin fa-fw" aria-hidden="true"></i> </button>
    <button type="button" class="btn btn-danger ml-1" (click)="close()">ยกเลิก</button>
</div>
<!-- 3=========== -->
<div *ngIf="chkMenu == 3" class="modal-body text-center">
    <app-news [from_event]="true"></app-news>
</div>
<div class="modal-footer" *ngIf="chkMenu == 3">
    <button type="button" class="btn btn-danger" (click)="close()">ยกเลิก</button>
</div>
<!-- 2=========== -->
<div *ngIf="chkMenu == 2" class="modal-body text-center">
    <div class="row">
        <div class="col-8 text-left blog-BG" #blogBG>
            <div *ngFor="let i of act_list;index as idx" class="d-flex log-BG mb-3">
                <p class="m-0">{{i.created_dt | date:'dd/MM/yyyy':'+0700'}}<br>{{i.created_dt | date:'HH:mm':'+0700'}}น.</p>
                <div class="position-relative ml-3 mr-3">
                    <div *ngIf="idx!==act_list.length-1" class="line-BG"></div>
                    <div class="sta-BG"></div>
                    <div class="position-absolute sta-BG done"[ngClass]="{
                        'reject': !i.status,
                        'reject': i.status == 5,
                        'create': i.status == 1,
                        'operate': i.status == 3,
                        'done': i.status == 4
                    }" style="top:0px"></div>
                </div>
                <div class="msg-BG">
                    <p>{{i.message}}</p>
                    <div *ngIf="i.image&&i.image.length">
                        <img *ngFor="let j of i.image" [src]="j" alt="" style="max-height: 50px;">
                    </div>
                </div>
                <!-- <div class="name-BG"><p>{{i.created_by||''}}</p></div> -->
                <div class="name-BG"><p *ngIf="info==i.u_id">ME</p></div>
            </div>

        </div>
        <div class="col-4 colum-text-BG">
            <!-- <div class="card">
                <div class="card-header">
                    ขั้นตอนการปฏิบัติการสำหรับเจ้าหน้าที่
                </div>
                <div class="card-body">
                   <div class="contact-BG" (click)="contact_sta = 1;msg='ติดต่อการไฟฟ้า'">
                       ติดต่อการไฟฟ้า
                       <i [ngClass]="(contact_sta&&contact_sta==1)?'clicked':'out'" class="fa fa-chevron-down" aria-hidden="true"></i>
                   </div>
                   <div class="contact-BG" (click)="contact_sta = 2;msg='ติดต่อสถานีดับเพลิง'">
                       ติดต่อสถานีดับเพลิง
                       <i [ngClass]="(contact_sta&&contact_sta==2)?'clicked':'out'" class="fa fa-chevron-down" aria-hidden="true"></i>
                   </div>
                   <div class="contact-BG" (click)="contact_sta = 3;msg='เจ้าหน้าที่ช่วยเหลือ'">
                       เจ้าหน้าที่ช่วยเหลือ
                       <i [ngClass]="(contact_sta&&contact_sta==3)?'clicked':'out'" class="fa fa-chevron-down" aria-hidden="true"></i>
                   </div>
                   <div class="contact-BG" (click)="contact_sta = 4;msg='การจราจรปกติ'">
                       การจราจรปกติ
                       <i [ngClass]="(contact_sta&&contact_sta==4)?'clicked':'out'" class="fa fa-chevron-down" aria-hidden="true"></i>
                   </div>
                </div>
            </div> -->
            <div class="colum-text">
                <button class="btn btn-outline-info mb-2" (click)="gotoSign(gotoBoardSign)">สั่งเปลี่ยนป้ายบนสายทาง</button>
                <button class="btn btn-outline-info mb-2" (click)="chkMenu = 3">ให้ข่าวสารผู้ใช้ทาง</button>
            </div>
            <div class="colum-text">
                <button class="btn btn-info mb-2" (click)="finishEvt()">สิ้นสุดเหตุการณ์</button>
                <button class="btn btn-success mb-2" (click)="exportPdf()">ออกรายงาน</button>
                <!-- <button class="btn btn-success mb-2" (click)="pdf.saveAs('invoice.pdf')">ออกรายงาน</button> -->
            </div>
            <!-- <kendo-pdf-export #pdf paperSize="A4" margin="2cm">
            MMMM
            </kendo-pdf-export> -->
        </div>
        <div class="col-8">
            <!-- <div class="input-group mb-3 mt-3">
                <div *ngFor="let i of images" class="addimg-BG position-relative">
                    <img [src]="i" alt="Image" style="max-height: 60px;">
                    <button (click)="rmImage(i)">X</button>
                </div>
                <input type="file" name="" id="addImage" accept="image/*" (change)="uploadFile(Images)" onerror="this.src='assets/noimage.png'" #Images>
                <Label for="addImage" class="ml-3 mb-0 btn btn-info addImg-BT">
                    <i class="icon-plus"></i>
                </Label>
            </div> -->
            <div class="align-items-center input-group">
                <input type="text" class="form-control" [(ngModel)]="msg" (keydown.enter)="sendMsg()">
                <div class="input-group-prepend">
                    <button class="btn-info btn" [disabled]="!msg" (click)="sendMsg()">
                        <!-- <i class="icon-plus"></i> -->
                        ส่ง
                    </button>
                </div>
                <!-- <select name="" id="" class="form-control" style="max-width:150px;margin-left: 5px;" [(ngModel)]="actlog_status">
                    <option value=null selected disabled>Please select activity type</option>
                    <option value=1>Created</option>
                    <option value=2>Approved</option>
                    <option value=3>Operating</option>
                    <option value=4>Clear</option>
                    <option value=5>Rejected</option>
                </select> -->
            </div>
        </div>
        <div class="col-4">
        </div>
        <!-- <div class="col-4 status-BG position-relative d-flex justify-content-between">
            <div><p>Reject</p></div>
            <div><p>Create</p></div>
            <div><p>Operate</p></div>
            <div><p>Close</p></div>
            <div></div>
        </div> -->
    </div>
</div>

<div class="modal-footer" *ngIf="chkMenu == 2">
    <button type="button" class="btn btn-danger" (click)="close()">ยกเลิก</button>
</div>
<!-- 1=========== -->
<div *ngIf="chkMenu == 1" class="modal-body text-center">
    <div class="row">
        <!-- <div class="col-12 mb-3 text-left">

            <button *ngIf="isReport" class="ml-1 mr-2 btn btn-outline-warning" [disabled]="_getReport" (click)="_getReport=true;getReport()">
                <i class="fa {{_getReport? 'fa-spinner':'fa-line-chart'}}"></i>
            </button>
            <button class="btn btn-outline-info mr-2" (click)="openDetail()"><i class="fa fa-pencil"></i></button>
            <button class="btn btn-outline-danger" (click)="rmlist()"><i class="fa fa-trash"></i></button>
        </div> -->
        <div class="col-6">
            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <p class="input-group-text">ประเภทเหตุการณ์<span class="red-txt"> *</span></p>
                </div>
                <!-- <input type="text" class="form-control" [(ngModel)]="values.event_group_name"> -->
                <select [(ngModel)]="stypeId" name="type_id" id="" class="form-control" [disabled]="values&&values.src_group_id==2" (change)="chgType($event)">
                    <option value=null>ไม่สามารถระบุได้</option>
                    <option *ngFor="let i of typeList" [value]="i.id">{{i.name}}</option>
                </select>
            </div>
        </div>
        <div class="col-6">
        </div>
        <div class="col-6">
            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <p class="input-group-text">ที่มาของเหตุการณ์</p>
                </div>
                <!-- <input type="text" class="form-control" [(ngModel)]="values.src_type_name"> -->
                <select [(ngModel)]="src_type" class="form-control" [disabled]="values&&values.src_group_id==2">
                    <option value="">Select</option>
                    <option [value]="1">Radio</option>
                    <option [value]="2">Tel</option>
                    <option [value]="3">Social Media</option>
                    <option [value]="4">ข้อมูลจากส่วนกลาง</option>
                    <option [value]="8">อื่นๆ</option>
                </select>
            </div>
        </div>
        <!-- <div class="col-6">
        </div> -->
        <div class="col-6">
            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <p class="input-group-text">เวลาเริ่ม</p>
                </div>
                <!-- <input type="text" class="form-control"> -->
                <!-- <div class="form-control input-group-append">
                    <p>{{values?.occur_datetime | date:'dd/MM/yyyy HH:mm:ss':'+0700'}}</p>
                </div> -->
                <kendo-datepicker style="max-width: 300px;" [(ngModel)]="occur_datetime" class="w-100"
                    [format]="'dd-MMM-yyyy'" [disabled]="values&&values.src_group_id==2"></kendo-datepicker>
            </div>
        </div>
        <div class="col-6">
        </div>
        <div class="col-12">
            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <p class="input-group-text">เลือกพื้นที่การเกิดเหตุ<span class="red-txt"> *</span></p>
                </div>

                <select name="" id="" class="form-control" [(ngModel)]="asp_type">
                    <option value="1">แบบจุด</option>
                    <option value="2">แบบเส้น</option>
                </select>
            </div>
        </div>
        <div class="col-12">
            <div class="input-group mb-3">

                <div class="input-group-prepend">
                    <p class="input-group-text">พื้นที่เกิดเหตุต้นทาง</p>
                </div>
                <label class="ml-3 src">
                    <input type="radio" name="dir1" id="" [value]="1" [(ngModel)]="dir1" [disabled]="disabled && (dir1!==1)">
                    ขาเข้า
                </label>
                <label class="ml-3 src">
                    <input type="radio" name="dir1" id="" [value]="2" [(ngModel)]="dir1" [disabled]="disabled && (dir1!==2)">
                    ขาออก
                </label>
                <label class="ml-3 src">
                    <input type="radio" name="dir1" id="" [value]="3" [(ngModel)]="dir1" [disabled]="disabled && (dir1!==3)">
                    ขาเข้า-ขาออก
                </label>
            </div>
        </div>
        <div class="col-12">
            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <p class="input-group-text">หมายเลขทางหลวง</p>
                </div>
                <kendo-combobox class="form-control" [data]="route_list" [allowCustom]="allowCustom"
                [suggest]="true" [(ngModel)]="search_route"[filterable]="true" [placeholder]="'ทั้งหมด'"
                (filterChange)="FilterRoute($event)"  (valueChange)="ChageRoute($event)" [disabled]="disabled"></kendo-combobox>
                <div class="input-group-prepend">
                    <p class="input-group-text">กิโลเมตร</p>
                </div>
                <input type="text" [(ngModel)]="km1" class="form-control" [disabled]="disabled">
                <div class="input-group-prepend">
                    <p class="input-group-text">เมตร</p>
                </div>
                <input type="text" [(ngModel)]="m1" class="form-control" [disabled]="disabled">
            </div>
        </div>
        <div *ngIf="asp_type == 2" class="col-12">
            <div class="input-group mb-3">

                <div class="input-group-prepend">
                    <p class="input-group-text">พื้นที่เกิดเหตุปลายทาง</p>
                </div>
                <label class="ml-3 src">
                    <input type="radio" name="dir2" id="" [value]="1" [(ngModel)]="dir2" [disabled]="disabled && (dir2!==1)">
                    ขาเข้า
                </label>
                <label class="ml-3 src">
                    <input type="radio" name="dir2" id="" [value]="2" [(ngModel)]="dir2" [disabled]="disabled && (dir2!==2)">
                    ขาออก
                </label>
                <label class="ml-3 src">
                    <input type="radio" name="dir2" id="" [value]="3" [(ngModel)]="dir2" [disabled]="disabled && (dir2!==3)">
                    ขาเข้า-ขาออก
                </label>
            </div>
        </div>
        <div *ngIf="asp_type == 2" class="col-12">
            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <p class="input-group-text">ตำแหน่ง</p>
                </div>
                <kendo-combobox class="form-control" [data]="route_list" [allowCustom]="allowCustom"
                [suggest]="true" [(ngModel)]="search_route2"[filterable]="true" [placeholder]="'ทั้งหมด'"
                (filterChange)="FilterRoute($event)"  (valueChange)="ChageRoute($event)"></kendo-combobox>
                <div class="input-group-prepend">
                    <p class="input-group-text">กิโลเมตร</p>
                </div>
                <input type="text" [(ngModel)]="km2" class="form-control">
                <div class="input-group-prepend">
                    <p class="input-group-text">เมตร</p>
                </div>
                <input type="text" [(ngModel)]="m2" class="form-control">
            </div>
        </div>
        <!-- <div class="col-12">
            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <p class="input-group-text align-items-baseline">ภาพประกอบ</p>
                </div>
                <div class="device-info">
                    <div *ngFor="let i of images_evtdetail" class="addimg-BG position-relative">
                        <img [src]="i" alt="images_evtdetail" style="max-height: 60px;" class="pointer" (click)="clickImg(i)" onerror="this.src='assets/noimage.png'">
                    </div>
                </div>
            </div>
        </div> -->
        <div class="col-12">
            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <p class="input-group-text">ภาพประกอบ</p>
                </div>

                <div class="allImg-BG">
                    <div *ngFor="let i of images_Evtdetail" class="addimg-BG position-relative">
                        <img [src]="i.link" class="pointer" alt="images_Evtdetail" style="max-height: 60px;" onerror="this.src='assets/noimage.png'" (click)="clickImg(i.link)">
                        <button *ngIf="!disabled" (click)="rmImage(i)">X</button>
                    </div>
                    <input type="file" name="" id="addImage" accept="image/*" (change)="uploadImg(ImagesEvt,1)" #ImagesEvt>
                    <Label *ngIf="!disabled && (event_group !== 2)" for="addImage" class="ml-3 mb-0 btn btn-info addImg-BT">
                        <i class="icon-plus"></i>
                    </Label>
                </div>
            </div>
        </div>
        <div class="col-12">
            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <p class="input-group-text align-items-baseline">บันทึกเพิ่มเติม</p>
                </div>
                <textarea class="form-control" rows="5" [(ngModel)]="notes"></textarea>
            </div>
        </div>
        <div class="col-12" *ngIf="values&&values.status == 4">
            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <p class="input-group-text align-items-baseline">Comment</p>
                </div>
                <div class="form-control input-group-append disabled">
                    <p>{{values.comment}}</p>
                </div>
            </div>
        </div>

        <!-- xxxxxxxxxxxxxxxxxxxxxxx -->

        <!-- <div class="col-6">
            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <p class="input-group-text">Create date</p>
                </div>
                <div class="form-control input-group-append">
                    <p>{{values.created_datetime | date:'dd/MM/yyyy HH:mm:ss':'+0700'}}</p>
                </div>
            </div>
        </div>
        <div class="col-6">
            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <p class="input-group-text">แจ้งเตือนจาก</p>
                </div>
                <div class="form-control input-group-append">
                    <p>{{values.src_group_name || ''}}</p>
                </div>
            </div>
        </div>
        <div class="col-6">
            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <p class="input-group-text">เวลาเริ่มต้นเหตุการณ์</p>
                </div>
                <div class="form-control input-group-append">
                    <p>{{values?.occur_datetime | date:'dd/MM/yyyy HH:mm:ss':'+0700'}}</p>
                </div>
            </div>
        </div>
        <div class="col-6">
            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <p class="input-group-text">ที่มาของข้อมูล</p>
                </div>
                <div class="form-control input-group-append">
                    <p>{{values.src_type_name || ''}}</p>
                </div>
            </div>
        </div>
        <div class="col-12">
            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <p class="input-group-text">ประเภทเหตุการณ์</p>
                </div>
                <div class="form-control input-group-append">
                    <p>{{values.event_group_name || ''}}</p>
                </div>
            </div>
        </div>
        <div *ngIf="values.event_group_id !== 2" class="col-12">
            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <p class="input-group-text">กีดขวางช่องทาง</p>
                </div>
                <div class="form-control input-group-append">
                    <p>{{values.route | number:'4.0-0' | noComma}} {{(values.km||values.m)? '('+(values.km||0)+(values.m? '+'+values.m:'')+')' : ''}}</p>
                </div>
            </div>
        </div> -->

        <!-- <div class="col-12" *ngIf="values.event_type_id != 7">
            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <p class="input-group-text">รูปภาพประกอบ</p>
                </div>

                <div *ngFor="let i of images_evtdetail" class="addimg-BG position-relative">
                    <img [src]="i" alt="images_evtdetail" style="max-height: 60px;" class="pointer" (click)="clickImg(i)" onerror="this.src='assets/noimage.png'"> -->
                    <!-- <button *ngIf="!disabled" (click)="rmImage(i)">X</button> -->
                <!-- </div> -->
                <!-- <input type="file" name="" id="addImage" accept="image/*" (change)="uploadFile(ImagesInc,1)" #ImagesInc>
                <Label *ngIf="!disabled" for="addImage" class="ml-3 mb-0 btn btn-info addImg-BT">
                    <i class="icon-plus"></i>
                </Label> -->
            <!-- </div>
        </div> -->

        <!-- <div class="col-12" *ngIf="values.event_type_id == 7">
            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <p class="input-group-text align-items-baseline">ข้อมูลอุปกรณ์</p>
                </div>
                <div class="device-info">
                    <p>{{values?.additional_data?.event_desc||''}}</p>
                    <div class="d-flex">
                        <p class="name">{{values?.additional_data?.device_name||''}}</p>
                        <div class="detail">
                            <p class="text-right" ngPreserveWhitespaces>
                                Board &ensp;:&ensp; <br>
                                Temperature &ensp;:&ensp; <br>
                                Play &ensp;:&ensp; <br>
                                Brightness &ensp;:&ensp; <br>
                                Image sync &ensp;:&ensp; <br>
                            </p>
                            <p>
                                <span *ngIf="values.additional_data.board_status" class="green-txt bold">online</span>
                                <span *ngIf="!values.additional_data.board_status" class="red-txt bold">offline</span><br>
                                {{values?.additional_data?.temp||''}}<br>
                                {{values?.additional_data?.image_name||''}}<br>
                                {{values?.additional_data?.brightness||''}}<br>
                                {{(values?.additional_data?.image_sync)? 'not sync':'sync'}}<br>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->

        <!-- <div class="col-12">
            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <p class="input-group-text">ลายเซ็นต์</p>
                </div>
                <div class="form-control input-group-append h-100" style="min-height: 35px;">
                    <img *ngIf="values.img_signature_path" [src]="values.img_signature_path" alt="license" style="max-height: 400px;"  onerror="this.src='assets/noimage.png'">
                </div>
            </div>
        </div> -->
        <!-- <div class="col-12 text-right">
            <div class="w-50">
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                        <p class="input-group-text">การยืนยันเหตุการณ์</p>
                    </div>
                    <select class="form-control pointer" [disabled]="values.status!=1" (change)="chgStatus()" [(ngModel)]="evtConfirm">
                        <option [value]="null"></option>
                        <option *ngFor="let i of evtConfirmList" [value]=i.id>{{i.name}}</option>
                    </select>
                </div>
            </div>
        </div> -->
    </div>
</div>
<div class="modal-footer justify-content-between d-flex" *ngIf="chkMenu == 1">
    <div *ngIf="values&&values.src_group_id==2 && values.status!=4">
        <button type="button" class="btn btn-warning mr-2" (click)="_approved=true;comment='';openRemark(remarkBG)">ยืนยันเหตุการณ์</button>
        <button type="button" class="btn btn-outline-warning" (click)="_approved=false;comment='';openRemark(remarkBG)">ไม่พบเหตุการณ์</button>
    </div>
    <div></div>
    <div>
        <button type="button" class="btn btn-danger mr-2" (click)="cancel()">ปิด</button>
        <button type="button" class="btn btn-info" (click)="save()" [disabled]="!typeId">ยืนยัน</button>
    </div>
    <!-- <button *ngIf="isManageInc" type="button" class="btn btn-info" [disabled]="(!name || !search_route)? 'disabled': null" (click)="save()">ยืนยัน</button> -->
</div>

<ng-template #remarkBG class="remark-BG">
    <div class="modal-header">
      <h4 class="modal-title pull-left">Comment</h4>
      <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="modalRef_Detail?.hide()">
        <span aria-hidden="true" class="visually-hidden">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <input type="text" class="form-control" [(ngModel)]="comment">
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-info" [disabled]="!comment" (click)="updateEvt(_approved)">ยืนยัน</button>
    </div>
  </ng-template>
<ng-template #gotoBoardSign class="remark-BG">
    <div class="modal-header">
      <h4 class="modal-title pull-left">Event Sign Control</h4>
      <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="modalRef_Detail?.hide()">
          <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <h5 class="modal-title text-left">เลือกประเภทป้าย</h5>
        <div class="d-flex w-100">
            <label class="mr-3 pointer" for="s_evt_type[1]">
                <input type="checkbox" id="s_evt_type[1]" [(ngModel)]="s_evt_type['ATIS']">
                ATIS
            </label>
            <label class="mr-3 pointer" for="s_evt_type[2]">
                <input type="checkbox" id="s_evt_type[2]" [(ngModel)]="s_evt_type['VSW']">
                VSW
            </label>
            <!-- <label class="mr-2 pointer" for="s_evt_type[3]">
                <input type="checkbox" id="s_evt_type[3]">
                type3
            </label>
            <label class="mr-2 pointer" for="s_evt_type[4]">
                <input type="checkbox" id="s_evt_type[4]">
                type4
            </label> -->
        </div>
        <div>
            <h5 class="modal-title text-left">รัศมีกิโลเมตร (กิโลเมตร)</h5>
            <input type="number" class="form-control" [(ngModel)]="r_km">
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-info" [disabled]="!r_km" (click)="clickGotoSign()">ดำเนินการต่อ</button>
    </div>
  </ng-template>
