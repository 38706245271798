import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth/auth.service';
import { environment } from 'src/environments/environment';
import { CommonService } from 'src/app/services/common.service';
import { ActionEnum } from 'src/app/models/common-enum';
@Component({
  selector: 'app-traveltime',
  templateUrl: './traveltime.component.html',
  styleUrls: ['./traveltime.component.scss']
})
export class TraveltimeComponent implements OnInit {
  url: any;
  constructor(private authService: AuthService, private commonService: CommonService) { 
    this.commonService.activityLog(ActionEnum.Get, 'Traffic Information ➡ ตรวจจับความเร็ว/ สำรวจข้อมูลสภาพอากาศ').subscribe(res => { }, error => { console.log(error); });
   }

  ngOnInit(): void {
    const token = this.authService.user_token;
    const username = this.authService.user_name;
    if (username && token) {
      this.url = `http://www.ntdcloud.com/traveltime/?client_id=${username}&token=${token}&from_web=${environment.from_web}`;
      // const url1  = `http://www.ntdcloud.com/speeddetect/?client_id=${username}&token=${token}`;
      // this.url = url1 + "&output=embed";
      // window.parent.location.replace(this.url);
    }
    console.log(token);
    console.log(username);
    console.log(this.url);
  }

}
