// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  from_web: 1,
  api: {
    domain_assets: "https://api.iwz.transcode.co.th/api/asset-service",
    domain_auth: "https://api.iwz.transcode.co.th/api/auth-service",
    domain_sign_control: "https://api.iwz.transcode.co.th/api/signctrl-service",
    domain_traffic_tool: "",
    domain_event: "",
    domain_information: "",
    domain_report: "https://api.iwz.transcode.co.th/report",

    domain_old_jarvis_report_export_api: "https://report.toc.highwaytraffic.go.th",
    // traffic_api: 'https://congression-alert.shw.transcodeglobal.com',
    traffic_api: 'https://uat-api.shw.transcode.co.th/api/tt-analytic-service',
    socials_api: 'https://api.shw.transcode.co.th', // http://159.65.9.27:9091
  },
  vsw_group_id: 1012,
  vsw_device_type: 1001,
  vsw_device_subtype: 1001,
  security_group_id: 1013,
  security_device_type: 1002,
  security_device_subtype: 1002,
  firebase: {
    apiKey: "AIzaSyDAglrUPPC39MCxtyWjdz5QecalfqPHjvM",
    authDomain: "shw-tc.firebaseapp.com",
    databaseURL: "https://shw-tc.firebaseio.com",
    projectId: "shw-tc",
    storageBucket: "shw-tc.appspot.com",
    messagingSenderId: "886032913437",
    appId: "1:886032913437:web:6f09c1560d06d385b622f4",
    measurementId: "G-NJV6MZ2XWX"
  },
  mapbox: {
    accessToken: 'pk.eyJ1IjoibWF5c2FpIiwiYSI6ImNrZmdodDFhbDAydDEyd3E5ZGl1d3RkMTYifQ.yRq45sGr7-cQ73hIxX8P3Q',
    style: 'mapbox://styles/mapbox/streets-v11'
  },
  sentry: {
    dsn: 'https://e398cef9f7874501a6432dd62110fee6@sentry.devcrew.co.th/14'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
