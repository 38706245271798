export const navItems = [
    {
        id: 1200,
        name: 'Dashboard',
        url: `/dashboard`,
        icon: 'icon-speedometer',
    },
    {
        id : 1100,
        name: 'Traffic Information',
        url: '/traffictool',
        icon: 'fa fa-road',
    },{
        id: 100,
        name: 'Event Management ',
        url: `/event`,
        icon: 'fa fa-exclamation-triangle',
    },
    {
        id : 200,
        name: 'Sign Control',
        url: '/sign-control',
        icon: 'fa fa-clone',
    },
    {
        id : 800,
        name: 'Assets Management',
        url: '/assets-management',
        icon: 'fa fa-cog',
    },
    {
        id : 1000,
        name: 'User Management',
        url: '/user-management',
        icon: 'fa fa-user-circle-o',
    },
    {
        id: 900,
        name: 'Information',
        url: `/information`,
        icon: 'icon-info',
    },
    {
        id: 1300,
        name: 'Report',
        url: `/report`,
        icon: 'icon icon-graph',
    },
    {
        id: 1400,
        name: 'Previous Version',
        url: `/previous-version`,
        icon: 'icon icon-screen-desktop',
    }
];
