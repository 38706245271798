<div class="main-BG container-fluid">
  <div class="row">
      <!-- <div class="col-12">
          <h5>Event ประเภทเหตุการณ์</h5>
      </div> -->
      <div class="p-0 col-9">
          <div class="map" id="map-event"></div>
      </div>
      <div class="p-0 col-3">
          <div class="card mt-4">
              <div class="card-header">
                  <h3>Filter</h3>
              </div>
              <div class="card-body">
                  <kendo-multiselect #multiselect [data]="routeItems" [(ngModel)]="route" class="" placeholder="Please select route" [filterable]="true" (filterChange)="chgRoute($event)"></kendo-multiselect>
                  <div class="d-flex mt-2">
                      <input type="text" placeholder="Start km" class="form-control" [(ngModel)]="st_km">
                      <span style="margin: 8px;">-</span>
                      <input type="text" placeholder="End km" class="form-control" [(ngModel)]="en_km">
                  </div>
                  <kendo-multiselect [data]="sTypeItems" [(ngModel)]="sType" class="mt-2" textField="name"
                  valueField="id" placeholder="Please select subtype" [filterable]="true" (filterChange)="chgsType($event)"></kendo-multiselect>
                  <kendo-multiselect [data]="StatusItems" [(ngModel)]="Status" class="mt-2" textField="name"
                  valueField="id" placeholder="Please select status" [filterable]="true" (filterChange)="chgStatus($event)"></kendo-multiselect>
              </div>
              <div class="card-footer text-center">
                  <button class="btn btn-info" (click)="search()">Search</button>
              </div>
          </div>
          <div *ngIf="loading">
              <div class="loading">
                  <div class="bar bar1"></div>
                  <div class="bar bar2"></div>
                  <div class="bar bar3"></div>
                  <div class="bar bar4"></div>
                  <div class="bar bar5"></div>
                  <div class="bar bar6"></div>
                  <div class="bar bar7"></div>
                  <div class="bar bar8"></div>
                </div>
          </div>
          <div *ngIf="!loading&&lists.length==0" class="text-center">
              No data
          </div>
          <div class="detail-BG">
              <div *ngFor="let i of lists" class="card b-card">
                  <div class="card-body detailmap-BG type-{{i.img}} c-pointer" (click)="flytoI(i)">
                      <img src="assets/img/event/{{i.img||1}}.png" alt="">
                      <div class="inner-txt">
                          <h5>{{i.subtype_name||''}}</h5>
                          <div class="sdetail-BG">
                              <div class="">
                                  <p>
                                      <img src="assets/img/event/road.png" alt="">
                                      {{i.route_name||''}}
                                  </p>
                                  <p>
                                      <img src="assets/img/event/clock.png" alt="">
                                      {{(i.occur_datetime| date : 'dd/MM/yyyy HH:mm:ss') ||''}}
                                  </p>
                              </div>

                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>

<!-- <div class="map-panel">
  <div class="map" id="map-event"></div>
  <div class="box-list-panel">
    <div class="p-2 flex-fill d-flex justify-content-center align-items-center disable-select box-list-panel-header">
      <span>ประเภทเหตุการณ์</span>
    </div>
    <div class="pr-3 pb-3 pl-3 pt-1 box-list-item-panel box-scrollbar-style">
      <div *ngFor="let item of type_list; let i = index"
        class="mt-2 pl-3 pr-2 pt-2 pb-1 d-flex justify-content-start align-items-center bg-gray-900 rounded">
        <label class="checkbox-container">
          <span>{{item?.name}}</span>
          <input type="checkbox" [(ngModel)]="item.checked" (change)="clickType($event)"
            [ngModelOptions]="{standalone: true}">
          <span class="checkmark-box"></span>
        </label>
      </div>
      <div class="mt-2 p-3 d-flex justify-content-start align-items-center bg-gray-900 rounded">
        <div class="mr-1" style="white-space: nowrap;">ทางหลวง :</div>
        <kendo-combobox class="w-100" [data]="data_route" [allowCustom]="allowCustom" [textField]="" [valueField]=""
          [value]="search_route" (valueChange)="handleValueRoute($event)" (filterChange)="handleFilterRoute($event)"
          [placeholder]="'ทั้งหมด'" [filterable]="true">
        </kendo-combobox>
      </div>
      <div class="mt-2 p-3 bg-gray-900 rounded">
        <div>หลักกิโลเมตร :</div>
        <div class="d-flex">
          <div class="w-50">
            <div class="input-group-text d-flex justify-content-center">เริ่มต้น</div>
            <input type="text" class="form-control text-center" [(ngModel)]="st_km" placeholder="กม." numbersOnly
              maxlength="4" (ngModelChange)="changeStKm($event)">
          </div>
          <div class="w-50">
            <div class="input-group-text d-flex justify-content-center">สิ้นสุด</div>
            <input type="text" class="form-control text-center" [(ngModel)]="en_km" placeholder="กม." numbersOnly
              maxlength="4" (ngModelChange)="changeEnKm($event)">
          </div>

        </div>
      </div>
    </div>
  </div> -->