import { NodeCompatibleEventEmitter } from "rxjs/internal/observable/fromEvent";

export class DeviceInfo {
    device_id?: number;
    station_id?: number;
    station_str?: string;
    connection_status?: number;
    device_group_id?: number;
    device_type_id?: number;
    device_type_str?: string;
    device_sub_type_id?: number;
    device_sub_type_str?: string;
    ip?: string;
    name?: string;
    serial_no?: string;
    sim_number?: string;
    link_snapshot?: string;
    brand_id?: number;
    model_id?: number;
    owner_id?: number;
    owner_desc?: string;
    vendor_id?: number;
    vendor_desc?: string;
    vendor_contact_id?: number;
    vendor_contact_desc?: string;
    install_ts?: Date;
    ma_expire_ts?: Date;
    is_monitor?: boolean;
    image_url?: string[];
    create_date?: Date;
    update_date?: Date;
    status?: number;
    status_str?: string;
    steaming?: DeviceSteaming[];
    veh_class?: VehClassInfo[];
    num_class?: number;
}
export class DeviceSteaming {
    steaming_id?: number;
    link: string;
    type: number;
    device_id?: number;
}
export class ReqAddDevice {
    device: DeviceInfo;
    steaming: DeviceSteaming[];
}
export class SteamingTypeInfo {
    link?: string;
    type?: ItemNameType;
}
export class ItemNameType {
    id: number;
    name: string;
}
export class VehClassInfo {
    id?: number;
    model_id?: number;
    veh_class_id?: number;
    veh_class_name?: string;
    veh_short_name?: string;
    is_default?: boolean;
    status?: number;
}