import { Component, OnInit,EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FuncService } from 'src/app/services/user/func.service';
import { InstitutionService } from 'src/app/services/user/institution.service';
import { CommonService } from 'src/app/services/common.service';
import { ActionEnum } from 'src/app/models/common-enum';
import { ApiService } from 'src/app/services/user/api.service';
import * as _ from 'lodash';
@Component({
  selector: 'app-institution-modal',
  templateUrl: './institution-modal.component.html',
  styleUrls: ['./institution-modal.component.scss']
})
export class InstitutionModalComponent implements OnInit {

  public event: EventEmitter<any> = new EventEmitter();
  values : any;
  id : number 
  name : string 
  tel_1 : string 
  tel_2 : string 
  remark : string 
  hashtag : string 
  constructor(
    private func:FuncService,
    public bsModalRef: BsModalRef,
    private insS: InstitutionService,
    private commonService: CommonService,
    private apiService: ApiService,

  ) { }

  ngOnInit(): void {
    
    console.log(this.values)
    if(this.values){
      this.id = this.values.id || null
      this.name = this.values.name || ''
      this.tel_1 = this.values.tel_1 || ''
      this.tel_2 = this.values.tel_2 || ''
      this.remark = this.values.remark || ''
      this.hashtag = this.values.hashtag || ''
    }
  }
  
  async save(){
    let val : any = {}
    if(this.id) val.id = this.id || ''
    if(this.name) val.name = this.name || ''
    if(!this.name) return this.func.alertpopup('ลืมกรอกชื่อหน่วยงาน')
    if(this.tel_2) val.tel_2 = this.tel_2 || ''
    if(this.tel_1) {
      if(!this.func._isTelhome(this.tel_1)) return this.func.alertpopup('กรุณากรอกเบอร์โทรศัพท์ 1 ให้ถูกต้อง')
      val.tel_1 = this.tel_1 || ''
    }
    if(this.tel_2) {
      if(!this.func._isTelhome(this.tel_2)) return this.func.alertpopup('กรุณากรอกเบอร์โทรศัพท์ 2 ให้ถูกต้อง')
      val.tel_2 = this.tel_2 || ''
    }
    if(this.remark) val.remark = this.remark || ''
    if(this.hashtag) val.hashtag = this.hashtag || ''

    const req1 = _.cloneDeep(val);
    let txt_req = _.cloneDeep(req1);

    let res : any = await this.insS.addIns(val)
    if(res.id){
      this.confirm();
      let action = ActionEnum.Add;
      let request_data = { url: `${this.apiService.api.ins}`, request: req1 };
      if (req1.id) {
        action = ActionEnum.Edit;
        delete txt_req.id
        delete txt_req.username
        request_data = { url: `${this.apiService.api.ins}/${req1.id}`, request: txt_req };
      }
      this.commonService.activityLog(action, 'User Management ➡ หน่วยงาน', JSON.stringify(request_data)).subscribe(res => { }, error => { console.log(error); });
    }
  }

  close() {
    this.bsModalRef.hide();
  } 
  
  confirm(){
    this.event.emit(true);
    this.bsModalRef.hide();
  }
  cancel(){
    this.event.emit(false);
    this.bsModalRef.hide();
  }

}
