import { Component, OnInit, OnDestroy, Input, ViewChildren } from '@angular/core';
import { ShapefileService } from 'src/app/services/dashboard/shapefile.service';
import { interval } from 'rxjs';
import { Subscription } from 'rxjs';
import * as _ from 'lodash';
import { timeInterval, region_id, region_code } from '../../schematic-map/setting';
import { CommonService } from 'src/app/services/common.service';
import { ActionEnum } from 'src/app/models/common-enum';
@Component({
  selector: 'app-northeast-new',
  templateUrl: './northeast-new.component.html',
  styleUrls: ['./northeast-new.component.scss']
})
export class NortheastNewComponent implements OnInit, OnDestroy {

  img_url: any = "http://159.65.9.27:9091/uploads/schem/northeast_black.png";
  url: any;
  polyList: any = [];
  shapefile: any;
  subscription: any;
  sub_toggle: Subscription;
  is_toggle: boolean = false;
  is_first_load: boolean;
  region_code: string = region_code.northeast;;
  region_id: number = region_id.northeast;
  tab = 1;

  constructor(private shapefileService: ShapefileService,
    private commonService: CommonService) {
    this.url = _.cloneDeep(this.img_url);
    this.commonService.activityLog(ActionEnum.Get, 'Dashboard ➡ Schematic Map ภาคอีสาน').subscribe(res => { }, error => { console.log(error); });

  }

  async ngOnInit() {
    this.subscription = interval(timeInterval).subscribe((x) => {
      this.url = this.img_url + '?t=' + new Date().getTime();
    });
    await this.getShapefile();
  }
  changeTab(id) {
    this.tab = id;
  }

  toggleBox() {
    this.is_toggle = !this.is_toggle;
  }
  async getShapefile() {
    this.is_first_load = true;
    const shape = await this.shapefileService.getShapefileNE().toPromise();
    this.polyList = shape;

    setTimeout(() => this.is_first_load = false, 1000);
  }

  poly_top: any;
  poly_left: any;
  poly_is_show: any = false;
  poly_text: any = '';
  poly_points: any[] = [];
  pad: any = "0000";
  nname: any = '';
  DIR_IN: string = 'ขาเข้า';
  DIR_OUT: string = 'ขาออก';

  polymouseEnter(e: any, m: any, x: any, y: any) {
    let str_route = "" + m.route;
    let route = this.pad.substring(0, this.pad.length - str_route.length) + str_route;
    let dir = '';
    if (m.dir == 'in') {
      dir = this.DIR_IN;
    } else {
      dir = this.DIR_OUT;
    }
    let text = `${route} (${m.km} + 000) ${dir}`;
    this.poly_is_show = true;
    this.poly_text = text;
    this.poly_top = e.clientY - 30;
    this.poly_left = e.clientX + 5;
    this.nname = m.name_dir;

  }
  polymouseLeave() {
    this.poly_is_show = false;
    this.poly_top = null;
    this.poly_left = null;
    this.nname = '';
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  p_size: any = 100;
  d: any = 1.45;
  setImSize: any = {
    'height': 'calc(100vh - ' + this.p_size + 'px)',
    'width': 'calc((100vh - ' + this.p_size + 'px)' + ' * ' + this.d + ')',
    'display': 'table',
    'margin': 'auto',
    'text-align': 'center'
  };
  setSizeStyles(type: number) {
    const p = 100;
    if (type == 1) {
      this.p_size = this.p_size - 150;
    } else if (type == 2) {
      this.p_size = this.p_size + 150;
      if (this.p_size > p) {
        this.p_size = p;
      }
    } else {
      this.p_size = p;
    }

    let styles = {
      'height': 'calc(100vh - ' + this.p_size + 'px)',
      'width': 'calc((100vh - ' + this.p_size + 'px)' + ' * ' + this.d + ')',
      'display': 'table',
      'margin': 'auto',
      'text-align': 'center'
    };
    this.setImSize = styles;
  }

  setSizeStylesPlus() {
    this.p_size = this.p_size - 150;
    let styles = {
      'height': 'calc(100vh - ' + this.p_size + 'px)',
      'width': 'calc((100vh - ' + this.p_size + 'px)' + ' * ' + this.d + ')',
      'display': 'table',
      'margin': 'auto',
      'text-align': 'center'
    };
    this.setImSize = styles;
  }
  setSizeStylesMinus() {
    this.p_size = this.p_size + 150;
    let styles = {
      'height': 'calc(100vh - ' + this.p_size + 'px)',
      'width': 'calc((100vh - ' + this.p_size + 'px)' + ' * ' + this.d + ')',
      'display': 'table',
      'margin': 'auto',
      'text-align': 'center'
    };
    this.setImSize = styles;
  }
  setSizeStylesDefault() {
    this.p_size = 100;
    let styles = {
      'height': 'calc(100vh - ' + this.p_size + 'px)',
      'width': 'calc((100vh - ' + this.p_size + 'px)' + ' * ' + this.d + ')',
      'display': 'table',
      'margin': 'auto',
      'text-align': 'center'
    };
    this.setImSize = styles;
  }
}