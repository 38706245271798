<div bsModal #boardsignChangeMediaModal="bs-modal" class="modal fade bd-example-modal-xl" tabindex="-1" role="dialog"
  aria-labelledby="myModalLabel" aria-hidden="true" (onHide)="onHide()" [config]="{backdrop: 'static'}"
  [ngClass]="(mediaListModal?._isShown || favListModal?._isShown || checkBoardsignChangeMediaModal?._isShown)?'modal-hide-when-second-open':''">
  <div class="modal-dialog modal-xl" role="document">
    <div class="modal-content text-light">
      <div class="modal-header bg-gray-950">
        <div class="d-flex justify-content-around align-items-center w-100">
          <div>
            <h4> <i class="icon-grid icon"></i>
              <span *ngIf="!isVsw" class="ml-2">จัดการป้ายLMS</span>
              <span *ngIf="isVsw" class="ml-2">จัดการป้ายVSW</span>
            </h4>
          </div>
        </div>

        <button type="button" class="close text-light" (click)="closeBoardsignChangeMediaModal()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body modal-body-h m-0 p-0 text-white">
        <div *ngIf="is_loading" class="d-flex align-items-center justify-content-center media-list-panel mt-3">
          <div class="lds-dual-ring"></div>
        </div>
        <div *ngIf="!is_loading && !isVsw">
          <div *ngFor="let b of control_filter_list; let i = index">
            <div style="height: 40px;width:100%" class="bg-gray-900 d-flex align-items-center"><span
                class="ml-3">{{(i+1)}}. {{b?.name}}</span></div>
            <div class="row row1 no-gutters">
              <div class="col border border-top-0 border-left-0 border-right-0 border-dark">
                <div class="m-1"><span class="badge badge-success">ปัจจุบัน</span></div>
                <div class="d-flex justify-content-around">
                  <div class="mb-1">
                    <div class="sign-dir-text mb-1 text-light d-flex justify-content-center"><span
                        class="small">ป้ายหน้า</span></div>
                    <div *ngFor="let l of b?.layers;index as ii;">
                      <div class="d-flex justify-content-center flex-wrap">
                        <div *ngFor="let sign of l;index as iii;">
                          <div class="sign-box" [style.width.px]="get_sign_width(sign?.ratio,set_sign_height_small)"
                            [style.height.px]="set_sign_height_small" *ngIf="sign?.dir == signDir.Front">
                            <!-- <img [src]="sign?.media?.media?.path" height="{{set_sign_height_small}}"
                              width="{{get_sign_width(sign?.ratio,set_sign_height_small)}}"
                              *ngIf="sign?.media?.media?.path && (sign?.media?.media_type == mediaType.Image || sign?.media?.media_type == mediaType.Gif)" /> -->
                            <app-sign-image-item [media]="sign?.media?.media" [sign_height]="set_sign_height_small"
                              [is_board]="true" [config_road]="b?.config_road" [rain]="b?.rain" [ratio]="sign?.ratio"
                              [resize]="(set_sign_height_small/sign?.sign_type_object?.height)"
                              *ngIf="sign?.media?.media?.path && (sign?.media?.media_type == mediaType.Image || sign?.media?.media_type == mediaType.Gif)">
                            </app-sign-image-item>
                            <app-playlist-item [show_count]="true" [is_board]="true" [config_road]="b?.config_road"
                              [rain]="b?.rain" [resize]="(set_sign_height_small/sign?.sign_type_object?.height)"
                              *ngIf="sign?.media?.items && sign?.media?.media_type == mediaType.Playlist"
                              [playlist]="sign?.media?.items" [height]="set_sign_height_small"
                              [width]="get_sign_width(sign?.ratio,set_sign_height_small)">
                            </app-playlist-item>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="mb-1">
                    <div class="sign-dir-text mb-1 text-light d-flex justify-content-center"><span
                        class="small">ป้ายหลัง</span></div>
                    <div *ngFor="let l of b?.layers;index as ii;" [style.height.px]="set_sign_height_small+4">
                      <div class="d-flex justify-content-center flex-wrap">
                        <div *ngFor="let sign of l;index as iii;">
                          <div class="sign-box" [style.width.px]="get_sign_width(sign?.ratio,set_sign_height_small)"
                            [style.height.px]="set_sign_height_small" *ngIf="sign?.dir == signDir.Back">
                            <!-- <img [src]="sign?.media?.media?.path" height="{{set_sign_height_small}}"
                              width="{{get_sign_width(sign?.ratio,set_sign_height_small)}}"
                              *ngIf="sign?.media?.media?.path && (sign?.media?.media_type == mediaType.Image || sign?.media?.media_type == mediaType.Gif)" /> -->
                            <app-sign-image-item [media]="sign?.media?.media" [sign_height]="set_sign_height_small"
                              [is_board]="true" [config_road]="b?.config_road" [rain]="b?.rain" [ratio]="sign?.ratio"
                              [resize]="(set_sign_height_small/sign?.sign_type_object?.height)"
                              *ngIf="sign?.media?.media?.path && (sign?.media?.media_type == mediaType.Image || sign?.media?.media_type == mediaType.Gif)">
                            </app-sign-image-item>
                            <app-playlist-item [show_count]="true" [is_board]="true" [config_road]="b?.config_road"
                              [rain]="b?.rain" [resize]="(set_sign_height_small/sign?.sign_type_object?.height)"
                              *ngIf="sign?.media?.items && sign?.media?.media_type == mediaType.Playlist"
                              [playlist]="sign?.media?.items" [height]="set_sign_height_small"
                              [width]="get_sign_width(sign?.ratio,set_sign_height_small)">
                            </app-playlist-item>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col border border-top-0 border-dark">
                <div class="m-1"><span class="badge badge-warning">แนะนำ</span></div>
                <div class="d-flex justify-content-around">
                  <div class="mb-1">
                    <div class="sign-dir-text mb-1 text-light d-flex justify-content-center"><span
                        class="small">ป้ายหน้า</span></div>
                    <div *ngFor="let l of b?.layers_suggest;index as ii;">
                      <div class="d-flex justify-content-center flex-wrap">
                        <div *ngFor="let sign of l;index as iii;">
                          <div class="sign-box" [style.width.px]="get_sign_width(sign?.ratio,set_sign_height_small)"
                            [style.height.px]="set_sign_height_small" *ngIf="sign?.dir == signDir.Front">
                            <!-- <img [src]="sign?.media?.media?.path" height="{{set_sign_height_small}}"
                              width="{{get_sign_width(sign?.ratio,set_sign_height_small)}}"
                              *ngIf="sign?.media?.media?.path && (sign?.media?.media_type == mediaType.Image || sign?.media?.media_type == mediaType.Gif)" /> -->
                            <app-sign-image-item [media]="sign?.media?.media" [sign_height]="set_sign_height_small"
                              [is_board]="true" [config_road]="b?.config_road" [rain]="b?.rain" [ratio]="sign?.ratio"
                              [resize]="(set_sign_height_small/sign?.sign_type_object?.height)"
                              *ngIf="sign?.media?.media?.path && (sign?.media?.media_type == mediaType.Image || sign?.media?.media_type == mediaType.Gif)">
                            </app-sign-image-item>
                            <app-playlist-item [show_count]="true" [is_board]="true" [config_road]="b?.config_road"
                              [rain]="b?.rain" [resize]="(set_sign_height_small/sign?.sign_type_object?.height)"
                              *ngIf="sign?.media?.items && sign?.media?.media_type == mediaType.Playlist"
                              [playlist]="sign?.media?.items" [height]="set_sign_height_small"
                              [width]="get_sign_width(sign?.ratio,set_sign_height_small)">
                            </app-playlist-item>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="mb-1">
                    <div class="sign-dir-text mb-1 text-light d-flex justify-content-center"><span
                        class="small">ป้ายหลัง</span></div>
                    <div *ngFor="let l of b?.layers_suggest;index as ii;" [style.height.px]="set_sign_height_small+4">
                      <div class="d-flex justify-content-center flex-wrap">
                        <div *ngFor="let sign of l;index as iii;">
                          <div class="sign-box" [style.width.px]="get_sign_width(sign?.ratio,set_sign_height_small)"
                            [style.height.px]="set_sign_height_small" *ngIf="sign?.dir == signDir.Back">
                            <!-- <img [src]="sign?.media?.media?.path" height="{{set_sign_height_small}}"
                              width="{{get_sign_width(sign?.ratio,set_sign_height_small)}}"
                              *ngIf="sign?.media?.media?.path && (sign?.media?.media_type == mediaType.Image || sign?.media?.media_type == mediaType.Gif)" /> -->
                            <app-sign-image-item [media]="sign?.media?.media" [sign_height]="set_sign_height_small"
                              [is_board]="true" [config_road]="b?.config_road" [rain]="b?.rain" [ratio]="sign?.ratio"
                              [resize]="(set_sign_height_small/sign?.sign_type_object?.height)"
                              *ngIf="sign?.media?.media?.path && (sign?.media?.media_type == mediaType.Image || sign?.media?.media_type == mediaType.Gif)">
                            </app-sign-image-item>
                            <app-playlist-item [show_count]="true" [is_board]="true" [config_road]="b?.config_road"
                              [rain]="b?.rain" [resize]="(set_sign_height_small/sign?.sign_type_object?.height)"
                              *ngIf="sign?.media?.items && sign?.media?.media_type == mediaType.Playlist"
                              [playlist]="sign?.media?.items" [height]="set_sign_height_small"
                              [width]="get_sign_width(sign?.ratio,set_sign_height_small)">
                            </app-playlist-item>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col border border-top-0 border-left-0 border-right-0 border-dark">
                <div class="m-1"><span class="badge badge-danger">ใหม่</span></div>
                <div class="d-flex justify-content-around">
                  <div class="mb-1">
                    <div class="sign-dir-text mb-1 text-light d-flex justify-content-center"><span
                        class="small">ป้ายหน้า</span></div>
                    <div *ngFor="let l of b?.layers_new;index as ii;">
                      <div class="d-flex justify-content-center flex-wrap">
                        <div *ngFor="let sign of l;index as iii;">
                          <div class="sign-box pointer"
                            [style.width.px]="get_sign_width(sign?.ratio,set_sign_height_small)"
                            [style.height.px]="set_sign_height_small" *ngIf="sign?.dir == signDir.Front"
                            (click)="clickSign(sign,b)">
                            <!-- <img [src]="sign?.media?.media?.path" height="{{set_sign_height_small}}"
                              width="{{get_sign_width(sign?.ratio,set_sign_height_small)}}"
                              *ngIf="sign?.media?.media?.path && (sign?.media?.media_type == mediaType.Image || sign?.media?.media_type == mediaType.Gif)" /> -->
                            <app-sign-image-item [media]="sign?.media?.media" [sign_height]="set_sign_height_small"
                              [is_board]="true" [config_road]="b?.config_road" [rain]="b?.rain" [ratio]="sign?.ratio"
                              [resize]="(set_sign_height_small/sign?.sign_type_object?.height)"
                              *ngIf="sign?.media?.media?.path && (sign?.media?.media_type == mediaType.Image || sign?.media?.media_type == mediaType.Gif)">
                            </app-sign-image-item>
                            <app-playlist-item [show_count]="true" [is_board]="true" [config_road]="b?.config_road"
                              [rain]="b?.rain" [resize]="(set_sign_height_small/sign?.sign_type_object?.height)"
                              *ngIf="sign?.media?.items && sign?.media?.media_type == mediaType.Playlist"
                              [playlist]="sign?.media?.items" [height]="set_sign_height_small"
                              [width]="get_sign_width(sign?.ratio,set_sign_height_small)">
                            </app-playlist-item>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="mb-1">
                    <div class="sign-dir-text mb-1 text-light d-flex justify-content-center"><span
                        class="small">ป้ายหลัง</span></div>
                    <div *ngFor="let l of b?.layers_new;index as ii;" [style.height.px]="set_sign_height_small+4">
                      <div class="d-flex justify-content-center flex-wrap">
                        <div *ngFor="let sign of l;index as iii;">
                          <div class="sign-box pointer"
                            [style.width.px]="get_sign_width(sign?.ratio,set_sign_height_small)"
                            [style.height.px]="set_sign_height_small" *ngIf="sign?.dir == signDir.Back"
                            (click)="clickSign(sign,b)">
                            <!-- <img [src]="sign?.media?.media?.path" height="{{set_sign_height_small}}"
                              width="{{get_sign_width(sign?.ratio,set_sign_height_small)}}"
                              *ngIf="sign?.media?.media?.path && (sign?.media?.media_type == mediaType.Image || sign?.media?.media_type == mediaType.Gif)" /> -->
                            <app-sign-image-item [media]="sign?.media?.media" [sign_height]="set_sign_height_small"
                              [is_board]="true" [config_road]="b?.config_road" [rain]="b?.rain" [ratio]="sign?.ratio"
                              [resize]="(set_sign_height_small/sign?.sign_type_object?.height)"
                              *ngIf="sign?.media?.media?.path && (sign?.media?.media_type == mediaType.Image || sign?.media?.media_type == mediaType.Gif)">
                            </app-sign-image-item>
                            <app-playlist-item [show_count]="true" [is_board]="true" [config_road]="b?.config_road"
                              [rain]="b?.rain" [resize]="(set_sign_height_small/sign?.sign_type_object?.height)"
                              *ngIf="sign?.media?.items && sign?.media?.media_type == mediaType.Playlist"
                              [playlist]="sign?.media?.items" [height]="set_sign_height_small"
                              [width]="get_sign_width(sign?.ratio,set_sign_height_small)">
                            </app-playlist-item>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="bg-gray-700">
                  <div style="height: 39px;width:100%" class="d-flex justify-content-end align-items-center">
                    <button title="เลือกจาก Favorite" type="button" class="btn btn-sm  btn-primary1 bg-dark-blue m-1"
                      (click)="clickFavList(b)">
                      Favorite
                    </button>
                    <button (click)="changeMediaToNow(b)" title="เลือกจากปัจจุบัน" type="button"
                      class="btn btn-sm btn-success">
                      ปัจจุบัน
                    </button>
                    <button (click)="changeMediaToSuggest(b)" title="เลือกจากการแนะนำ" type="button"
                      class="btn btn-sm btn-warning m-1">
                      แนะนำ
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="!is_loading && isVsw">
          <div>
            <div class="row row1 no-gutters">
              <div class="col-2"></div>
              <div class="col-4 border border-top-0 border-dark">
                <div class="m-1"><span class="badge badge-warning">แนะนำ</span></div>
                <div class="d-flex justify-content-around">
                  <div class="mb-1">
                    <div class="sign-dir-text mb-1 text-light d-flex justify-content-center"></div>
                    <div *ngFor="let l of control_filter_list[0]?.layers_suggest;index as ii;">
                      <div class="d-flex justify-content-center flex-wrap">
                        <div *ngFor="let sign of l;index as iii;">
                          <div class="sign-box" [style.width.px]="get_sign_width(sign?.ratio,set_sign_height_vsw)"
                            [style.height.px]="set_sign_height_vsw" *ngIf="sign?.dir == signDir.Front">
                            <app-sign-image-item [media]="sign?.media?.media" [sign_height]="set_sign_height_vsw"
                              [is_board]="true" [config_road]="control_filter_list[0]?.config_road" [rain]="control_filter_list[0]?.rain" [ratio]="sign?.ratio"
                              [resize]="(set_sign_height_vsw/sign?.sign_type_object?.height)"
                              *ngIf="sign?.media?.media?.path && (sign?.media?.media_type == mediaType.Image || sign?.media?.media_type == mediaType.Gif)">
                            </app-sign-image-item>
                            <app-playlist-item [show_count]="true" [is_board]="true" [config_road]="control_filter_list[0]?.config_road"
                              [rain]="control_filter_list[0]?.rain" [resize]="(set_sign_height_vsw/sign?.sign_type_object?.height)"
                              *ngIf="sign?.media?.items && sign?.media?.media_type == mediaType.Playlist"
                              [playlist]="sign?.media?.items" [height]="set_sign_height_vsw"
                              [width]="get_sign_width(sign?.ratio,set_sign_height_vsw)">
                            </app-playlist-item>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-4 border border-top-0 border-dark">
                <div class="m-1"><span class="badge badge-danger">ใหม่</span></div>
                <div class="d-flex justify-content-around">
                  <div class="mb-1">
                    <div class="sign-dir-text mb-1 text-light d-flex justify-content-center"></div>
                    <div *ngFor="let l of control_filter_list[0]?.layers_new;index as ii;">
                      <div class="d-flex justify-content-center align-items-center flex-wrap">
                        <div *ngFor="let sign of l;index as iii;">
                          <div class="sign-box pointer"
                            [style.width.px]="get_sign_width(sign?.ratio,set_sign_height_vsw)"
                            [style.height.px]="set_sign_height_vsw" *ngIf="sign?.dir == signDir.Front"
                            (click)="clickSign(sign,control_filter_list[0])">
                            <app-sign-image-item [media]="sign?.media?.media" [sign_height]="set_sign_height_vsw"
                              [is_board]="true" [config_road]="control_filter_list[0]?.config_road" [rain]="control_filter_list[0]?.rain" [ratio]="sign?.ratio"
                              [resize]="(set_sign_height_vsw/sign?.sign_type_object?.height)"
                              *ngIf="sign?.media?.media?.path && (sign?.media?.media_type == mediaType.Image || sign?.media?.media_type == mediaType.Gif)">
                            </app-sign-image-item>
                            <app-playlist-item [show_count]="true" [is_board]="true" [config_road]="control_filter_list[0]?.config_road"
                              [rain]="control_filter_list[0]?.rain" [resize]="(set_sign_height_vsw/sign?.sign_type_object?.height)"
                              *ngIf="sign?.media?.items && sign?.media?.media_type == mediaType.Playlist"
                              [playlist]="sign?.media?.items" [height]="set_sign_height_vsw"
                              [width]="get_sign_width(sign?.ratio,set_sign_height_vsw)">
                            </app-playlist-item>
                          </div>
                        </div>
                        <div>
                          <i class="fa fa-hand-o-left fa-2x text-danger" [ngClass]="is_blink?'hand1':'hand2'"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="bg-gray-700">
                  <div style="height: 39px;width:100%" class="d-flex justify-content-end align-items-center">
                    <button (click)="changeMediaToSuggest(control_filter_list[0],true)" title="เลือกจากการแนะนำ" type="button"
                      class="btn btn-sm btn-warning m-1">
                      แนะนำ
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row row1 no-gutters">
            <div *ngFor="let b of control_filter_list; let i = index" class="col-4">
              <div style="height: 40px;width:100%" class="bg-gray-900 d-flex align-items-center"><span
                  class="ml-3">{{(i+1)}}. {{b?.name}}</span></div>
              <div class="border border-dark">
                <div class="m-1"><span class="badge badge-success">ปัจจุบัน</span></div>
                <div class="d-flex justify-content-around">
                  <div class="mb-4">
                    <div class="sign-dir-text mb-1 text-light d-flex justify-content-center"></div>
                    <div *ngFor="let l of b?.layers;index as ii;">
                      <div class="d-flex justify-content-center flex-wrap">
                        <div *ngFor="let sign of l;index as iii;">
                          <div class="sign-box" [style.width.px]="get_sign_width(sign?.ratio,set_sign_height_vsw)"
                            [style.height.px]="set_sign_height_vsw" *ngIf="sign?.dir == signDir.Front">
                            <app-sign-image-item [media]="sign?.media?.media" [sign_height]="set_sign_height_vsw"
                              [is_board]="true" [config_road]="b?.config_road" [rain]="b?.rain" [ratio]="sign?.ratio"
                              [resize]="(set_sign_height_vsw/sign?.sign_type_object?.height)"
                              *ngIf="sign?.media?.media?.path && (sign?.media?.media_type == mediaType.Image || sign?.media?.media_type == mediaType.Gif)">
                            </app-sign-image-item>
                            <app-playlist-item [show_count]="true" [is_board]="true" [config_road]="b?.config_road"
                              [rain]="b?.rain" [resize]="(set_sign_height_vsw/sign?.sign_type_object?.height)"
                              *ngIf="sign?.media?.items && sign?.media?.media_type == mediaType.Playlist"
                              [playlist]="sign?.media?.items" [height]="set_sign_height_vsw"
                              [width]="get_sign_width(sign?.ratio,set_sign_height_vsw)">
                            </app-playlist-item>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div *ngFor="let b of sign_null_list; let i = index" class="col-4">
              <div style="height: 40px;width:100%" class="bg-gray-900 d-flex align-items-center"><span
                  class="ml-3"></span></div>
            </div>
          </div>
        </div>
        <div *ngIf="is_loading" class="d-flex justify-content-center align-items-center h-100 w-100">
          <div class="lds-dual-ring"></div>
        </div>
      </div>
      <div class="modal-footer bg-gray-950 panel-layer-footer d-flex justify-content-end align-items-center">
        <div>
          <button type="button" class="btn btn-danger mr-1 text-white" (click)="closeBoardsignChangeMediaModal()">
            ยกเลิก
          </button>
        </div>
        <div>
          <button type="button" class="btn btn-primary ml-1 text-white" (click)="changeMediaManyBoard()"
          [disabled]="isVsw && control_filter_list[0]?.layers_new[0][0]?.media == null">
            ส่งคำสั่งเปลี่ยนป้าย
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<div bsModal #checkBoardsignChangeMediaModal="bs-modal" class="modal fade bd-example-modal-xl" tabindex="-1"
  role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" (onHide)="onHide()" [config]="{backdrop: 'static'}">
  <div class="modal-dialog modal-xl w-100" role="document">
    <div class="modal-content text-light">
      <div class="modal-header bg-gray-950">
        <div class="d-flex justify-content-around align-items-center w-100">
          <div>
            <h4> <i class="icon-grid icon"></i> <span class="ml-2">ตรวจสอบการเปลี่ยนป้าย</span>
            </h4>
          </div>
        </div>

        <button type="button" class="close text-light" (click)="closeCheckBoardsignChangeMediaModal()"
          aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body modal-body-h m-0 p-0 text-white">
        <div *ngIf="!is_loading">
          <div *ngFor="let b of check_board_list; let i = index">
            <div style="height: 40px;width:100%" class="bg-gray-900 d-flex align-items-center"><span
                class="ml-3">{{(i+1)}}. {{b?.name}}</span></div>
            <div class="row row1 no-gutters">
              <div class="col border border-top-0 border-left-0 border-right-1 border-dark">
                <div class="d-flex justify-content-around pt-2">
                  <div class="mb-1">
                    <div class="sign-dir-text mb-1 text-light d-flex justify-content-center">
                      <span *ngIf="!isVsw" class="small">ป้ายหน้า</span></div>
                    <div *ngFor="let l of b?.layers;index as ii;">
                      <div class="d-flex justify-content-center flex-wrap">
                        <div *ngFor="let sign of l;index as iii;">
                          <div class="sign-box" [style.width.px]="get_sign_width(sign?.ratio,set_sign_height)"
                            [style.height.px]="set_sign_height" *ngIf="sign?.dir == signDir.Front">
                            <app-sign-image-item [media]="sign?.media?.media" [sign_height]="set_sign_height"
                              [is_board]="true" [config_road]="b?.config_road" [rain]="b?.rain" [ratio]="sign?.ratio"
                              [resize]="(set_sign_height/sign?.sign_type_object?.height)"
                              *ngIf="sign?.media?.media?.path && (sign?.media?.media_type == mediaType.Image || sign?.media?.media_type == mediaType.Gif)">
                            </app-sign-image-item>
                            <app-playlist-item [show_count]="true" [is_board]="true" [config_road]="b?.config_road"
                              [rain]="b?.rain" [resize]="(set_sign_height/sign?.sign_type_object?.height)"
                              *ngIf="sign?.media?.items && sign?.media?.media_type == mediaType.Playlist"
                              [playlist]="sign?.media?.items" [height]="set_sign_height"
                              [width]="get_sign_width(sign?.ratio,set_sign_height)">
                            </app-playlist-item>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="!isVsw" class="mb-1">
                    <div class="sign-dir-text mb-1 text-light d-flex justify-content-center"><span
                        class="small">ป้ายหลัง</span></div>
                    <div *ngFor="let l of b?.layers;index as ii;" [style.height.px]="set_sign_height+4">
                      <div class="d-flex justify-content-center flex-wrap">
                        <div *ngFor="let sign of l;index as iii;">
                          <div class="sign-box" [style.width.px]="get_sign_width(sign?.ratio,set_sign_height)"
                            [style.height.px]="set_sign_height" *ngIf="sign?.dir == signDir.Back">
                            <app-sign-image-item [media]="sign?.media?.media" [sign_height]="set_sign_height"
                              [is_board]="true" [config_road]="b?.config_road" [rain]="b?.rain" [ratio]="sign?.ratio"
                              [resize]="(set_sign_height/sign?.sign_type_object?.height)"
                              *ngIf="sign?.media?.media?.path && (sign?.media?.media_type == mediaType.Image || sign?.media?.media_type == mediaType.Gif)">
                            </app-sign-image-item>
                            <app-playlist-item [show_count]="true" [is_board]="true" [config_road]="b?.config_road"
                              [rain]="b?.rain" [resize]="(set_sign_height/sign?.sign_type_object?.height)"
                              *ngIf="sign?.media?.items && sign?.media?.media_type == mediaType.Playlist"
                              [playlist]="sign?.media?.items" [height]="set_sign_height"
                              [width]="get_sign_width(sign?.ratio,set_sign_height)">
                            </app-playlist-item>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col border border-top-0 border-left-1 border-right-0 border-dark">
                <div *ngIf="b?.camera?.length == 0" class="text-center d-flex justify-content-center align-items-center w-100 h-100">
                  <span class="bg-primary text-white rounded pl-2 pr-2 pt-1 pb-1">ไม่พบข้อมูล</span>
                </div>
                <div *ngIf="b?.camera?.length > 0">
                  <div class="pt-2 text-center" *ngFor="let c of b?.camera; let ic = index">
                    <span class="bg-primary text-white rounded pl-2 pr-2 pt-1 pb-1">{{c?.name}}</span>
                    <div class="pt-2 pb-1">
                      <img *ngIf="c?.url" [src]="c?.url" width="100%" style="max-height: 400px;" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="is_loading" class="d-flex justify-content-center align-items-center h-100 w-100 mt-3">
          <div class="lds-dual-ring"></div>
        </div>
      </div>
      <div class="modal-footer bg-gray-950 panel-layer-footer d-flex justify-content-end align-items-center">
        <div>
          <button type="button" class="btn btn-danger text-white" (click)="closeCheckBoardsignChangeMediaModal()">
            ปิด
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<div bsModal #mediaListModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
  aria-hidden="true" [config]="{backdrop: 'static'}">
  <div class="modal-dialog  modal-xl" role="document">
    <div class="modal-content">
      <div *ngIf="sign_select != null">
        <app-media-selector-all [sign_height]="100" [sign]="sign_select" [board]="boardSelect" [config_road]="boardSelect?.config_road"
          [rain]="boardSelect?.rain" [is_board]="true" (onComponentClosed)="closeMediaModalChangeMediaNew($event)">
        </app-media-selector-all>
      </div>
    </div>
  </div>
</div>
<div bsModal #favListModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
  aria-hidden="true" [config]="{backdrop: 'static'}">
  <div class="modal-dialog  modal-xl" role="document">
    <div class="modal-content">
      <div *ngIf="boardSelect != null && this.favListModal.isShown">
        <app-favorite-selector [is_show]="this.favListModal.isShown" [boardsign_id]="this.boardSelect?.id"
          [structure]="boardSelect?.station?.boardsign_structure"
          (onComponentClosed)="closeFavModalChangeFavNew($event)">
        </app-favorite-selector>
      </div>
    </div>
  </div>
</div>
<swal #swalSuccess title="<div class='text-light'>สำเร็จ</div>" type="success" text="" icon="success"></swal>
<swal #swalError title="<div class='text-light'>ไม่สำเร็จ</div>" html="<div class='text-light'>{{res_message}}</div>"
  type="error" icon="error"></swal>
