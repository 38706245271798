<div class="animated fadeIn m-4">
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body bg-gray-900">
          <div class="d-flex justify-content-center align-items-center flex-wrap">
            <div class="d-flex align-items-center ml-2 flex-wrap">
              <div class="mr-2 text-right" style="width: 80px;">
                สาย :
              </div>
              <kendo-combobox style="width: 120px;" [data]="data_route" [allowCustom]="allowCustom" [suggest]="true"
                [textField]="'route'" [valueField]="'route'" [value]="route" (filterChange)="handleFilterRoute($event)"
                [filterable]="true" (valueChange)="handleValueRoute($event)" [placeholder]="'โปรดระบุ'">
              </kendo-combobox>
              <div class="mr-2 text-right" style="width: 80px;">
                เส้นทาง :
              </div>
              <kendo-combobox style="width: 300px;" [data]="data_path" [allowCustom]="allowCustom" [suggest]="true"
                [textField]="'name'" [valueField]="'code'" [value]="path" (filterChange)="handleFilterPath($event)"
                [filterable]="true" (valueChange)="handleValuePath($event)" [placeholder]="'โปรดระบุ'">
              </kendo-combobox>

              <div class="ml-2 text-right">
                <button [disabled]="!(route && path)" type="button" class="btn btn-primary" style="min-width: 80px;"
                  (click)="search()">ค้นหา</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 col-lg-12 col-xl-12">
      <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <div class="card text-white bg-gray-900">
            <div class="card-body">
              <kendo-chart>
                <kendo-chart-area background="none" [margin]="0" [height]="500">
                </kendo-chart-area>
                <kendo-chart-title text="Travel time"></kendo-chart-title>
                <kendo-chart-category-axis>
                  <kendo-chart-category-axis-item [categories]="data?.categories" [title]="{ text: '' }"
                    [plotBands]="categoryPlotBands">
                  </kendo-chart-category-axis-item>
                </kendo-chart-category-axis>
                <kendo-chart-series>
                  <kendo-chart-series-item name="Max" type="line" color="#ff6358" [style]="style" [data]="data.max"
                    [dashType]="dashType">
                    <kendo-chart-tooltip color="#000" format="{0:N0} นาที"></kendo-chart-tooltip>
                  </kendo-chart-series-item>
                  <kendo-chart-series-item name="Average" type="line" color="#78d237" [style]="style" [data]="data.avg"
                    [dashType]="dashType">
                    <kendo-chart-tooltip color="#000" format="{0:N0} นาที"></kendo-chart-tooltip>
                  </kendo-chart-series-item>
                  <kendo-chart-series-item name="Min" type="line" color="#ffd246" [style]="style" [data]="data.min"
                    [dashType]="dashType">
                    <kendo-chart-tooltip color="#000" format="{0:N0} นาที"></kendo-chart-tooltip>
                  </kendo-chart-series-item>
                  <kendo-chart-series-item name="Current" type="line" [style]="style" color="#28b4c8"
                    [data]="data.current">
                    <kendo-chart-tooltip color="#000" format="{0:N0} นาที"></kendo-chart-tooltip>
                  </kendo-chart-series-item>
                  <kendo-chart-series-item name="Predict" type="line" [style]="style" color="#f38ff3"
                    [data]="data.current_predict">
                    <kendo-chart-tooltip color="#000" format="{0:N0} นาที"></kendo-chart-tooltip>
                  </kendo-chart-series-item>
                </kendo-chart-series>
                <kendo-chart-legend [position]="'bottom'" [visible]="true"></kendo-chart-legend>
                <kendo-chart-tooltip [visible]="true"></kendo-chart-tooltip>
              </kendo-chart>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>