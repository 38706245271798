<div bsModal #infoModal="bs-modal" class="modal fade bd-example-modal-xl" tabindex="-1" role="dialog"
  aria-labelledby="myModalLabel" aria-hidden="true" (onHide)="onHide()" [config]="{backdrop: 'static'}"
  [ngClass]="(addModal?._isShown || deleteModal?._isShown || isEditModalOpen || boardsignBrightnessModal?._isShown)?'modal-hide-when-second-open':''">
  <div class="modal-dialog modal-xl" role="document">
    <div class="modal-content text-light">
      <div class="modal-header bg-gray-900 d-flex justify-content-between align-items-center">
        <h4 class="modal-title"> <i class="fa fa-cog mr-2"></i><span>รายละเอียดสถานีป้าย
          </span>
          <span class="ml-2">
            <strong>ID</strong> : {{boardInfo?.id}}
          </span>
        </h4>
        <button type="button" class="close text-light" (click)="clickCloseManageModal()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body modal-body-h m-0 p-0 text-white">
        <div class="m-3 d-flex justify-content-between align-items-center">
          <div>
            <div class="mt-1">
              <strong>Station</strong> : {{boardInfo?.station?.station_name}}
            </div>
            <div class="mt-1">
              <strong>Name</strong> : {{boardInfo?.name}}
            </div>
          </div>
          <button (click)="openEditBoardsignModal()" type="button" class="btn btn-warning text-light b-0 ml-1">
            <i class="cui-note icons"></i> แก้ไข
          </button>
        </div>
        <div class="bg-gray-900 panel-text-header d-flex justify-content-between align-items-center pt-2 pb-2">
          <div class="ml-3">
            <i class="fa fa-lightbulb-o"></i> ตั้งค่าความสว่างป้ายแสดงผล
          </div>
          <div class="mr-3">
            <button type="button" class="btn btn-primary pull-right text-white"
              (click)="openBoardsignBrightnessModal()">
              <i class="fa fa-lightbulb-o"></i>
              ตั้งค่าความสว่าง
            </button>
          </div>
        </div>
        <div *ngIf="brightness_list?.length>0" class="m-3">
          <div class="d-flex justify-content-around align-items-center bg-gray-900">
            <div class="m-1 d-flex justify-content-center min-w-">เวลาเริ่มต้น</div>
            <div class="m-1 d-flex justify-content-center min-w-">เวลาสิ้นสุด</div>
            <div class="m-1 d-flex justify-content-center min-w-">% ความสว่าง</div>
          </div>
          <div *ngFor="let bright of brightness_list;index as ii;">
            <div class="d-flex justify-content-around align-items-center">
              <div class="m-1 d-flex justify-content-center min-w-">{{bright?.time_st}}</div>
              <div class="m-1 d-flex justify-content-center min-w-">{{bright?.time_en}}</div>
              <div class="m-1 d-flex justify-content-center min-w-">{{bright?.percent}}%</div>
            </div>
          </div>
        </div>
        <div *ngIf="brightness_list?.length==0" class="m-3 d-flex justify-content-center align-items-center">
          ยังไม่ได้ตั้งค่าความสว่างของป้ายแสดงผล
        </div>
        <div class="bg-gray-900 panel-text-header d-flex justify-content-between align-items-center pt-2 pb-2">
          <div class="ml-3">
            <i class="fa fa-info-circle"></i> รายละเอียดป้ายแสดงภาพ
          </div>
          <div class="mr-3">
            <button *ngIf="isAddSign" type="button" class="btn btn-primary pull-right text-white"
              (click)="clickSignItem(null,1)">
              <i class="fa fa-plus"></i>
              เพิ่มป้ายแสดงภาพ
            </button>
          </div>
        </div>
        <div class="m-3">
          <div *ngFor="let s of signs;index as ii;">
            <div class="d-flex justify-content-start align-items-center flex-wrap">
              <div class="sign-box-in-info"
                [style.width.px]="get_sign_width(s?.sign_type_object?.aspect_ratio,set_sign_height)"
                [style.height.px]="set_sign_height">
                <img [src]="s?.media?.media?.path" height="{{set_sign_height}}"
                  width="{{get_sign_width(s?.sign_type_object?.aspect_ratio,set_sign_height)}}"
                  *ngIf="s?.media?.media?.path && (s?.media?.media_type == mediaType.Image || s?.media?.media_type == mediaType.Gif)" />
                <app-playlist-item class="pointer" [show_count]="true"
                  *ngIf="s?.media?.items && s?.media?.media_type == mediaType.Playlist" [playlist]="s?.media?.items"
                  [height]="set_sign_height"
                  [width]="get_sign_width(s?.sign_type_object?.aspect_ratio,set_sign_height)">
                </app-playlist-item>
              </div>
              <div class="text-light">
                <div>
                  Sign ID:{{s?.id}}
                  <i tooltip="Active" placement="top" *ngIf="s?.status == statusEnum.Active"
                    class="fa fa-circle text-success"></i>
                  <i tooltip="Inctive" placement="top" *ngIf="s?.status == statusEnum.Inactive"
                    class="fa fa-circle text-danger"></i>
                  <i tooltip="MA" placement="top" *ngIf="s?.status == statusEnum.Ma"
                    class="fa fa-circle text-warning"></i>
                </div>
                <div>
                  {{s?.sign_type_object?.name}} ({{s?.sign_type_object?.width}}x{{s?.sign_type_object?.height}})
                </div>
                <div>
                  X:{{s?.position_x}} Y:{{s?.position_y}}
                </div>
                <div>
                  Cover Lane : {{s?.cover_lane | coverLaneText}}
                </div>
                <div>
                  Direction : {{s?.direction | signDirToText}}
                  <!-- <i title="เปลี่ยนป้าย" (click)="clickSign(s,this.boardItem)" class="fa fa-picture-o ml-2 mr-1 pointer"></i>  -->
                  <i *ngIf="isEditSign" class="fa fa-pencil-square-o ml-2 mr-1 pointer text-warning" title="แก้ไข"
                    (click)="clickSignItem(s,2)"></i>
                  <i *ngIf="isEditSign" class="fa fa-trash-o ml-1 mr-1 pointer  text-danger" title="ลบป้าย"
                    (click)="clickSignItem(s,3)"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div bsModal #addModal="bs-modal" class="modal fade modal-md" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
  aria-hidden="true" (onHide)="clearData()" [config]="{backdrop: 'static'}">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header bg-dark bg-gray-900">
        <h4 class="modal-title"> <i class="icon-info mr-2"></i><span *ngIf="!signItem?.id">เพิ่มป้ายแสดงผล</span><span
            *ngIf="signItem?.id">แก้ไขป้ายแสดงผล</span></h4>
        <button type="button" class="close text-light" (click)="addModal.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body text-white">
        <form class="form-horizontal mt-2 mb-2">
          <div class="form-group row">
            <label class="col-md-4 col-form-label" for="text-input"> <span
                class="float-sm-left float-md-right">ชนิดป้าย<span class="text-danger">*</span> :</span></label>
            <div class="col-md-8">
              <kendo-combobox class="w-100" [data]="data_sign_type_modal" [allowCustom]="allowCustom"
                [textField]="'name'" [valueField]="'id'" [value]="sign_type_modal"
                (valueChange)="handleValueSignTypeModal($event)" (filterChange)="handleFilterSignTypeModal($event)"
                [placeholder]="'โปรดระบุ'" [filterable]="true">
              </kendo-combobox>
            </div>
          </div>
          <div *ngIf="!isVsw" class="form-group row">
            <label class="col-md-4 col-form-label" for="select1"> <span class="float-sm-left float-md-right">ทิศทาง<span
                  class="text-danger">*</span>
                :</span></label>
            <div class="col-md-8">
              <kendo-combobox class="w-100" [data]="dir_list" [allowCustom]="allowCustom" [textField]="'name'"
                [valueField]="'id'" [value]="dir_modal" (valueChange)="handleValueDirModal($event)"
                [placeholder]="'โปรดระบุ'" [filterable]="true">
              </kendo-combobox>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-4 col-form-label" for="select1"> <span class="float-sm-left float-md-right">X
                Position<span class="text-danger">*</span>
                :</span></label>
            <div class="col-md-8">
              <input type="text" [(ngModel)]="signItem.position_x" name="sign_p_x" class="form-control" placeholder=""
                maxlength="10">
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-4 col-form-label" for="select1"> <span class="float-sm-left float-md-right">Y
                Position<span class="text-danger">*</span>
                :</span></label>
            <div class="col-md-8">
              <input type="text" [(ngModel)]="signItem.position_y" name="sign_p_y" class="form-control" placeholder=""
                maxlength="10">
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-4 col-form-label" for="select1"> <span class="float-sm-left float-md-right">Cover
                Lane :</span></label>
            <div class="col-md-8">
              <div class="d-flex align-items-center justify-content-start">
                <div *ngFor="let item of sign_cover_lane_list;index as i;">
                  <div class="custom-control custom-checkbox ml-2">
                    <input type="checkbox" [(ngModel)]="item.check" class="custom-control-input pointer"
                      id="ccustomCheck{{item.id}}" [ngModelOptions]="{standalone: true}"
                      (change)="changeLanelist($event,item)">
                    <label class="custom-control-label pointer" for="ccustomCheck{{item.id}}"><span
                        class="text-info">{{item.id}}</span> </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-4 col-form-label" for="select1"> <span class="float-sm-left float-md-right">สถานะ<span
                  class="text-danger">*</span>
                :</span></label>
            <div class="col-md-8">
              <kendo-combobox class="w-100" [data]="status_list" [allowCustom]="allowCustom" [textField]="'name'"
                [valueField]="'id'" [value]="status_modal" (valueChange)="handleValueStatusModal($event)"
                [placeholder]="'โปรดระบุ'" [filterable]="true">
              </kendo-combobox>
            </div>
          </div>
        </form>
        <div class="d-flex justify-content-end mt-2 mb-2">
          <button type="button" class="btn btn-secondary" (click)="addModal.hide()">ยกเลิก</button>
          <!-- <button type="button" class="btn btn-primary ml-2" *ngIf="actionType==1" (click)="addType()" [disabled]="!newsTypeItem?.news_type_name">สร้าง</button>
          <button type="button" class="btn btn-primary ml-2" *ngIf="actionType==2" (click)="editType()">แก้ไข</button> -->
          <button
            [disabled]="!(signItem.position_x != null && signItem.position_y != null && status_modal != null && data_sign_type_modal != null && dir_modal != null)"
            *ngIf="signItem?.id" type="button" class="btn btn-primary text-white ml-2"
            (click)="editSign()">แก้ไข</button>
          <button
            [disabled]="!(signItem.position_x != null && signItem.position_y != null && status_modal != null && data_sign_type_modal != null && dir_modal != null)"
            *ngIf="!signItem?.id" type="button" class="btn btn-primary text-white ml-2"
            (click)="addSign()">เพิ่ม</button>
        </div>
      </div>
      <!-- <div class="modal-footer">
          
        </div> -->
    </div>
  </div>
</div>
<div bsModal #deleteModal="bs-modal" class="modal fade modal-md" tabindex="-1" role="dialog"
  aria-labelledby="myModalLabel" aria-hidden="true" (onHide)="clearData()" [config]="{backdrop: 'static'}">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header bg-dark bg-gray-900">
        <h4 class="modal-title"> <i class="icon-info mr-2"></i>ลบป้าย</h4>
        <button type="button" class="close text-light" (click)="deleteModal.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="mt-2 text-center">
          <h4>คุณต้องการลบใช่หรือไม่</h4>
        </div>
        <div class="mt-3 mb-4 text-center">
          <h5>Sign ID : {{signItem?.id}}</h5>
        </div>
        <div class="d-flex justify-content-end mt-2 mb-2">
          <button type="button" class="btn btn-secondary" (click)="deleteModal.hide()">ยกเลิก</button>
          <!-- <button type="button" class="btn btn-primary ml-2" (click)="deleteType(newsTypeItem.id)">ยืนยัน</button> -->
          <button type="button" class="btn btn-primary text-white ml-2" (click)="deleteSign()">ยืนยัน</button>
        </div>
      </div>
      <!-- <div class="modal-footer">
          
        </div> -->
    </div>
  </div>
</div>

<div bsModal #boardsignBrightnessModal="bs-modal" class="modal fade modal-md" tabindex="-1" role="dialog"
  aria-labelledby="myModalLabel" aria-hidden="true" (onHide)="onHideBrightnessModal()" [config]="{backdrop: 'static'}">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header bg-dark bg-gray-900">
        <h4 class="modal-title"> <i class="fa fa-lightbulb-o mr-2"></i>ตั้งค่าความสว่างป้ายแสดงผล</h4>
        <button type="button" class="close text-light" (click)="boardsignBrightnessModal.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body text-white">
        <div>
          <div class="d-flex justify-content-around align-items-center bg-gray-900">
            <div class="m-1 d-flex justify-content-center min-w-">เวลาเริ่มต้น</div>
            <div class="m-1 d-flex justify-content-center min-w-">เวลาสิ้นสุด</div>
            <div class="m-1 d-flex justify-content-center min-w-">% ความสว่าง</div>
            <div class="m-1 d-flex justify-content-center">
              <div class="bt-rounded-circle"></div>
            </div>
          </div>
          <div *ngFor="let bright of brightness_req;index as ii;">
            <div class="d-flex justify-content-around align-items-center">
              <div class="m-1 d-flex justify-content-center min-w-">
                <kendo-timepicker class="w-100" [format]="'HH:mm'" [(value)]="bright.st"
                  [formatPlaceholder]="formatPlaceholder"></kendo-timepicker>
              </div>
              <div class="m-1 d-flex justify-content-center min-w-">
                <kendo-timepicker class="w-100" [format]="'HH:mm'" [(value)]="bright.en"
                  [formatPlaceholder]="formatPlaceholder"></kendo-timepicker>
              </div>
              <div class="m-1 d-flex justify-content-center min-w-">
                <input type="text" class="form-control text-center" numbersOnly maxlength="3"
                  [(ngModel)]="bright.percent" [ngModelOptions]="{standalone: true}"></div>
              <div class="m-1 d-flex justify-content-center">
                <div title="ลบ" (click)="deleteBrightnessItem(bright)"
                  class="bt-rounded-circle pointer rounded-circle bg-danger d-flex justify-content-center align-items-center">
                  <i class="fa fa-times"></i></div>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-center align-items-center m-2">
            <div title="เพิ่ม" (click)="addBrightnessItem()"
              class="bt-rounded-circle pointer rounded-circle bg-success d-flex justify-content-center align-items-center">
              <i class="fa fa-plus"></i></div>
          </div>
        </div>
        <div class="d-flex justify-content-end mt-4 mb-2">
          <button type="button" class="btn btn-secondary" (click)="boardsignBrightnessModal.hide()">ยกเลิก</button>
          <button [disabled]="!(brightness_req.length>0)" type="button" class="btn btn-primary text-white ml-2"
            (click)="updateBrightness()">บันทึก</button>
        </div>
      </div>
    </div>
  </div>
</div>
<swal #swalSuccess title="<div class='text-light'>สำเร็จ</div>" type="success" text="" icon="success"></swal>
<swal #swalError title="<div class='text-light'>ไม่สำเร็จ</div>" html="<div class='text-light'>{{res_message}}</div>"
  type="error" icon="error"></swal>