import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { CommonService } from '../../../services/common.service';
import { StationService } from '../../../services/assets-management/station.service';
import { MaService } from '../../../services/assets-management/ma.service';
import { Station, ReqAddStation } from '../../../models/assets-management/station';
import { StatusEnum, MonitorTypeEnum, MaStatusEnum, ConnectionStatusEnum, ActionEnum } from '../../../models/common-enum';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import * as _ from 'lodash';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Owner } from 'src/app/models/assets-management/owner';
import { Vendor } from 'src/app/models/assets-management/vendor';
import { DeviceGroup } from 'src/app/models/assets-management/device-group';
import { OwnerService } from '../../../services/assets-management/owner.service';
import { VendorService } from '../../../services/assets-management/vendor.service';
import { DeviceGroupService } from '../../../services/assets-management/device-group.service';
import { DeviceTypeService } from '../../../services/assets-management/device-type.service';
import { DeviceType } from 'src/app/models/assets-management/device-type';
import { DeviceInfo } from 'src/app/models/assets-management/device';
import { MaInfo } from 'src/app/models/assets-management/ma';
import { Img } from 'src/app/models/common';
import { DeviceService } from '../../../services/assets-management/device.service';
import { AuthService } from '../../../services/auth/auth.service';
import { UserProfile } from 'src/app/models/user';
import { Subscription } from 'rxjs';
import * as mapboxgl from 'mapbox-gl';
import { environment } from 'src/environments/environment';
import { permission } from 'src/app/views/assets-management/_menu';
import { BoardsignService } from '../../../services/sign-control/boardsign.service';
import { AddBoardsign } from 'src/app/models/sign-control/boardsign';

@Component({
  selector: 'app-ma-ticket',
  templateUrl: './ma-ticket.component.html',
  styleUrls: ['./ma-ticket.component.scss']
})
export class MaTicketComponent implements OnInit {
  @ViewChild('deleteModal', { static: false }) public deleteModal: ModalDirective;
  @ViewChild('swalSuccess', { static: false }) private swalSuccess: SwalComponent;
  @ViewChild('swalError', { static: false }) private swalError: SwalComponent;
  map: mapboxgl.Map;
  ma_images: Img[] = [];
  viewType = 1; //1:list,2:map
  statusEnum = StatusEnum;
  maStatusEnum = MaStatusEnum;
  connectionStatusEnum = ConnectionStatusEnum;
  myDate = new Date();
  currentPage: any = 1;
  itemsPerPage: any = 10;
  totalItems: any = 0;
  data_list: MaInfo[];
  monitorTypeEnum = MonitorTypeEnum;
  status_list = [
    { id: MaStatusEnum.Create, name: "Create" },
    { id: MaStatusEnum.Operate, name: "Operate" },
    { id: MaStatusEnum.Close, name: "Close" }
  ];
  search_status: any;
  monitor_type_list = [
    { id: MonitorTypeEnum.Hardware, name: "Hardware" },
    { id: MonitorTypeEnum.Software, name: "Software" },
    { id: MonitorTypeEnum.Boardsign, name: "ป้ายแสดงผล" }
  ];
  search_monitor_type: any;
  selectItem: MaInfo = {
    detail: ""
  };
  selectMaView = 1;
  public allowCustom: boolean = false;
  public clickOpenAdd: boolean = false;
  res_message: any;
  status_modal: any;
  search_km_st: any;
  search_km_en: any;
  search_m_st: any;
  search_m_en: any;
  search_station: any;
  //station_modal: Station;
  data_station_search: Station[];
  //data_station_modal: Station[];
  station_search_list: Station[];
  search_owner: any;
  data_owner: Owner[];
  owner_list: Owner[];
  search_vendor: any;
  data_vendor: Vendor[];
  vendor_list: Vendor[];
  search_device_group: any;
  data_device_group: DeviceGroup[];
  device_group_list: DeviceGroup[];
  search_device_type: any;
  data_device_type: DeviceType[];
  device_type_list: DeviceType[];


  currentUser: UserProfile;
  currentUserSubscription: Subscription;

  selectMaId: any;

  isAdd: boolean = false;
  isEdit: boolean = false;
  constructor(private commonService: CommonService, private stationService: StationService, private ownerService: OwnerService,
    private vendorService: VendorService, private deviceGroupService: DeviceGroupService, private deviceTypeService: DeviceTypeService,
    private maService: MaService, private deviceService: DeviceService, private authService: AuthService,private boardsignService:BoardsignService) {
    this.currentUserSubscription = this.authService.currentUser.subscribe(user => {
      this.currentUser = user;
    });
    mapboxgl.accessToken = environment.mapbox.accessToken;
    this.commonService.activityLog(ActionEnum.Get, `Assets Management ➡ แจ้งซ่อม`).subscribe(res => { }, error => { console.log(error); });
  }

  async ngOnInit() {
    this.getRoute();
    this.is_loading = true;
    this.getMa();
    this.getStationSearch();
    this.getOwner();
    this.getVendor();
    this.getDeviceGroup();
    this.getDeviceType();
    this.initMaMap();
    this.isAdd = await this.commonService.getPermission(permission.ma.add_id).toPromise();
    this.isEdit = await this.commonService.getPermission(permission.ma.edit_id).toPromise();
  }
  addMaSuccess() {
    this.getMa();
  }
  search() {
    if (this.viewType == 1) {
      this.currentPage = 1;
      this.getMa();
    } else {
      this.getMaMap();
      setTimeout(() => { this.updateMap(); this.map.resize(); this.is_loading = false; }, 1000);
    }
  }
  changeView(id) {
    if (id != this.viewType) {
      if (id == 1) {
        this.getMa();
      } else {
        this.getMaMap();
        setTimeout(() => { this.updateMap(); this.map.resize(); this.is_loading = false; }, 1000);

      }
    }
  }
  handleValueStatus(value) {
    if (value) {
      this.search_status = value;
    } else {
      this.search_status = null;
    }
  }

  handleValueMonitor(value) {
    if (value) {
      this.search_monitor_type = value;
    } else {
      this.search_monitor_type = null;
    }
  }
  is_loading: boolean = false;
  search_route: string;
  data_route: Array<string> = [];
  route_list: string[];
  getRoute() {
    this.commonService.getRoute().subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            if (res.data) {
              this.route_list = _.cloneDeep(res.data);
              this.data_route = _.cloneDeep(res.data);
            }
          }
        }
        this.is_loading = false;
      },
      error => {
        this.is_loading = false;
      });
  }

  handleFilterRoute(value) {
    if (value.length > 0) {
      this.data_route = this.route_list.filter((s) => s.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else if (value.length == 0) {
      this.data_route = _.cloneDeep(this.route_list);
    } else {
      this.data_route = [];
    }
  }
  handleValueRoute(value) {
    if (value) {
      this.search_route = value;
    } else {
      this.search_route = null;
    }
  }

  getStationSearch() {
    this.stationService.getStation(null, null, 1).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            if (res.data) {
              this.station_search_list = _.cloneDeep(res.data.data);
              this.data_station_search = _.cloneDeep(res.data.data);
            }
          }
        }
        this.is_loading = false;
      },
      error => {
        this.is_loading = false;
      });
  }
  handleFilterStation(value) {
    if (value.length > 0) {
      this.data_station_search = this.station_search_list.filter(s=>s.name != null).filter((s) => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else if (value.length == 0) {
      this.data_station_search = _.cloneDeep(this.station_search_list);
    } else {
      this.data_station_search = [];
    }
  }
  handleValueStation(value) {
    if (value) {
      this.search_station = value;
    } else {
      this.search_station = null;
    }
  }

  getOwner() {
    this.ownerService.getOwner(null, null, 1).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            if (res.data) {
              this.owner_list = _.cloneDeep(res.data.data);
              this.data_owner = _.cloneDeep(res.data.data);
            }
          }
        }
        this.is_loading = false;
      },
      error => {
        this.is_loading = false;
      });
  }
  handleFilterOwner(value) {
    if (value.length > 0) {
      this.data_owner = this.owner_list.filter((s) => s.company_name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else if (value.length == 0) {
      this.data_owner = _.cloneDeep(this.owner_list);
    } else {
      this.data_owner = [];
    }
  }
  handleValueOwner(value) {
    if (value) {
      this.search_owner = value;
    } else {
      this.search_owner = null;
    }
  }

  getVendor() {
    this.vendorService.getVendor(null, null, 1).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            if (res.data) {
              this.vendor_list = _.cloneDeep(res.data);
              this.data_vendor = _.cloneDeep(res.data);

            }
          }
        }
        this.is_loading = false;
      },
      error => {
        this.is_loading = false;
      });
  }
  handleFilterVendor(value) {
    if (value.length > 0) {
      this.data_vendor = this.vendor_list.filter((s) => s.company_name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else if (value.length == 0) {
      this.data_vendor = _.cloneDeep(this.vendor_list);
    } else {
      this.data_vendor = [];
    }
  }
  handleValueVendor(value) {
    if (value) {
      this.search_vendor = value;
    } else {
      this.search_vendor = null;
    }
  }

  getDeviceGroup() {
    this.deviceGroupService.getDeviceGroup(null, null, null, null, 1).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            if (res.data) {
              this.device_group_list = _.cloneDeep(res.data.data);
              this.data_device_group = _.cloneDeep(res.data.data);
            }
          }
        }
        this.is_loading = false;
      },
      error => {
        this.is_loading = false;
      });
  }
  handleFilterDeviceGroup(value) {
    if (value.length > 0) {
      this.data_device_group = this.device_group_list.filter((s) => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else if (value.length == 0) {
      this.data_device_group = _.cloneDeep(this.device_group_list);
    } else {
      this.data_device_group = [];
    }
  }
  handleValueDeviceGroup(value) {
    if (value) {
      this.search_device_group = value;
    } else {
      this.search_device_group = null;
    }
  }

  getDeviceType() {
    this.deviceTypeService.getDeviceType(null, null, null, null, 1).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            if (res.data) {
              this.device_type_list = _.cloneDeep(res.data);
              this.data_device_type = _.cloneDeep(res.data);
            }
          }
        }
        this.is_loading = false;
      },
      error => {
        this.is_loading = false;
      });
  }
  handleFilterDeviceType(value) {
    if (value.length > 0) {
      this.data_device_type = this.device_type_list.filter((s) => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else if (value.length == 0) {
      this.data_device_type = _.cloneDeep(this.device_type_list);
    } else {
      this.data_device_type = [];
    }
  }
  handleValueDeviceType(value) {
    if (value) {
      this.search_device_type = value;
    } else {
      this.search_device_type = null;
    }
  }

  async getMa() {
    this.data_list = [];
    let status = null;
    let route = null;
    let station = null;
    let owner = null;
    let vendor = null;
    let device_type = null;
    let device_group = null;
    let monitor_type = null;
    if (this.search_status)
      status = this.search_status.id;
    if (this.search_route)
      route = this.search_route;
    if (this.search_station)
      station = this.search_station.id;
    if (this.search_owner)
      owner = this.search_owner.id;
    if (this.search_vendor)
      vendor = this.search_vendor.vendor_id;
    if (this.search_device_group)
      device_group = this.search_device_group.id;
    if (this.search_device_type)
      device_type = this.search_device_type.id;
    if (this.search_monitor_type)
      monitor_type = this.search_monitor_type.id;
    this.maService.getMa(this.currentPage, this.itemsPerPage, null, route, this.search_km_st, this.search_km_en, this.search_m_st, this.search_m_en, status, station, owner, vendor, device_group, device_type, monitor_type).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            if (res.data) {
              this.data_list = res.data.data;
              this.totalItems = res.data.total_items;
              const filter_board_ma = this.data_list.filter(x=>x.type_id == MonitorTypeEnum.Boardsign);
              if(filter_board_ma.length>0){
                const ids = filter_board_ma.map(x=>x.ref_id);
                this.getBoardsign(ids);
              }
            }else{
              this.totalItems = 0;
            }
          }else{
            this.totalItems = 0;
          }
        }else{
          this.totalItems = 0;
        }
        this.is_loading = false;
      },
      error => {
        this.is_loading = false;
        this.totalItems = 0;
      });
  }
  async getBoardsign(ids?:number[]) {
    const res = await this.boardsignService.getBoardsign(null, null, null, null, null, null, null, null, null, null,ids).toPromise();
    if(res){
      if (res.code == 1) {
        const boards = res.data.data;
        if(boards.length>0){
          this.data_list.forEach(item => {
            if(item.type_id == MonitorTypeEnum.Boardsign){
              const find = boards.find(x=>x.id == item.ref_id);
              if(find){
                if(find.connection_status != null){
                  item.connection_status = find.connection_status;
                }
              }
            }
          });
        }
      }
    }
  }
  openAddModal() {
    this.selectItem = null;
    this.selectMaView = 1;
    this.clickOpenAdd = true;
  }
  closeAddModal() {
    this.clickOpenAdd = false;
    this.selectMaId = null;
    this.getMa();
  }
  openAddLogModal(item?: any) {
    this.selectMaView = 3;
    this.selectItem = item;
    this.selectMaId = this.selectItem.ma_id;
    this.clickOpenAdd = true;
  }
  openDeleteModal(item?: any) {
    this.selectItem = item;
    this.status_modal = this.status_list.find(x => x.id == item.status);
    this.deleteModal.show();
  }
  clearData() {
    this.selectItem = new Station();
  }
  handleValueStatusModal(value) {
    if (value) {
      this.status_modal = value;
    } else {
      this.status_modal = null;
    }
  }
  pageChanged(event: any): void {
    if (this.currentPage != event.page) {
      this.currentPage = event.page;
      this.getMa();
    }
  }
  deleteMa() {
    this.is_loading = true;
    this.maService.deleteMa(this.selectItem.ma_id).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            this.commonService.activityLog(ActionEnum.Delete, `Assets Management ➡ แจ้งซ่อม`, JSON.stringify({ id: this.selectItem.ma_id })).subscribe(res => { }, error => { console.log(error); });
            this.res_message = `ลบการแจ้งซ่อมสำเร็จ`;
            if (this.selectItem.type_id == MonitorTypeEnum.Boardsign) {
              this.editBoardsignStatus(this.statusEnum.Active,this.selectItem.ref_id);
            }
            setTimeout(() => {
              this.swalSuccess.fire();
              this.is_loading = false;
              this.getMa();
            }, 100);
            this.deleteModal.hide();
          } else {
            this.res_message = res.message;
            setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
          }
        } else {
          setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
        }
      },
      error => {
        console.log(error);
        if (error) {
          if (error.status == 403) {

          } else {
            const error_text = error.error.message || error.statusText;
            this.res_message = error_text;
            this.is_loading = false;
            setTimeout(() => { this.swalError.fire() }, 1000);
          }
        } else {
          setTimeout(() => { this.swalError.fire() }, 1000);
        }
        this.is_loading = false;
      });
  }
  editBoardsignStatus(status?: number, boardsign_id?: number) {
    let req = new AddBoardsign();
    req.status = status;
    this.boardsignService.editBoardsign(req, boardsign_id).subscribe(
      res => {
      },
      error => {
        console.log(error);
      });
  }
  //-------------------------------------------------------------------------------------------------//
  ma_layer: any = 'MaLayer';
  ma_src: any = 'MaSrc';
  maListFeatures: any[] = [];
  maList: any = [];
  getMaMap() {
    this.is_loading = true;
    this.maList = [];
    this.maListFeatures = [];
    this.data_list = [];
    let status = null;
    let route = null;
    let station = null;
    let owner = null;
    let vendor = null;
    let device_type = null;
    let device_group = null;
    let monitor_type = null;
    if (this.search_status)
      status = this.search_status.id;
    if (this.search_route)
      route = this.search_route;
    if (this.search_station)
      station = this.search_station.id;
    if (this.search_owner)
      owner = this.search_owner.id;
    if (this.search_vendor)
      vendor = this.search_vendor.vendor_id;
    if (this.search_device_group)
      device_group = this.search_device_group.id;
    if (this.search_device_type)
      device_type = this.search_device_type.id;
    if (this.search_monitor_type)
      monitor_type = this.search_monitor_type.id;
    this.maService.getMa(this.currentPage, this.itemsPerPage, null, route, this.search_km_st, this.search_km_en, this.search_m_st, this.search_m_en, status, station, owner, vendor, device_group, device_type, monitor_type).subscribe(
      res => {
        if (res) {
          res.data.data.forEach(item => {
            if (item.lng && item.lat) {
              const coordinates = [item.lng, item.lat];

              const geometry = { type: 'Point', coordinates: coordinates };
              const feature = { type: 'Feature', geometry: geometry, properties: item };
              this.maListFeatures.push(feature);
            }
          });
          this.maList = res.data.data;
        }
      },
      err => {
        console.log(JSON.stringify(err.statusText));
      });
  }

  initMaMap() {
    this.map = new mapboxgl.Map({
      container: 'map-ma',
      style: environment.mapbox.style,
      zoom: 7,
      center: [100.523186, 13.736717],
    });
    this.map.dragRotate.disable();
    this.map.on('load', (event: mapboxgl.MapEvent) => {
      this.map.setPaintProperty('water', 'fill-color', '#aad3df');

      this.map.loadImage('assets/img/marker/pin-red-offline.png', (error, image) => {
        if (error) { throw error };
        this.map.addImage(`pin-${MaStatusEnum.Create}-${ConnectionStatusEnum.Offline}`, image);
      });
      this.map.loadImage('assets/img/marker/pin-red-online.png', (error, image) => {
        if (error) { throw error };
        this.map.addImage(`pin-${MaStatusEnum.Create}-${ConnectionStatusEnum.Online}`, image);
      });
      this.map.loadImage('assets/img/marker/pin-green-offline.png', (error, image) => {
        if (error) { throw error };
        this.map.addImage(`pin-${MaStatusEnum.Close}-${ConnectionStatusEnum.Offline}`, image);
      });
      this.map.loadImage('assets/img/marker/pin-green-online.png', (error, image) => {
        if (error) { throw error };
        this.map.addImage(`pin-${MaStatusEnum.Close}-${ConnectionStatusEnum.Online}`, image);
      });
      this.map.loadImage('assets/img/marker/pin-yellow-offline.png', (error, image) => {
        if (error) { throw error };
        this.map.addImage(`pin-${MaStatusEnum.Operate}-${ConnectionStatusEnum.Offline}`, image);
      });
      this.map.loadImage('assets/img/marker/pin-yellow-online.png', (error, image) => {
        if (error) { throw error };
        this.map.addImage(`pin-${MaStatusEnum.Operate}-${ConnectionStatusEnum.Online}`, image);
      });

      this.map.loadImage('assets/img/marker/pin-gray.png', (error, image) => {
        if (error) { throw error };
        this.map.addImage('pin-null', image);
      });
      this.map.loadImage('assets/img/marker/pin-gray.png', (error, image) => {
        if (error) { throw error };
        this.map.addImage('pin-null-null', image);
      });
      this.map.loadImage('assets/img/marker/pin-gray.png', (error, image) => {
        if (error) { throw error };
        this.map.addImage('pin-', image);
      });
      //------------------------------------------------------------------------------------------//

      this.map.addSource(this.ma_src, {
        "type": 'geojson', "data": {
          "type": "FeatureCollection",
          "features": this.maListFeatures
        }
      });

      //icon w-40 h-40
      this.map.addLayer({
        id: this.ma_layer,
        source: this.ma_src,
        type: 'symbol',
        layout: {
          'icon-allow-overlap': true,
          'icon-image': 'pin-{status}-{connection_status}',
          "icon-size": 0.6,
          "icon-offset": [0, 0], //[x,y]
          'text-offset': [0, 0],
          'text-field': '{route}',
          'text-size': 12,
          'text-allow-overlap': true,
          'text-transform': 'uppercase',
        },
        paint: {
          "text-color": "#ffffff"
        },
        "filter": ["==", "$type", "Point"],
      });

      const popup = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false, anchor: 'top', offset: [0, -52]
      });

      // this.map.on('click', this.ma_layer, (event: MapEvent) => {
      //   this.map.flyTo({ center: event.features[0].geometry.coordinates, zoom: 15 });
      //   const p = event.features[0].properties;
      //   this.clickMapStation(p);
      // });
      this.map.on('mouseenter', this.ma_layer, (e: mapboxgl.MapEvent) => {
        this.map.getCanvas().style.cursor = 'pointer';
        const p = e.features[0].properties;
        const htmltext = `<div class="font-3" style="padding-top: 10px;padding-left: 10px;padding-right: 10px;"><h6>${p.item_name}</h6></div>`;
        popup.setLngLat(e.features[0].geometry.coordinates)
          .setHTML(htmltext)
          .addTo(this.map);
      });
      this.map.on('mouseleave', this.ma_layer, (e: mapboxgl.MapEvent) => {
        this.map.getCanvas().style.cursor = '';
        popup.remove();
      });
      this.updateMap();
    });

  }
  updateMap() {
    if (this.map.getSource(this.ma_src) != null) {
      this.map.getSource(this.ma_src).setData({
        'type': 'FeatureCollection',
        'features': this.maListFeatures
      });
    }
  }
}