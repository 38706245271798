import { Component, OnInit } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import * as moment from 'moment'
import * as _ from 'lodash'
import * as mapboxgl from 'mapbox-gl';
import { environment } from 'src/environments/environment';
import { StationService } from 'src/app/services/assets-management/station.service';
import { Station } from 'src/app/models/assets-management/station';
import { CommonService } from 'src/app/services/common.service';
import { ReversibleLaneModalComponent } from './reversible-lane-modal/reversible-lane-modal.component';
import { ReversibleService } from 'src/app/services/user/reversible.service';
import { FuncService } from 'src/app/services/user/func.service';
import { permission } from 'src/app/views/assets-management/_menu';
import { ActionEnum } from 'src/app/models/common-enum';
import { ApiService } from 'src/app/services/user/api.service';
@Component({
  selector: 'app-reversible-lane',
  templateUrl: './reversible-lane.component.html',
  styleUrls: ['./reversible-lane.component.scss']
})
export class ReversibleLaneComponent implements OnInit {
  allowCustom: boolean = false
  no_data: boolean = true
  map: mapboxgl.Map;
  modalRef: BsModalRef;
  view: number = 1
  data_sta: any;
  station_name: any;
  classList: any;
  sta: any;
  staList: any;
  values: any = []
  route_list: any;
  setRouteList: any = []
  route_id: any
  start_km: number
  start_m: number
  end_km: number
  end_m: number
  direction: any[] = []
  latlng: any = []
  ResMessage: string;
  rm_data: any
  _subMkShow: boolean = null

  // ================
  data_list : any = [];
  viewType = 1; //1:list,2:map
  is_loading :boolean = false
  search_route: string;
  search_station : any;
  data_station_modal: Station[] = [];
  data_station_search: Station[] = [];
  station_search_list: Station[] = [];
  search_status: any;
  public data_route: Array<string>;
  search_km_st : number
  search_m_st : number
  search_km_en : number
  search_m_en : number
  currentPage: any = 1;
  itemsPerPage: any = 15;

  isAdd: boolean = false;
  isEdit: boolean = false;
  constructor(
    private func:FuncService,
    private commonService: CommonService,
    private stationService: StationService, 
    private modalService: BsModalService,
    private rvS : ReversibleService,
    private apiService: ApiService

  ){
    mapboxgl.accessToken = environment.mapbox.accessToken;
    this.commonService.activityLog(ActionEnum.Get, `Assets Management ➡ Setting ➡ Reversible`).subscribe(res => { }, error => { console.log(error); });
  }

  async ngOnInit() {
    this.isAdd = await this.commonService.getPermission(permission.setting.reversible.add_id).toPromise();
    this.isEdit = await this.commonService.getPermission(permission.setting.reversible.edit_id).toPromise();
    this.getlist()
    this.getData()
  }
  async getData() {
    this.getRoute()
    this.getStationSearch()
    this.data_list = await this.rvS.getReversible()
    this.is_loading=false
  }

  getRoute() {
    this.commonService.getRoute().subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            if (res.data) {
              this.route_list = _.cloneDeep(res.data);
              this.data_route = _.cloneDeep(res.data);
            }
          }
        }
      },
      error => {
      });
  }

  handleValueStatus(value) {
    if (value) {
      this.search_status = value;
    } else {
      this.search_status = null;
    }
  }
  handleFilterRoute(value) {
    if (value.length > 0) {
      this.data_route = this.route_list.filter((s) => s.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else if (value.length == 0) {
      this.data_route = _.cloneDeep(this.route_list);
    } else {
      this.data_route = [];
    }
  }
  handleValueRoute(value) {
    if (value) {
      this.search_route = value;
    } else {
      this.search_route = null;
    }
  }
  getStationSearch() {
    this.stationService.getStation(null, null, 1).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            if (res.data) {
              this.station_search_list = _.cloneDeep(res.data.data);
              this.data_station_search = _.cloneDeep(res.data.data);
              this.data_station_modal = _.cloneDeep(res.data.data);
            }
          }
        }
      },
      error => {
      });
  }
  handleFilterStation(value) {
    if (value.length > 0) {
      this.data_station_search = this.station_search_list.filter(s=>s.name != null).filter((s) => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else if (value.length == 0) {
      this.data_station_search = _.cloneDeep(this.station_search_list);
    } else {
      this.data_station_search = [];
    }
  }
  handleValueStation(value) {
    if (value) {
      this.search_station = value;
    } else {
      this.search_station = null;
    }
  }
  changeView(id) {
    if (id != this.viewType) {
      if (id == 1) {
        // this.getDevice();
      } else {
        // this.getStationMap();
        // setTimeout(() => { this.updateMap(); this.is_loading = false; this.map_station.resize(); }, 1000);

      }
      // if(this.view==1 && !this.map) this.buildMap()
    }
  }


  // ===================================

  async getlist() {
    this.is_loading = true;
    this.values = await this.rvS.getReversible()
    
    this.latlng = []
    for (const i of this.values) {
      if (i.longitude && i.latitude)
        this.latlng.push({
          coor: [i.longitude, i.latitude],
          prop: i
        })
    }
    if (this.values.length) this.no_data = false
    this.buildMap()
  }
  resize() {
    // this.map.resize();
    if(this.view==1 && !this.map) this.buildMap()
  }
  handleFilter(value) {
    if (value.length > 0) {
      this.data_sta = this.data_sta.filter((s) => s.station_name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else if (value.length == 0) {
      // this.data_sta = _.cloneDeep(this.staList);
    } else {
      this.data_sta = [];
    }
  }
  handleValue(value) {
    if (value) {
      this.sta = value;
    } else {
      this.sta = null;
    }
  }
  FilterRoute(evt: any) {
    if (evt.length > 0) {
      this.setRouteList = this.route_list.filter(res => res.includes(evt))
    } else if (evt.length == 0) {
      this.setRouteList = this.route_list
    } else {

    }
  }
  async ChageRoute(evt) {
    if (evt) {
      // this.staList = await this.getStationList({
      //   route: evt
      // })

    } else {
      // this.staList = await this.getStationList()
      this.start_km = null
      this.start_m = null
      this.end_km = null
      this.end_m = null
      this.sta = null
    }
  }
  async search() {
    this.is_loading = true;
    this.no_data = true
    let data: any = {}
    this.data_list = []
    if (this.search_station) data.station_id = this.search_station.id
    if (this.route_id) data.route = Number(this.route_id)
    if (this.start_km) data.start_km = Number(this.start_km)
    if (this.start_m) data.start_m = Number(this.start_m)
    if (this.end_km) data.end_km = Number(this.end_km)
    if (this.end_m) data.end_m = Number(this.end_m)
    console.log(data)
    // data.direction = []
    // for (const key in this.direction) {
    //   if (Object.prototype.hasOwnProperty.call(this.direction, key)) {
    //     const element = this.direction[key];
    //     if (element) data.direction.push(Number(key) + 1)
    //   }
    // }
    let res: any = await this.rvS.getReversible(data)
    if (res.length) {
      this.no_data = false
      this.values = res
      this.data_list = res
      this.latlng = []
      for (const i of res) {
        if (i.longitude && i.latitude)
          this.latlng.push({
            coor: [i.longitude, i.latitude],
            prop: i
          })
      }
      this.updateSourceMap(this.latlng);
      this.map.setLayoutProperty('clusters', 'visibility', 'visible');
      this.map.setLayoutProperty('cluster-count', 'visibility', 'visible');
      this.map.setLayoutProperty('mks', 'visibility', 'visible');
      this.map.setLayoutProperty('s_mks', 'visibility', 'visible');
    } else {
      this.values = []
      this.data_list = []
      this.map.setLayoutProperty('clusters', 'visibility', 'none');
      this.map.setLayoutProperty('cluster-count', 'visibility', 'none');
      this.map.setLayoutProperty('mks', 'visibility', 'none');
      this.map.setLayoutProperty('s_mks', 'visibility', 'none');
    }
    this.is_loading = false;
  }
  openModal(data?: any) {
    const initialState = {
      values: data,
    };
    this.modalRef = this.modalService.show(ReversibleLaneModalComponent, { initialState, ignoreBackdropClick: true, class: 'modal-BG w-80' });
    this.modalRef.content.event.subscribe(data => {
      if (data) this.ngOnInit()
      // return resolve(data);
    });
  }
  
  async rmlist(data){
    let result = await this.func.alertpopup('<h4>คุณต้องการลบใช่หรือไม่</h4>', `<i class="icon-info mr-2"></i>ลบผู้ใช้งาน`, 1);
    if (result) {
      let res: any = await this.rvS.rmRv(data)

      if (res) {
        let request_data = { url: `${this.apiService.api.reversible}/${data}}` };
        this.commonService.activityLog(ActionEnum.Delete, `Assets Management ➡ Setting ➡ Reversible`, JSON.stringify(request_data)).subscribe(res => { }, error => { console.log(error); });
        this.getData()
      }
    }
  }
  // async deleteList() {
  //   let res: any = await this.rvS.rmRv({ rv_id: this.rm_data.id })
  //   if (res) {
  //     this.ngOnInit()
  //     // this.ResMessage = 'OK';
  //     // this.swalSuccess.show();
  //   }
  // }
  cancelHandler() {

  }
  buildMap() {
    try {
      this.map = new mapboxgl.Map({
        container: 'map_rv',
        style: environment.mapbox.style,
        zoom: 10,
        center: [100.5976347, 14.1682688],
      });
      const popup = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false, anchor: 'bottom', offset: [0, -45]
      });

      // var spiderifier = new MapboxglSpiderifier(this.map, {
      //   onClick: function(e, spiderLeg){
      //     console.log('Clicked on ', spiderLeg);
      //   },
      //   markerWidth: 40,
      //   markerHeight: 40,
      // });

      // ======================

      this.map.on('load', (event) => {

        var features = [
          { id: 0, type: 'car', color: 'red' },
          { id: 1, type: 'bicycle', color: '#ff00ff' },
          { id: 2, type: 'bus', color: 'blue' },
          { id: 3, type: 'cab', color: 'orange' },
          { id: 4, type: 'train', color: 'red' }
        ];

        // spiderifier.spiderfy([100.12342929840088, 14.345639615708862], features);
        
        setTimeout(() => {
          this.map.resize();
        }, 2000);
        this.addSourceMap(this.latlng);
        this.addMapLayer();
        this.is_loading = false;
      })
      this.map.on('click', (event) => {
        // this.addSourceShopMap();
        // this.addMapLayer();
      })
      this.map.on('click', 'clusters', function (e) {
        var features = this.map.queryRenderedFeatures(e.point, {
          layers: ['clusters']
        });
        var clusterId = features[0].properties.cluster_id;
        this.map.getSource('mks-map').getClusterExpansionZoom(
          clusterId,
          function (err, zoom) {
            if (err) return;
            this.map.easeTo({
              center: features[0].geometry.coordinates,
              zoom: zoom
            });
          }.bind(this)
        )

        this.clickItem({
          lng: features[0].geometry.coordinates[0],
          lat: features[0].geometry.coordinates[1],
        })
      }.bind(this));
      this.map.on('click', 'mks', (event) => {
        let prop = event.features[0].properties
        let chk_latlng = _.filter(this.latlng, { coor: [prop.longitude, prop.latitude] })
        if (chk_latlng.length > 1) {
          const chk: any = []
          let num: number = 1
          let helf: number = 0
          let type: number = 1
          if (chk_latlng.length % 2 == 0) {
            type = 2
            helf = chk_latlng.length / 2
          } else {
            type = 1
            helf = (chk_latlng.length - 1) / 2
          }
          for (const i of chk_latlng) {
            let mi: number = 1
            if (num <= helf) mi = (-2)
            if ((type == 1) && (num == chk_latlng.length)) {
              mi = 0
            }
            chk.push({
              coor: [(i.coor[0] + (0.001 * num * mi)), (i.coor[1] + (0.001))],
              prop: i.prop
            })
            num++
          }
          if (this._subMkShow === null) {
            this.addSubMkSourceMap(chk)
            this.addSubMkLayer()
          } else {
            this._subMkShow = !this._subMkShow
            this.updateSubMkSourceMap(chk)
            this.map.setLayoutProperty('s_mks', 'visibility', `${(this._subMkShow) ? 'visible' : 'none'}`);
          }

        } else {
          this.openModal(prop)

        }

      })
      this.map.on('mouseenter', 'clusters', (event) => {
        this.map.getCanvas().style.cursor = 'pointer';
      })
      this.map.on('mouseleave', 'clusters', (event) => {
        this.map.getCanvas().style.cursor = '';
      })
      this.map.on('mouseenter', 'mks', (event) => {
        this.map.getCanvas().style.cursor = 'pointer';
        let prop = event.features[0].properties
        let chk = _.filter(this.latlng, { coor: [prop.longitude, prop.latitude] })
        let htmlTxt = ''
        if (chk.length > 1) {
          for (const i of chk) {
            htmlTxt += `<div>
          ${i.prop.reversible_name} ${(i.prop.km) ? '(' + i.prop.km + '+' + i.prop.m + ')' : ''}</div>`
          }
        } else {
          htmlTxt = `<div>
          ${prop.reversible_name} ${(prop.km) ? '(' + prop.km + '+' + prop.m + ')' : ''}</div>`
        }
        popup.setLngLat(event.features[0].geometry.coordinates)
          .setHTML(this.getAddIncLayerHtml(htmlTxt))
          .addTo(this.map);
      })
      this.map.on('mouseleave', 'mks', (event) => {
        this.map.getCanvas().style.cursor = '';
        popup.remove()
      })

      // ==============
      this.map.on('click', 's_mks', (event) => {

        let prop = event.features[0].properties
        this.openModal(prop)
      })
      this.map.on('mouseenter', 's_mks', (event) => {
        this.map.getCanvas().style.cursor = 'pointer';
        let prop = event.features[0].properties
        let htmlTxt = ''
        htmlTxt = `<div>
        ${prop.reversible_name} ${(prop.km) ? '(' + prop.km + '+' + prop.m + ')' : ''}</div>`
        popup.setLngLat(event.features[0].geometry.coordinates)
          .setHTML(this.getAddIncLayerHtml(htmlTxt))
          .addTo(this.map);
      })
      this.map.on('mouseleave', 's_mks', (event) => {
        this.map.getCanvas().style.cursor = '';
        popup.remove()
      })
    } catch (err) {
      console.log("error from mapbox" + err);
    }
  }
  flytopoint(e) {
    // var features = this.map.queryRenderedFeatures(e.point, {
    //   layers: ['clusters']
    //   });
    //   var clusterId = features[0].properties.cluster_id;
    //   this.map.getSource('mks-map').getClusterExpansionZoom(
    //   clusterId,
    //   function (err, zoom) {
    //   if (err) return;

    //   this.map.easeTo({
    //   center: features[0].geometry.coordinates,
    //   zoom: zoom
    //   });
    //   }
    //   );


  }
  addMapLayer() {

    this.map.addLayer({
      id: 'clusters',
      type: 'circle',
      source: 'mks-map',
      filter: ['has', 'point_count'],
      paint: {
        // Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
        // with three steps to implement three types of circles:
        //   * Blue, 20px circles when point count is less than 100
        //   * Yellow, 30px circles when point count is between 100 and 750
        //   * Pink, 40px circles when point count is greater than or equal to 750
        'circle-color': [
          'step',
          ['get', 'point_count'],
          '#51bbd6',
          100,
          '#f1f075',
          750,
          '#f28cb1'
        ],
        'circle-radius': [
          'step',
          ['get', 'point_count'],
          20,
          100,
          30,
          750,
          40
        ]
      }
    });
    // ------------------
    this.map.addLayer({
      id: 'cluster-count',
      type: 'symbol',
      source: 'mks-map',
      filter: ['has', 'point_count'],
      layout: {
        'text-field': '{point_count_abbreviated}',
        'text-size': 12
      }
    });
    // -------------------
    // this.map.addLayer({
    //   id: 'mks',
    //   type: 'circle',
    //   source: 'mks-map',
    //   filter: ['!', ['has', 'point_count']],
    //   paint: {
    //   'circle-color': '#11b4da',
    //   'circle-radius': 4,
    //   'circle-stroke-width': 1,
    //   'circle-stroke-color': '#fff'
    //   }
    //   });
    // -------------------
    this.map.loadImage('./assets/marker.png', function (error, image) {
      if (error) console.error(error);
      this.map.addImage('mks', image);
      this.map.addLayer({
        id: "mks",
        type: "symbol",
        source: "mks-map",
        filter: ['!', ['has', 'point_count']],
        // filter: ["has", "point_count"],
        layout: {
          "icon-image": "mks",
          "icon-size": 0.08,
          "icon-allow-overlap": false,
          //"icon-rotate": 70,
          "icon-rotate": {
            type: "identity",
            property: "heading",
            default: 0
          }
        }
      });
    }.bind(this));
  }

  addSourceMap(data: any) {
    data.push({
      prop:"nothing",
      coor:[100,13]
    })
    if (data.length < 1) return false
    let data_feature: any = {
      'type': 'FeatureCollection',
      'features': [
      ]
    }
    let allMk: any[] = []
    for (const i of data) {
      allMk.push(i.coor)
      data_feature.features.push({
        type: 'Feature',
        properties: i.prop,
        geometry: {
          type: 'Point',
          coordinates: i.coor
        }
      })
    }
    this.map.addSource('mks-map', {
      type: 'geojson',
      data: data_feature,
      cluster: true,
      clusterMaxZoom: 11, // Max zoom to cluster points on
      clusterRadius: 50 // Radius of each cluster when clustering points (defaults to 50)
    });

    this.flyAllMarker(allMk)
  }
  updateSourceMap(data) {
    if (data.length < 1) return false
    let data_feature: any = {
      'type': 'FeatureCollection',
      'features': [
      ]
    }
    let allMk: any[] = []
    for (const i of data) {
      allMk.push(i.coor)
      data_feature.features.push({
        type: 'Feature',
        properties: i.prop,
        geometry: {
          type: 'Point',
          coordinates: i.coor
        }
      })
    }

    this.map.getSource('mks-map').setData(data_feature);
    this.flyAllMarker(allMk)

  }
  addSubMkLayer() {

    this.map.loadImage('./assets/submarker.png', function (error, image) {
      if (error) console.error(error);
      this.map.addImage('s_mks', image);
      this.map.addLayer({
        id: "s_mks",
        type: "symbol",
        source: "s_mks-map",
        filter: ['!', ['has', 'point_count']],
        // filter: ["has", "point_count"],
        layout: {
          "icon-image": "s_mks",
          "icon-size": 0.08,
          "icon-allow-overlap": false,
          //"icon-rotate": 70,
          "icon-rotate": {
            type: "identity",
            property: "heading",
            default: 0
          }
        }
      });
    }.bind(this));
  }
  addSubMkSourceMap(data: any) {
    this._subMkShow = true
    if (data.length < 1) return false
    let data_feature: any = {
      'type': 'FeatureCollection',
      'features': [
      ]
    }
    let allMk: any[] = []
    for (const i of data) {
      allMk.push(i.coor)
      data_feature.features.push({
        type: 'Feature',
        properties: i.prop,
        geometry: {
          type: 'Point',
          coordinates: i.coor
        }
      })
    }
    this.map.addSource('s_mks-map', {
      type: 'geojson',
      data: data_feature,
      cluster: true,
      clusterMaxZoom: 11, // Max zoom to cluster points on
      clusterRadius: 50 // Radius of each cluster when clustering points (defaults to 50)
    });
    // this.flyAllSubMarker(allMk)
  }
  updateSubMkSourceMap(data) {
    if (data.length < 1) return false
    let data_feature: any = {
      'type': 'FeatureCollection',
      'features': [
      ]
    }
    let allMk: any[] = []
    for (const i of data) {
      allMk.push(i.coor)
      data_feature.features.push({
        type: 'Feature',
        properties: i.prop,
        geometry: {
          type: 'Point',
          coordinates: i.coor
        }
      })
    }

    this.map.getSource('s_mks-map').setData(data_feature);
    // this.flyAllSubMarker(allMk)

  }
  getAddIncLayerHtml(txt: any): any {
    let htmltext = `
    <div style="padding: 10px 10px 10px;">
      <div style="max-width: 250px;">${txt}</div>
    </div>
  `;
    return htmltext;
  }
  clickItem(item) {
    this.map.flyTo({ center: [item.lng, item.lat], zoom: 15 });
  }
  flyAllMarker(val: any) {
    let minLngLat: any = val[0]
    let maxLngLat: any = val[0]
    for (const i of val) {
      // lng
      if (i[0] < minLngLat[0]) minLngLat[0] = i[0]
      if (i[0] > maxLngLat[0]) maxLngLat[0] = i[0]
      // lat
      if (i[1] < minLngLat[1]) minLngLat[1] = i[1]
      if (i[1] > maxLngLat[1]) maxLngLat[1] = i[1]
    }
    this.map.fitBounds([
      [minLngLat[0] - 0.5, minLngLat[1] - 0.5],
      [maxLngLat[0] + 0.5, maxLngLat[1] + 0.5]
    ], {
      padding: {
        top: 100, bottom: 25, left: 15, right: 5
      }
    });
  }
  flyAllSubMarker(val: any) {
    let minLngLat: any = val[0]
    let maxLngLat: any = val[0]
    for (const i of val) {
      // lng
      if (i[0] < minLngLat[0]) minLngLat[0] = i[0]
      if (i[0] > maxLngLat[0]) maxLngLat[0] = i[0]
      // lat
      if (i[1] < minLngLat[1]) minLngLat[1] = i[1]
      if (i[1] > maxLngLat[1]) maxLngLat[1] = i[1]
    }
    this.map.fitBounds([
      [minLngLat[0] - 0.009, minLngLat[1] - 0.009],
      [maxLngLat[0] + 0.009, maxLngLat[1] + 0.009]
    ], {
      padding: {
        top: 100, bottom: 25, left: 15, right: 5
      }
    });
  }
  // ==========================================
}
