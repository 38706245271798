<div class="row p-3">
  <div class="col-lg-4 col-md-4 col-sm-6">
    <div>ทางหลวง :</div>
    <div>
      <kendo-combobox class="w-100" [data]="data_route" [allowCustom]="allowCustom" [textField]="" [valueField]=""
        [value]="search_route" (valueChange)="handleValueRoute($event)" (filterChange)="handleFilterRoute($event)"
        [placeholder]="'ทั้งหมด'" [filterable]="true" >
      </kendo-combobox>
    </div>
  </div>
  <div class="col-lg-4 col-md-4 col-sm-6">
    <div>ช่วงกิโลเมตร :</div>
    <div class="d-flex align-items-center">
      <div class="flex-fill"><span class="nowrap mr-1">เริ่มต้น :</span></div>
      <div class="flex-fill"><input type="text" class="form-control w-100" placeholder="กม." numbersOnly maxlength="4"
          [(ngModel)]="search_km_st" [ngModelOptions]="{standalone: true}"></div>
      <div class="flex-fill ml-1 mr-1"> + </div>
      <div class="flex-fill"><input type="text" class="form-control w-100" placeholder="ม." numbersOnly maxlength="3"
          [(ngModel)]="search_m_st" [ngModelOptions]="{standalone: true}"></div>
      <div class="flex-fill"><span class="nowrap ml-1 mr-1">สิ้นสุด :</span></div>
      <div class="flex-fill"><input type="text" class="form-control w-100" placeholder="กม." numbersOnly maxlength="4"
          [(ngModel)]="search_km_en" [ngModelOptions]="{standalone: true}"></div>
      <div class="flex-fill ml-1 mr-1"> + </div>
      <div class="flex-fill"><input type="text" class="form-control w-100" placeholder="ม." numbersOnly maxlength="3"
          [(ngModel)]="search_m_en" [ngModelOptions]="{standalone: true}"></div>
    </div>
  </div>
  <div class="col-lg-4 col-md-4 col-sm-6">
    <div>สถานะ :</div>
    <div>
      <kendo-combobox class="w-100" [data]="status_list" [allowCustom]="allowCustom" [textField]="'name'" [filterable]="true" 
        [valueField]="'id'" [value]="search_status" (valueChange)="handleValueStatus($event)" [placeholder]="'ทั้งหมด'">
      </kendo-combobox>
    </div>
  </div>
  <div class="col-lg-4 col-md-4 col-sm-6">
    <div>Station :</div>
    <div>
      <kendo-combobox class="w-100" [data]="station_search_list" [allowCustom]="allowCustom" [textField]="'name'"
        [valueField]="'id'" [value]="search_station" (valueChange)="handleValueStation($event)" [filterable]="true" 
        (filterChange)="handleFilterStation($event)" [placeholder]="'ทั้งหมด'">
      </kendo-combobox>
    </div>
  </div>
  <div class="col-lg-4 col-md-4 col-sm-6">
    <div>Owner :</div>
    <div>
      <kendo-combobox class="w-100" [data]="data_owner" [allowCustom]="allowCustom" [textField]="'company_name'"
        [valueField]="'id'" [value]="search_owner" (valueChange)="handleValueOwner($event)" [filterable]="true" 
        (filterChange)="handleFilterOwner($event)" [placeholder]="'ทั้งหมด'">
      </kendo-combobox>
    </div>
  </div>
  <div class="col-lg-4 col-md-4 col-sm-6">
    <div>Vendor :</div>
    <div>
      <kendo-combobox class="w-100" [data]="data_vendor" [allowCustom]="allowCustom" [textField]="'company_name'"
        [valueField]="'vendor_id'" [value]="search_vendor" (valueChange)="handleValueVendor($event)" [filterable]="true" 
        (filterChange)="handleFilterVendor($event)" [placeholder]="'ทั้งหมด'">
      </kendo-combobox>
    </div>
  </div>
  <div class="col-lg-4 col-md-4 col-sm-6">
    <div>กลุ่มอุปกรณ์ :</div>
    <div>
      <kendo-combobox class="w-100" [data]="data_device_group" [allowCustom]="allowCustom" [textField]="'name'"
        [valueField]="'id'" [value]="search_device_group" (valueChange)="handleValueDeviceGroup($event)" [filterable]="true" 
        (filterChange)="handleFilterDeviceGroup($event)" [placeholder]="'ทั้งหมด'">
      </kendo-combobox>
    </div>
  </div>
  <div class="col-lg-4 col-md-4 col-sm-6">
    <div>ประเภทอุปกรณ์ :</div>
    <div>
      <kendo-combobox class="w-100" [data]="data_device_type" [allowCustom]="allowCustom" [textField]="'name'"
        [valueField]="'type_id'" [value]="search_device_type" (valueChange)="handleValueDeviceType($event)" [filterable]="true" 
        (filterChange)="handleFilterDeviceType($event)" [placeholder]="'ทั้งหมด'">
      </kendo-combobox>
    </div>
  </div>

  <div class="col-lg-4 col-md-4 d-flex align-items-end justify-content-end">
    <div class="mt-1">
      <button type="button" (click)="search()" class="btn btn-primary pull-right text-white ml-1"> <i
          class="fa fa-search"></i>
        ค้นหา </button>
    </div>
  </div>

  <div class="col-12 mt-3 table-header pl-3 pr-3 pt-2 pb-2 d-flex align-items-center justify-content-between">
    <div class="btn-group btn-group-toggle" data-toggle="buttons">
      <label class="btn text-white pointer" [ngClass]="(viewType == 1)?'btn-primary':'btn-dark'">
        <input type="radio" name="options" id="option1" autocomplete="off" [value]="1" [(ngModel)]="viewType" (click)="changeView(1)"> <i
          class="fa fa-bars mr-1"></i>List
      </label>
      <label class="btn text-white pointer" [ngClass]="(viewType == 2)?'btn-primary':'btn-dark'">
        <input type="radio" name="options" id="option1" autocomplete="off" [value]="2" [(ngModel)]="viewType" (click)="changeView(2)"><i
          class="fa fa-map mr-1"></i> Map
      </label>
    </div>
    <button *ngIf="isAdd" (click)="openAddModal()" type="button" class="btn btn-primary text-white"> <i class="fa fa-plus"></i>
      เพิ่มอุปกรณ์</button>
  </div>
  <div *ngIf="viewType == 1" class="col-12 mt-3">
    <table class="table table-outline mb-0">
      <thead class="thead-dark" style="background-color: black;">
        <tr>
          <th class="text-center text-light table-header"><i class="fa fa-hashtag"></i></th>
          <th class="text-center text-light table-header">สถานะ</th>
          <th class="text-center text-light table-header">อุปกรณ์</th>
          <th class="text-center text-light table-header">Station</th>
          <th class="text-center text-light table-header">ประเภทอุปกรณ์</th>
          <th class="text-center text-light table-header">ประเภทย่อยอุปกรณ์</th>
          <th class="text-center text-light table-header">Create date</th>
          <th *ngIf="isEdit" class="text-center text-light table-header">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngIf="!this.is_loading && data_list?.length == 0">
          <td colspan="10" style="height: 50px;">
            ไม่พบข้อมูล
          </td>
        </tr>
        <tr *ngIf="this.is_loading">
          <td colspan="10" style="height: 50px;">
            กำลังโหลด ..
          </td>
        </tr>
        <tr *ngFor="let item of data_list;index as i;">
          <td class="text-center">
            <div>
              {{(i+1)+((this.currentPage - 1)*itemsPerPage)}}
            </div>
          </td>
          <td class="text-center">
            <i tooltip="Active" placement="top" *ngIf="item?.status == statusEnum.Active"
              class="fa fa-circle text-success"></i>
            <i tooltip="Inctive" placement="top" *ngIf="item?.status == statusEnum.Inactive"
              class="fa fa-circle text-danger"></i>
              <i tooltip="MA" placement="top" *ngIf="item?.status == statusEnum.Ma"
              class="fa fa-circle text-warning"></i>
          </td>
          <td class="text-center">
            {{item?.name}}
          </td>
          <td class="text-center">
            {{item?.station_str}}
          </td>
          <td class="text-center">
            {{item?.device_type_str}}
          </td>
          <td class="text-center">
            {{item?.device_sub_type_str}}
          </td>
          <td class="text-center">
            <div style="font-size: 12px;">{{item?.create_date | date: 'dd/MM/yyyy'}}</div>
          </td>

          <td *ngIf="isEdit" class="text-center">
            <div class="d-flex align-items-center justify-content-center">
              <button (click)="openEditModal(item)" title="แก้ไข" type="button"
                class="btn btn-sm btn-warning mr-1 text-light">
                <i class="cui-note icons"></i>
              </button>
              <button (click)="openDeleteModal(item)" title="ลบ" type="button" class="btn btn-sm btn-danger ml-1">
                <i class="cui-trash icons"></i>
              </button>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="12" class="table-footer">
            <div class="table-footer-inside d-flex align-items-center justify-content-between">
              <div class="ml-3 pt-3">
                <pagination [totalItems]="totalItems" [maxSize]="5" [itemsPerPage]="itemsPerPage"
                  [(ngModel)]="currentPage" (pageChanged)="pageChanged($event)" class="pagination"
                  previousText="&lsaquo;" nextText="&rsaquo;" [boundaryLinks]="true" firstText="&laquo;"
                  lastText="&raquo;"></pagination>
              </div>
              <div></div>
              <div class="mr-3 ml-2">total: {{totalItems}}</div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="col-12" style="position: relative;">
    <div class="map" id="map-station-device" [ngClass]="(viewType == 2) ? 'map' : 'map-hide'"></div>
    <div *ngIf="viewType == 2" class="s-box scrollbar-style">
      <div (click)="clickMapStationList(item.lat,item.lng)" *ngFor="let item of stationList;index as i;" class="pointer s-box-bg p-2" [ngClass]="(i%2 == 0) ? 's-box-even-bg' : ''">
         <div>{{item.name}}</div>
         <div class="d-flex flex-wrap">
          <div *ngFor="let d of item?.devices;index as i2;">
            <img tooltip="{{d?.name}}" placement="top" *ngIf="d.device_subtype?.image" [src]="d.device_subtype?.image"
                class="rounded-circle mr-1 ml-1" width="20" height="20"
                [ngClass]="{'device-status-on':d?.connection_status == connectionStatusEnum.Online, 'device-status-off':d?.connection_status == connectionStatusEnum.Offline,'device-status-tmpoff':d?.connection_status == connectionStatusEnum.TmpOffline, 'device-status-null':d?.connection_status == null}">
              <div tooltip="{{d?.name}}" placement="top" *ngIf="!d?.device_subtype?.image" class="rounded-circle device-sub-null-20 mr-1 ml-1"
                [ngClass]="{'device-status-on':d?.connection_status == connectionStatusEnum.Online, 'device-status-off':d?.connection_status == connectionStatusEnum.Offline,'device-status-tmpoff':d?.connection_status == connectionStatusEnum.TmpOffline, 'device-status-null':d?.connection_status == null}">
              </div>
          </div>
         </div>
      </div>
    </div>
  </div>
</div>


<div bsModal #addModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
  aria-hidden="true" (onHide)="clearData()" [config]="{backdrop: 'static'}">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header bg-dark bg-gray-900">
        <h4 class="modal-title"> <i class="icon-info mr-2"></i><span *ngIf="!selectItem?.device_id">เพิ่ม
            อุปกรณ์</span><span *ngIf="selectItem?.device_id">แก้ไข อุปกรณ์</span></h4>
        <button type="button" class="close text-light" (click)="addModal.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form class="form-horizontal mt-2 mb-2">
          <div class="form-group row">
            <div class="col-md-6">
              <div><span>ชื่ออุปกรณ์<span class="text-danger">*</span>:</span></div>
              <div>
                <input autocomplete="nope" type="text" placeholder="" maxlength="100" class="form-control w-100"
                  [(ngModel)]="selectItem.name" [ngModelOptions]="{standalone: true}" />
              </div>
            </div>
            <div class="col-md-6">
              <div> <span>สถานะ<span class="text-danger">*</span>:</span></div>
              <div>
                <kendo-combobox class="w-100" [data]="status_list" [allowCustom]="allowCustom" [textField]="'name'"
                  [valueField]="'id'" [value]="status_modal" (valueChange)="handleValueStatusModal($event)"
                  [placeholder]="'โปรดระบุ'" [filterable]="true" >
                </kendo-combobox>
              </div>
            </div>
            <div class="col-md-6">
              <div><span>Station<span class="text-danger">*</span>:</span></div>
              <div>
                <kendo-combobox class="w-100" [data]="data_station_modal" [allowCustom]="allowCustom"
                  [textField]="'name'" [valueField]="'id'" [value]="station_modal"
                  (valueChange)="handleValueStationModal($event)" (filterChange)="handleFilterStationModal($event)"
                  [placeholder]="'โปรดระบุ'" [filterable]="true" >
                </kendo-combobox>
              </div>
            </div>
            <div class="col-md-6">
              <div><span>Device Group<span class="text-danger">*</span>:</span></div>
              <div>
                <kendo-combobox class="w-100" [data]="data_device_group_modal" [allowCustom]="allowCustom"
                  [textField]="'name'" [valueField]="'id'" [value]="device_group_modal"
                  (valueChange)="handleValueDeviceGroupModal($event)" [filterable]="true" 
                  (filterChange)="handleFilterDeviceGroupModal($event)" [placeholder]="'โปรดระบุ'">
                </kendo-combobox>
              </div>
            </div>
            <div class="col-md-6">
              <div><span>Device Type<span class="text-danger">*</span>:</span></div>
              <div>
                <kendo-combobox class="w-100" [data]="data_device_type_modal" [allowCustom]="allowCustom"
                  [textField]="'name'" [valueField]="'type_id'" [value]="device_type_modal"
                  (valueChange)="handleValueDeviceTypeModal($event)" [filterable]="true" 
                  (filterChange)="handleFilterDeviceTypeModal($event)" [placeholder]="'โปรดระบุ'">
                </kendo-combobox>
              </div>
            </div>
            <div class="col-md-6">
              <div><span>Sub Device Type<span class="text-danger">*</span>:</span></div>
              <div>
                <kendo-combobox class="w-100" [data]="data_device_subtype_modal" [allowCustom]="allowCustom"
                  [textField]="'name'" [valueField]="'subtype_id'" [value]="device_subtype_modal"
                  (valueChange)="handleValueDeviceSubTypeModal($event)" [filterable]="true" 
                  (filterChange)="handleFilterDeviceSubTypeModal($event)" [placeholder]="'โปรดระบุ'">
                </kendo-combobox>
              </div>
            </div>
            <div class="col-md-6">
              <div><span>Serial no<span class="text-danger">*</span>:</span></div>
              <div>
                <input autocomplete="nope" type="text" placeholder="" maxlength="100" class="form-control w-100"
                  [(ngModel)]="selectItem.serial_no" [ngModelOptions]="{standalone: true}" />
              </div>
            </div>

            <div class="col-md-6">
              <div><span>IP :</span></div>
              <div>
                <input autocomplete="nope" type="text" placeholder="" maxlength="100" class="form-control w-100"
                  [(ngModel)]="selectItem.ip" [ngModelOptions]="{standalone: true}" />
              </div>
            </div>
            <div class="col-md-6">
              <div><span>SIM :</span></div>
              <div>
                <input autocomplete="nope" type="text" placeholder="" maxlength="100" class="form-control w-100"
                  [(ngModel)]="selectItem.sim_number" [ngModelOptions]="{standalone: true}" />
              </div>
            </div>
            <div class="col-md-6">
              <div><span>Link Snapshot :</span></div>
              <div>
                <input autocomplete="nope" type="text" placeholder="" maxlength="100" class="form-control w-100"
                  [(ngModel)]="selectItem.link_snapshot" [ngModelOptions]="{standalone: true}" />
              </div>
            </div>
            <div class="col-12" *ngFor="let item of select_steaming">
              <div class="row">
                <div class="col-md-6">
                  <div><span>Link Steaming :</span></div>
                  <div>
                    <input autocomplete="nope" type="text" placeholder="" maxlength="100" class="form-control w-100"
                      [(ngModel)]="item.link" [ngModelOptions]="{standalone: true}" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div> <span>Link Type :</span></div>
                  <div>
                    <kendo-combobox class="w-100" [data]="steaming_type_list" [allowCustom]="allowCustom"
                      [textField]="'name'" [valueField]="'id'" [(value)]="item.type" [placeholder]="'โปรดระบุ'"
                      [filterable]="true" >
                    </kendo-combobox>
                  </div>
                </div>
              </div>
            </div>
            
            <!-- <div class="col-md-6">
              <div><span>Link Steaming :</span></div>
              <div>
                <input autocomplete="nope" type="text" placeholder="" maxlength="100" class="form-control w-100"
                  [(ngModel)]="selectItem.serial_no" [ngModelOptions]="{standalone: true}" />
              </div>
            </div>
            <div class="col-md-6">
              <div> <span>Link Type :</span></div>
              <div>
                <kendo-combobox class="w-100" [data]="steaming_type_list" [allowCustom]="allowCustom"
                  [textField]="'name'" [valueField]="'id'" [value]="steaming_type_modal"
                  (valueChange)="handleValueSteamingTypeModal($event)" [placeholder]="'โปรดระบุ'">
                </kendo-combobox>
              </div>
            </div>
            <div class="col-md-6">
              <div><span>Link Steaming :</span></div>
              <div>
                <input autocomplete="nope" type="text" placeholder="" maxlength="100" class="form-control w-100"
                  [(ngModel)]="selectItem.serial_no" [ngModelOptions]="{standalone: true}" />
              </div>
            </div>
            <div class="col-md-6">
              <div> <span>Link Type :</span></div>
              <div>
                <kendo-combobox class="w-100" [data]="steaming_type_list" [allowCustom]="allowCustom"
                  [textField]="'name'" [valueField]="'id'" [value]="steaming_type_modal"
                  (valueChange)="handleValueSteamingTypeModal($event)" [placeholder]="'โปรดระบุ'">
                </kendo-combobox>
              </div>
            </div> -->
            <div class="col-md-6">
              <div><span>Brand :</span></div>
              <div>
                <kendo-combobox class="w-100" [data]="data_brand_modal" [allowCustom]="allowCustom"
                  [textField]="'brand_name'" [valueField]="'brand_id'" [value]="brand_modal"
                  (valueChange)="handleValueBrandModal($event)" (filterChange)="handleFilterBrandModal($event)"
                  [placeholder]="'โปรดระบุ'" [filterable]="true" >
                </kendo-combobox>
              </div>
            </div>
            <div class="col-md-6">
              <div><span>Model :</span></div>
              <div>
                <kendo-combobox class="w-100" [data]="data_brand_model_modal" [allowCustom]="allowCustom"
                  [textField]="'model_name'" [valueField]="'model_id'" [value]="brand_model_modal"
                  (valueChange)="handleValueBrandModelModal($event)" [filterable]="true" 
                  (filterChange)="handleFilterBrandModelModal($event)" [placeholder]="'โปรดระบุ'">
                </kendo-combobox>
              </div>
            </div>
            <div class="col-md-6">
              <div><span>Owner :</span></div>
              <div>
                <kendo-combobox class="w-100" [data]="data_owner_modal" [allowCustom]="allowCustom"
                  [textField]="'company_name'" [valueField]="'id'" [value]="owner_modal" [filterable]="true" 
                  (valueChange)="handleValueOwnerModal($event)" (filterChange)="handleFilterOwnerModal($event)"
                  [placeholder]="'โปรดระบุ'">
                </kendo-combobox>
              </div>
            </div>
            <div class="col-md-6">
              <div><span>Detail :</span></div>
              <div>
                <input autocomplete="nope" type="text" placeholder="" maxlength="100" class="form-control w-100"
                  [(ngModel)]="selectItem.owner_desc" [ngModelOptions]="{standalone: true}" />
              </div>
            </div>
            <div class="col-md-6">
              <div><span>Vendor :</span></div>
              <div>
                <kendo-combobox class="w-100" [data]="data_vendor_modal" [allowCustom]="allowCustom"
                  [textField]="'company_name'" [valueField]="'vendor_id'" [value]="vendor_modal" [filterable]="true" 
                  (valueChange)="handleValueVendorModal($event)" (filterChange)="handleFilterVendorModal($event)"
                  [placeholder]="'โปรดระบุ'">
                </kendo-combobox>
              </div>
            </div>
            <div class="col-md-6">
              <div><span>Detail :</span></div>
              <div>
                <input autocomplete="nope" type="text" placeholder="" maxlength="100" class="form-control w-100"
                  [(ngModel)]="selectItem.vendor_desc" [ngModelOptions]="{standalone: true}" />
              </div>
            </div>
            <div class="col-md-6">
              <div><span>Vendor contact :</span></div>
              <div>
                <kendo-combobox class="w-100" [data]="data_vendor_contact_modal" [allowCustom]="allowCustom"
                  [textField]="'contact_name'" [valueField]="'contact_id'" [value]="vendor_contact_modal"
                  (valueChange)="handleFilterVendorContactModal($event)" [filterable]="true" 
                  (filterChange)="handleFilterVendorContactModal($event)" [placeholder]="'โปรดระบุ'">
                </kendo-combobox>
              </div>
            </div>
            <div class="col-md-6">
              <div><span>Detail :</span></div>
              <div>
                <input autocomplete="nope" type="text" placeholder="" maxlength="100" class="form-control w-100"
                  [(ngModel)]="selectItem.vendor_contact_desc" [ngModelOptions]="{standalone: true}" />
              </div>
            </div>
            <div class="col-md-6">
              <div><span>วันที่ติดตั้ง :</span></div>
              <div>
                <kendo-datepicker [navigation]="false" class="w-100 mt-1 mb-1" [(value)]="selectItem.install_ts"
                  placeholder="dd/MM/yyyy" format="dd/MM/yyyy"
                  [formatPlaceholder]="{year: 'ปี', month: 'เดือน', day: 'วัน'}">
                  <kendo-datepicker-messages today="วันนี้"></kendo-datepicker-messages>
                </kendo-datepicker>
              </div>
            </div>
            <div class="col-md-6">
              <div><span>วันสิ้นสุดการซ่อมบำรุง :</span></div>
              <div>
                <kendo-datepicker [navigation]="false" class="w-100 mt-1 mb-1" [(value)]="selectItem.ma_expire_ts"
                  placeholder="dd/MM/yyyy" format="dd/MM/yyyy"
                  [formatPlaceholder]="{year: 'ปี', month: 'เดือน', day: 'วัน'}">
                  <kendo-datepicker-messages today="วันนี้"></kendo-datepicker-messages>
                </kendo-datepicker>
              </div>
            </div>
            <div class="col-md-12">
              <div>
                <label class="checkbox-container">
                  <span><strong>Monitoring</strong></span>
                  <input type="checkbox" [(ngModel)]="selectItem.is_monitor" [ngModelOptions]="{standalone: true}">
                  <span class="checkmark-box"></span>
                </label>
              </div>
            </div>
            <div class="col-md-12">
              <div><span>รูปภาพ :</span></div>
              <div class="d-flex flex-wrap">
                <div *ngFor="let item of select_images">
                      <div *ngIf="item.dataurl && item.file" class="up-img">
                        <img height="58" width="58" [src]="item?.dataurl | safe">
                        <i title="ลบ" class="fa fa-close up-im-delete text-light" (click)="deleteImageFiles(item)"></i>
                      </div>
                      <div *ngIf="item.url && item.delete != true" class="up-img">
                        <img *ngIf="item?.url" height="58" width="58" [src]="item?.url">
                        <i title="ลบ" class="fa fa-close up-im-delete text-light" (click)="deleteImageFiles(item)"></i>
                      </div>
                    </div>
                    <div class="btn btn-file">
                      <i title="เพิ่มรูป" class="fa fa-plus" style="padding-top: 20px;"></i>
                      <input #imInput title="เพิ่มรูป" type="file" (change)="detectFiles($event)" accept=".png,.jpg,.gif"
                        multiple>
                    </div>
                    
              </div>
            </div>
            
          </div>
        </form>
        <div class="d-flex justify-content-end mt-2 mb-2">
          <button type="button" class="btn btn-secondary" (click)="addModal.hide()">ยกเลิก</button>
          <button [disabled]="!(selectItem.name && status_modal != null && station_modal != null && device_group_modal != null
          && device_type_modal != null && device_subtype_modal != null && selectItem.serial_no)" *ngIf="selectItem?.device_id" type="button"
            class="btn btn-primary text-white ml-2" (click)="editDeviceAsyn()">แก้ไข</button>
          <button [disabled]="!(selectItem.name && status_modal != null && station_modal != null && device_group_modal != null
          && device_type_modal != null && device_subtype_modal != null && selectItem.serial_no)" *ngIf="!selectItem?.device_id" type="button"
            class="btn btn-primary text-white ml-2" (click)="addDeviceAsyn()">เพิ่ม</button>
        </div>

      </div>
      <!-- <div class="modal-footer">
              
            </div> -->
    </div>
  </div>
</div>
<div bsModal #deleteModal="bs-modal" class="modal fade modal-md" tabindex="-1" role="dialog"
  aria-labelledby="myModalLabel" aria-hidden="true" (onHide)="clearData()" [config]="{backdrop: 'static'}">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header bg-dark bg-gray-900">
        <h4 class="modal-title"> <i class="icon-info mr-2"></i>ลบอุปกรณ์</h4>
        <button type="button" class="close text-light" (click)="deleteModal.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="mt-2 text-center">
          <h4>คุณต้องการลบใช่หรือไม่</h4>
        </div>
        <div class="mt-2 mb-4 text-center">
          <!-- <h4>{{newsTypeItem?.news_type_name}}</h4> -->
        </div>
        <div class="d-flex justify-content-end mt-2 mb-2">
          <button type="button" class="btn btn-secondary" (click)="deleteModal.hide()">ยกเลิก</button>
          <!-- <button type="button" class="btn btn-primary ml-2" (click)="deleteType(newsTypeItem.id)">ยืนยัน</button> -->
          <button type="button" class="btn btn-primary text-white ml-2" (click)="deleteDevice()">ยืนยัน</button>
        </div>

      </div>
      <!-- <div class="modal-footer">
              
            </div> -->
    </div>
  </div>
</div>
<swal #swalSuccess title="<div class='text-light'>สำเร็จ</div>" type="success" text="" icon="success"></swal>
<swal #swalError title="<div class='text-light'>ไม่สำเร็จ</div>" html="<div class='text-light'>{{res_message}}</div>"
  type="error" icon="error"></swal>