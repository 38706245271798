<!-- 1 Start -->
<div class="animated fadeIn m-4">
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <div>
            <span *ngIf="selectedId" class="pointer mr-3" (click)="backClicked()">
              <i class="fa fa-chevron-left" aria-hidden="true"></i>
            </span>
            รายงานข้อมูลรายคันจากอุปกรณ์ ตรวจวัด</div>

        </div>
        <div class="card-body">

          <div class="row">
            <div class="col-4">
              Station :
              <kendo-combobox *ngIf="!selectedId" class="form-control" [data]="data_sta" [allowCustom]="allowCustom"
                [suggest]="true" [textField]="'name'" [valueField]="'id'" [value]="sta"
                (filterChange)="handleFilter($event)" [filterable]="true" (valueChange)="handleValue($event)"
                [placeholder]="'ทั้งหมด'">
              </kendo-combobox>
              <kendo-combobox *ngIf="selectedId" class="form-control" [data]="data_sta" [allowCustom]="allowCustom"
                [suggest]="true" [textField]="'survey'" [valueField]="'id'" [value]="sta" disabled
                (filterChange)="handleFilter($event)" [filterable]="true" (valueChange)="handleValue($event)"
                [placeholder]="'ทั้งหมด'">
              </kendo-combobox>
              <!-- <ng-select class="w-100 speed-measure select-dash-sensor" placeholder="เลือกตำแหน่ง" [options]="stationList"
                [multiple]="false" [(ngModel)]="selected" [allowClear]="true" (selected)="onSelected($event)"
                (deselected)="onDeSelected($event)">
              </ng-select> -->
            </div>
            <div class="col-4">
              วัน/เวลา เริ่มต้น
              <kendo-datetimepicker [(ngModel)]="st_dt" class="w-100" [format]="'dd-MMM-yyyy HH:mm:ss'">
              </kendo-datetimepicker>
            </div>
            <div class="col-4">
              วัน/เวลา สิ้นสุด
              <kendo-datetimepicker [(ngModel)]="en_dt" class="w-100" [format]="'dd-MMM-yyyy HH:mm:ss'">
              </kendo-datetimepicker>
            </div>
            <div class="col-4 mt-3" style="line-height: 2;">
              ช่องจราจร
              <div *ngIf="!selectedId">
                <input type="checkbox" [(ngModel)]='laneChecked[1]' name="lane" id="l1"><label for="l1"
                  class="ml-1 mr-2 mr-1">1</label>
                <input type="checkbox" [(ngModel)]='laneChecked[2]' name="lane" id="l2"><label for="l2"
                  class="ml-1 mr-2 mr-1">2</label>
                <input type="checkbox" [(ngModel)]='laneChecked[3]' name="lane" id="l3"><label for="l3"
                  class="ml-1 mr-2 mr-1">3</label>
                <input type="checkbox" [(ngModel)]='laneChecked[4]' name="lane" id="l4"><label for="l4"
                  class="ml-1 mr-2 mr-1">4</label>
                <input type="checkbox" [(ngModel)]='laneChecked[5]' name="lane" id="l5"><label for="l5"
                  class="ml-1 mr-2 mr-1">5</label>
                <input type="checkbox" [(ngModel)]='laneChecked[6]' name="lane" id="l6"><label for="l6"
                  class="ml-1 mr-2 mr-1">6</label>
                <input type="checkbox" [(ngModel)]='laneChecked[7]' name="lane" id="l7"><label for="l7"
                  class="ml-1 mr-2 mr-1">7</label>
                <input type="checkbox" [(ngModel)]='laneChecked[8]' name="lane" id="l8"><label for="l8"
                  class="ml-1 mr-2 mr-1">8</label>
              </div>
              <div *ngIf="selectedId" class="d-flex align-items-baseline">
                <p class="m-0" style="width: 100px;">IN</p>
                <label *ngFor="let i of nums_lane" class="ml-1 mr-2 mr-1">
                  <input type="checkbox" [(ngModel)]='laneChecked_IN[(i+1)]' name="lane" [id]="l_in+(i+1)">
                  {{i+1}}
                </label>
              </div>
              <div *ngIf="selectedId" class="d-flex align-items-baseline">
                <p class="m-0" style="width: 100px;">OUT</p>
                <label *ngFor="let i of nums_lane" class="ml-1 mr-2 mr-1">
                  <input type="checkbox" [(ngModel)]='laneChecked_OUT[(i+1)]' name="lane" [id]="l_out+(i+1)">
                  {{i+1}}
                </label>
              </div>
            </div>
            <div class="col-4 mt-3" style="line-height: 2;">
              ประเภทรถ
              <!-- <div *ngFor="let i of classList; let idx = index;"> -->
              <label *ngFor="let i of classList; let idx = index;" [htmlFor]="i.veh_class_name+i.veh_class_id"
                class="ml-1 mr-1">
                <input type="checkbox" [id]="i.veh_class_name+i.veh_class_id" name="classs" [(ngModel)]="i.checked"
                  [value]="i.veh_class_name" (change)="changeClass()">
                {{i.veh_class_name}}
              </label>
              <!-- </div> -->
            </div>
            <div class="col-4 mt-3 text-right d-flex align-items-end justify-content-end">
              <button type="button" class="btn btn-warning mr-2" style="min-width: 80px;" (click)="exportCleanLog()"
                [disabled]="disCSV || !values || (values && noData)">
                <div *ngIf="disCSV && values && !noData">Loading...</div>
                <p *ngIf="!disCSV || !values || noData" class="m-0">Export CSV</p>
              </button>
              <button type="button" class="btn btn-primary" style="min-width: 80px;" (click)="search()"
                [disabled]="disedSea || !sta || !st_dt || !en_dt">ค้นหา</button>
            </div>
          </div>

        </div>
      </div>


      <div *ngIf="!values && !noData" class="card loading">
        <div class="card-body bg-gray-900">
          <!-- <div class="overlay-loader">
            <div class="loader">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div> -->
          <div class="overlay-loader d-flex align-items-center justify-content-center">
            <div class="lds-dual-ring"></div>
          </div>
        </div>
      </div>
      <div *ngIf="values && noData" class="card loading">
        <div class="card-body bg-gray-900 text-center">
          <H3>No data</H3>
        </div>
      </div>
      <div *ngIf="values && !noData" class="card">
        <div class="card-header">
          <div class="container">
            <div class="row">
              <div class="col-1">&emsp;</div>
              <div class="col-4 body-txt">
                <p>Min <span>{{values.stat.min||0}}</span></p>
                <p>Max <span>{{values.stat.max||''}}</span></p>
                <p>Mean <span>{{values.stat.mean||''}}</span></p>
                <p>Standard Deviation
                  <span>{{values.stat.std||''}}</span></p>
              </div>
              <div class="col-2">&emsp;</div>
              <div class="col-4 body-txt">
                <p>15th Percentile <span>{{values.stat.per15||''}}</span></p>
                <p>Quartile 1 <span>{{values.stat.q1||''}}</span></p>
                <p>Quartile 3<span>{{values.stat.q3||''}}</span></p>
                <p>85th Percentile
                  <span>{{values.stat.per85||''}}</span></p>
              </div>
              <div class="col-1">&emsp;</div>
            </div>
          </div>
          <div class="card-body bg-gray-900">
            <div class="row">
              <!-- Frequercy/Speed(Km/h) -->
              <div class="col-6">
                <kendo-chart>
                  <kendo-chart-title text="Frequercy/Speed(Km/h)"></kendo-chart-title>
                  <kendo-chart-value-axis>
                    <kendo-chart-value-axis-item [title]="{ text: 'Frequercy' }" [min]="feq_min" [max]="feq_max">
                    </kendo-chart-value-axis-item>
                  </kendo-chart-value-axis>
                  <kendo-chart-category-axis>
                    <kendo-chart-category-axis-item [categories]="feqLabelItem"
                      [labels]="{format: 'd', rotation: '-45' }" [title]="{ text: 'Speed(Km/h)' }">
                    </kendo-chart-category-axis-item>
                  </kendo-chart-category-axis>
                  <kendo-chart-tooltip format="{0}"></kendo-chart-tooltip>
                  <kendo-chart-series>
                    <kendo-chart-series-item [data]="feqData">
                    </kendo-chart-series-item>
                  </kendo-chart-series>
                  <kendo-chart-tooltip [visible]="true" color="#000" format="{0:N0}"></kendo-chart-tooltip>
                </kendo-chart>
              </div>
              <!-- Cumulative(%)/Speed(Km/h) -->
              <div class="col-6" style="border-left: 1px solid #515b65;">
                <kendo-chart [categoryAxis]="{ categories: categories }">
                  <kendo-chart-title text="Cumulative(%)/Speed(Km/h)"></kendo-chart-title>
                  <kendo-chart-value-axis>
                    <kendo-chart-value-axis-item [title]="{ text: 'Cumulative(%)' }" [min]="0" [max]="105">
                    </kendo-chart-value-axis-item>
                  </kendo-chart-value-axis>
                  <kendo-chart-category-axis>
                    <kendo-chart-category-axis-item [categories]="cumulLabelItem"
                      [labels]="{format: 'd', rotation: '-45' }" [title]="{ text: 'Speed(Km/h)' }">
                    </kendo-chart-category-axis-item>
                  </kendo-chart-category-axis>
                  <kendo-chart-legend position="bottom" orientation="horizontal"></kendo-chart-legend>

                  <kendo-chart-tooltip format="{0}%">
                  </kendo-chart-tooltip>
                  <kendo-chart-series>
                    <kendo-chart-series-item type="line" [data]="cumulData">
                    </kendo-chart-series-item>
                    <!-- <kendo-chart-series-item *ngFor="let item of series"
                        type="line"  [data]="item.data" [name]="item.name">
                    </kendo-chart-series-item> -->
                  </kendo-chart-series>

                  <kendo-chart-y-axis>
                    <kendo-chart-y-axis-item [title]="{ text: 'Charge' }" [labels]="{ format: '{0}%' }" [max]="100">
                    </kendo-chart-y-axis-item>
                  </kendo-chart-y-axis>
                  <kendo-chart-tooltip [visible]="true" color="#000" format="{0:N0}"></kendo-chart-tooltip>
                </kendo-chart>
              </div>
              <!-- col-6 End -->
              <!-- <div class="col-6" style="border-left: 1px solid #515b65;">

                <kendo-chart style="z-index: 5;">
                  <kendo-chart-title text="Cumulative(%)/Speed(Km/h)"></kendo-chart-title>
                  <kendo-chart-value-axis>
                    <kendo-chart-value-axis-item [title]="{ text: 'Cumulative(%)' }" [min]="0" [max]="100" pane="pane">
                    </kendo-chart-value-axis-item>
                  </kendo-chart-value-axis>
                  <kendo-chart-panes>
                    <kendo-chart-pane name="pane" clip="true">
                    </kendo-chart-pane>
                  </kendo-chart-panes>
                  <kendo-chart-series>
                    <kendo-chart-series-item [data]="cumul" type="line">
                    </kendo-chart-series-item>

                  <kendo-chart-tooltip format="{0}%">
                  </kendo-chart-tooltip>
                  </kendo-chart-series>

                  <kendo-chart-category-axis>
                    <kendo-chart-category-axis-item [categories]="categories">
                    </kendo-chart-category-axis-item>
                  </kendo-chart-category-axis>
                </kendo-chart>
              </div> -->
              <!-- col-6 End -->
            </div>
          </div>
        </div>
      </div>
      <!-- col-12 End -->
    </div>
    <!-- row End -->

  </div>
  <!-- 1 End -->