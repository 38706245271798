import { Component, Input } from '@angular/core';
import { navItems } from './../../_nav';
import { Router,ActivatedRoute } from '@angular/router';
import { AuthService } from '../../services/auth/auth.service';
import { UserProfile } from '../../models/user';
import { Subscription } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { EventService } from 'src/app/services/user/event.service';
@Component({
    selector: 'app-dashboard',
    templateUrl: './default-layout.component.html'
})
export class DefaultLayoutComponent {
    //public menuItems = navItems;
    public menuItems = [];
    public sidebarMinimized = true;
    private changes: MutationObserver;
    public element: HTMLElement = document.body;

    public is_hidden_toggle_bt = true;
    private is_toggle_sidebar: boolean = true;

    user_info: any;

    currentUser: UserProfile;
    currentUserSubscription: Subscription;
    tt_evt : number = 0
    rain_evt : number = 0
    chkEvt : Subscription
    _menuForm : boolean = true
    constructor(
        private authService: AuthService, private router: Router,
        private titleService: Title,
        private evt_S: EventService,
        private route_pr: ActivatedRoute,
        ) {
            console.log(location.hash)
            if(location.hash.match(';iwz=true')){
                let chk = location.hash.split(';')
                if(chk[1]=='iwz=true') this._menuForm =false
            }
        // if(sessionStorage.iwz==='true'){
        //     this._menuForm =false
        // }

        this.changes = new MutationObserver((mutations) => {
            this.sidebarMinimized = document.body.classList.contains('sidebar-minimized');
            if (this.is_toggle_sidebar) {
                this.is_toggle_sidebar = false;
                //this.commonService.setToggleSidebar(false);
            } else {
                this.is_toggle_sidebar = true;
                //this.commonService.setToggleSidebar(true);
            }
        });

        this.changes.observe(<Element>this.element, {
            attributes: true
        });

        router.events.subscribe((val) => {
            //this.updateRouter();
        });
        this.menuItems = [];
        this.currentUserSubscription = this.authService.currentUser.subscribe(user => {
            this.currentUser = user;
            if (user) {
                if (user.permission.length > 0) {
                    navItems.forEach(item => {
                        const find = user.permission.find(x => x == item.id);
                        if (find)
                            this.menuItems.push(item);
                    });
                }
            }

        });

        this.getSecurity()
    }

    logout() {
        if (this.currentUser) {
            this.authService.logout().subscribe(
                data => {
                    if (data.code == 1) {
                        this.router.navigate(['login']);
                    } else {
                        // this.addAlert(data.message);
                        this.router.navigate(['login']);
                    }
                },
                error => {
                    // this.addAlert(error);
                    this.router.navigate(['login']);
                });
        } else {
            this.router.navigate(['login']);
        }
    }
    updateRouter() {
        const route = this.router.url;
    }

    clickToggle() {
        const route = this.router.url;
    }
    async getSecurity(){
        this.chkEvt = this.evt_S.getMessage().subscribe(msg=>{
            if (msg.chk) {this.getEvt();}
        })
        this.getEvt();
    }
    async getEvt(){
        let res : any = await this.evt_S.getEvtCount({
            status:1,group_id:2,src_type_id:7,event_type_id:5,src_group_id:2
        })
        if(res) this.tt_evt = res.total_items
        res = await this.evt_S.getEvtCount({
            status:1,group_id:3,src_type_id:9,event_type_id:9,src_group_id:2
        })
        if(res) this.rain_evt = res.total_items
    }
    ngOnDestroy() {
        // unsubscribe to ensure no memory leaks
        this.chkEvt.unsubscribe();
    }
}
