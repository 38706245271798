import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import * as _ from 'lodash';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReportService {
  
  private subject = new Subject<any>();

  constructor(
    private api : ApiService,
  ) { }
  async getStation(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.getReportStation(req).subscribe((res:any)=>{
        if(res && res.code == 1){
          return reslove(res.data);
        }else{
          return reslove(false);
        }
      })
    })
  }
  async getSensorVolume(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.getReportSensorVolume(req).subscribe((res:any)=>{
        if(res && res.code == 1){
          return reslove(res.data);
        }else{
          return reslove(false);
        }
      })
    })
  }
  async getSensorStat(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.getreportSensorStat(req).subscribe((res:any)=>{
        if(res && res.code == 1){
          return reslove(res.data);
        }else{
          return reslove(false);
        }
      })
    })
  }
  async getSensorTimeline(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.getreportSensorTimeline(req).subscribe((res:any)=>{
        if(res && res.code == 1){
          return reslove(res.data);
        }else{
          return reslove(false);
        }
      })
    })
  }
  async getVehClass(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.getVehClass(req).subscribe((res:any)=>{
        if(res && res.code == 1){
          return reslove(res.data);
        }else{
          return reslove([]);
        }
      })
    })
  }
  async getSensorMeasurement(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.getSensorMeasurement(req).subscribe((res:any)=>{
        if(res && res.code == 1){
          return reslove(res.data);
        }else{
          return reslove([]);
        }
      })
    })
  }
  
  async getPerStation(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.getPerStation(req).subscribe((res:any)=>{
        if(res && res.code == 1){
          return reslove(res.data);
        }else{
          return reslove(false);
        }
      })
    })
  }
  async addPerStation(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.addPerStation(req).subscribe((res:any)=>{
        if(res.code == 1){
          this.subject.next({ chk: true });
          return reslove(true);
        }else{
          return reslove(false);
        }
      })
    })
  }
  async rmPerStation(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.rmPerStation(req).subscribe((res:any)=>{
        if(res.code == 1){
          this.subject.next({ chk: true });
          return reslove(true);
        }else{
          return reslove(false);
        }
      })
    })
  }
  async getPerCarlendar(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.getPerCarlendar(req).subscribe((res:any)=>{
        if(res.code == 1){
          this.subject.next({ chk: true });
          return reslove(res.data);
        }else{
          return reslove(false);
        }
      })
    })
  }
  async addPerCarlendar(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.addPerCarlendar(req).subscribe((res:any)=>{
        if(res.code == 1){
          this.subject.next({ chk: true });
          return reslove(true);
        }else{
          return reslove(false);
        }
      })
    })
  }
  async rmPerCarlendar(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.rmPerCarlendar(req).subscribe((res:any)=>{
        if(res.code == 1){
          this.subject.next({ chk: true });
          return reslove(true);
        }else{
          return reslove(false);
        }
      })
    })
  }
  
  async getMaTicketlog(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.getMaTicketlog(req).subscribe((res:any)=>{
        if(res && res.code == 1){
          return reslove(res.data);
        }else{
          return reslove(false);
        }
      })
    })
  }
  async getMaTicketlog_export(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.getMaTicketlog_export(req).subscribe((res:any)=>{
        console.log(res)
        // if(res) window.open(res)
        if(res && res.code == 1){
          return reslove(res.data);
        }else{
          return reslove(false);
        }
      })
    })
  }
}
