import { Component, OnInit, OnDestroy, HostListener, ViewChild } from '@angular/core';
import { Subscription, interval } from 'rxjs';
import * as _ from 'lodash';
import { CommonService } from 'src/app/services/common.service';
import * as mapboxgl from 'mapbox-gl';
import { TrafficService } from '../../../services/dashboard/traffic.service';
import { ActionEnum, RouteDirectionEnum, StatusEnum } from '../../../models/common-enum';
import { TrafficInfo, HistorySpeed } from 'src/app/models/dashboard/traffic';
import { GeoJsonPoint } from '../../../models/geojson';
import { environment } from 'src/environments/environment';
import { MediaType, SignDirection } from 'src/app/models/sign-control/enum';
import { BoardsignService } from 'src/app/services/sign-control/boardsign.service';
import { BoardsignInfo, TrafficInfoLayerView } from 'src/app/models/sign-control/boardsign';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-traffic-info',
  templateUrl: './traffic-info.component.html',
  styleUrls: ['./traffic-info.component.scss']
})
export class TrafficInfoComponent implements OnInit, OnDestroy {
  @ViewChild('boardsingInfoModal', { static: false }) public boardsingInfoModal: ModalDirective;
  subscription: any;
  sub_toggle: Subscription;
  is_toggle: boolean;
  map: mapboxgl.Map;
  km_layer: any = 'kmlisttlayer';
  kmListFeatures: any[] = [];

  incident_layer: any = 'Incidentlayer';
  incidentsListFeatures: any[] = [];
  // incStatusEnum = IncStatusEnum;
  // incSrcTypeEnum = IncSrcTypeEnum;
  // incTypeIconEnum = IncTypeIconEnum;
  route_list: any[] = [];
  signDirection = SignDirection;
  mediaType = MediaType;
  height_plus: any = 0;
  min : number
  max : number
  per_road = JSON.parse(localStorage.permission_road)

  search_header_height = 50;

  public data_route: Array<string> = [];
  allowCustom = true;
  constructor(private commonService: CommonService,
    private trafficService: TrafficService,
    private boardsignService: BoardsignService) {
    mapboxgl.accessToken = environment.mapbox.accessToken;
    this.sub_toggle = this.commonService.getToggle().subscribe(res => {
      if (res.is_toggle_traffic_info != null) {
        this.is_toggle = res.is_toggle_traffic_info;
        if (this.is_toggle) {
          for (let i = 0; i < 100; i++) {
            setTimeout(() => this.map.resize(), 50);
          }
        } else {
          setTimeout(() => this.map.resize(), 400);
        }
      }
    });
    this.commonService.activityLog(ActionEnum.Get, 'Dashboard ➡ Corridor Control').subscribe(res => { }, error => { console.log(error); });
  }

  async ngOnInit() {

    let route_data = await this.trafficService.getData(this.per_road).toPromise();
    if (route_data) {
      this.route = _.cloneDeep(route_data.route);
      this.st_km = _.cloneDeep(route_data.st_km);
      this.en_km = _.cloneDeep(route_data.en_km);
      this.min = this.st_km
      this.max = this.en_km
    }
    await this.getRoutes();
    await this.search();
    this.initMap();
    this.subscription = interval(60000).subscribe((x) => {
      this.search();
    });

  }
  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.sub_toggle.unsubscribe();
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  async getRoutes() {
    console.log(this.per_road)
    let arr_route = []
    for (const i of this.per_road) {
      arr_route.push(i.route)
    }
    this.route_list = _.cloneDeep(arr_route);
    this.data_route = _.cloneDeep(arr_route);
    // this.commonService.getRoute().subscribe(
    //   res => {
    //     if (res) {
    //       if (res.code == 1) {
    //         if (res.data) {
    //           this.route_list = _.cloneDeep(res.data);
    //           this.data_route = _.cloneDeep(res.data);
    //         }
    //       }
    //     }
    //     this.is_loading = false;
    //   },
    //   error => {
    //     this.is_loading = false;
    //   });
  }

  handleFilterRoute(value) {
    if (value.length > 0) {
      this.data_route = this.route_list.filter((s) => s.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else if (value.length == 0) {
      this.data_route = _.cloneDeep(this.route_list);
    } else {
      this.data_route = [];
    }
  }
  handleValueRoute(value) {
    console.log(value)
    let r = this.per_road.filter((v)=>v.route==value)
    console.log(value,r)
    if (value) {
      this.route = value;
    } else {
      this.route = null;
    }
  }
  chkKm(vals:number){
    let r = Number(vals)
    console.log(r,vals)
    return r
  }
  initMap() {
    this.map = new mapboxgl.Map({
      container: 'map-traffic-info',
      style: environment.mapbox.style,
      zoom: 8,
      center: [100.5443823, 14.138986]
    });
    this.map.dragRotate.disable();
    this.map.on('load', (e: mapboxgl.MapEvent) => {
      this.map.setPaintProperty('water', 'fill-color', '#aad3df');

      // this.map.loadImage('assets/img/map/marker/inc/pin-incident-red-55x55.png', (error, image) => {
      //   if (error) { throw error };
      //   this.map.addImage('incident0', image);
      // });
      // this.map.loadImage('assets/img/map/marker/inc/pin-incident-blue-55x55.png', (error, image) => {
      //   if (error) { throw error };
      //   this.map.addImage('incident1', image);
      // });
      // this.map.loadImage('assets/img/map/marker/inc/pin-incident-orange-55x55.png', (error, image) => {
      //   if (error) { throw error };
      //   this.map.addImage('incident2', image);
      // });
      // this.map.loadImage('assets/img/map/marker/inc/pin-incident-gray-55x55.png', (error, image) => {
      //   if (error) { throw error };
      //   this.map.addImage('incident3', image);
      // });
      // this.map.loadImage('assets/img/map/marker/inc/pin-incident-green-55x55.png', (error, image) => {
      //   if (error) { throw error };
      //   this.map.addImage('incident4', image);
      // });

      // this.map.addSource('incident', {
      //   "type": 'geojson', "data": {
      //     "type": "FeatureCollection",
      //     "features": this.incidentsListFeatures
      //   }
      // });
      this.map.addSource('kmlocation', {
        "type": 'geojson', "data": {
          "type": "FeatureCollection",
          "features": this.kmListFeatures
        }
      });
      // this.map.addLayer({
      //   id: this.incident_layer,
      //   type: 'symbol',
      //   source: 'incident',
      //   layout: {
      //     'icon-image': {
      //       property: 'status',
      //       stops: [
      //         [this.incStatusEnum.Created, 'incident0'],
      //         [this.incStatusEnum.Approved, 'incident1'],
      //         [this.incStatusEnum.Operated, 'incident2'],
      //         [this.incStatusEnum.Rejected, 'incident3'],
      //         [this.incStatusEnum.Clear, 'incident4']
      //       ]
      //     },
      //     "icon-size": 0.8,
      //     "icon-offset": [0, -20],
      //     'icon-allow-overlap': true,
      //   },
      //   "filter": ["==", "$type", "Point"]
      // });
      this.map.addLayer({
        "id": this.km_layer,
        "type": "circle",
        "source": "kmlocation",
        "paint": {
          "circle-radius": 26,
          "circle-color": "gray",
          "circle-opacity": 0.3
        }
      });
      this.map.addLayer({
        "id": "circle-1",
        "type": "circle",
        "source": "kmlocation",
        "paint": {
          "circle-radius": 18,
          "circle-color": "#636161",
          "circle-opacity": 0.8
        }
      });

      this.map.addLayer({
        "id": "dronee",
        "type": "symbol",
        "source": "kmlocation",
        "interactive": false,
        "layout": {
          "icon-size": 1,
          "icon-offset": [0, 0],
          "icon-keep-upright": true,
          "text-field": "{km}",

          "text-offset": [0, 0],
          "text-anchor": "center",
          "text-size": 14,
          "text-allow-overlap": false,
          "text-ignore-placement": false,
          "icon-ignore-placement": false,
          "text-optional": true,
          "icon-optional": true,
          "text-justify": "center",
          "icon-allow-overlap": false,
          "visibility": "visible",
        },
        "paint": {
          "text-color": "#fff"
        }
      });
      const popup3 = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false, anchor: 'bottom', offset: [0, -40]
      });
      this.map.on('click', this.incident_layer, (event: mapboxgl.MapEvent) => {
        console.log(event.features[0].properties);

      });
      this.map.on('mouseenter', this.incident_layer, (event: mapboxgl.MapEvent) => {
        let km1: any;
        let m1: any;
        let route1: any;
        const rrr = JSON.parse(event.features[0].properties.routes);
        if (rrr.length > 0) {
          route1 = rrr[0].loc0.route;
          km1 = rrr[0].loc0.km;
          let str = rrr[0].loc0.m + '';
          const pad = '000';
          const ans = pad.substring(0, pad.length - str.length) + str;
          m1 = ans;
        }
        this.map.getCanvas().style.cursor = 'pointer';
        popup3.setLngLat(event.features[0].geometry.coordinates)
          .setHTML(`<div style="padding: 5px 5px 5px;">
          <div> ${event.features[0].properties.name}</div>
          <div> ${route1} (${km1} + ${m1}) <div></div>`)
          .addTo(this.map);
      });
      this.map.on('mouseleave', this.incident_layer, (event: mapboxgl.MapEvent) => {
        this.map.getCanvas().style.cursor = '';
        popup3.remove();
      });
      this.map.on('mouseenter', this.km_layer, (event: mapboxgl.MapEvent) => {
        this.map.getCanvas().style.cursor = 'pointer';
      });
      this.map.on('mouseleave', this.km_layer, (event: mapboxgl.MapEvent) => {
        this.map.getCanvas().style.cursor = '';
      });
      this.map.on('click', this.km_layer, (event: mapboxgl.MapEvent) => {
        this.clearMapkm();
      });
    });
  }

  clickMapkm(e) {
    if (e) {
      //console.log(e);
      this.kmListFeatures = [];
      const geometry = e.all_lane.location;
      let coor = geometry.coordinates;
      const properties = e.all_lane.properties;
      const feature = { type: 'Feature', geometry: geometry, properties: properties };
      this.kmListFeatures.push(feature);
      if (this.map.getSource('kmlocation') != null) {
        this.map.getSource('kmlocation').setData({
          'type': 'FeatureCollection',
          'features': this.kmListFeatures
        });
      }
      this.map.flyTo({ center: coor, zoom: 11 });
    }
  }

  clearMapkm() {
    this.kmListFeatures = [];
    if (this.map.getSource('kmlocation') != null) {
      this.map.getSource('kmlocation').setData({
        'type': 'FeatureCollection',
        'features': this.kmListFeatures
      });
    }
  }

  //---------------------------------Traffic--------------------------------------//

  st_km: number;
  en_km: number;
  route: string;

  search_st_km: number;
  search_en_km: number;
  search_route: string;

  lane_height: number = 21;
  sign_height: number = 20;
  center_km_height: number = 35;
  km_point_height: number = 20;
  max_lane: number = 6;
  km_width_px = 80;
  road_list: any[] = [];
  road_layer_height: number;

  setRoad() {
    this.road_list = [];
    this.road_layer_height = this.center_km_height + (this.km_point_height * 2) + (this.lane_height * this.max_lane * 2);
    for (let i = (this.search_st_km - 1); i <= this.search_en_km; i++) {
      let lanes = [];
      for (let j = 1; j <= this.max_lane; j++) {
        lanes.push(j);
      }
      const item = { lanes: lanes, km: i, inc_in: false, inc_out: false };
      this.road_list.push(item);
    }
  }

  //---------------------------------Graph--------------------------------------//
  dir_tab_height: any = 40;
  speed_time_margin: any = 15;
  speed_time_height: any = 21;
  current_speed: any;
  predict_speed: HistorySpeed[] = [];
  toggle_dir: any = 1;
  speed: any[] = [0, 15, 30, 45];

  traffic_in = [];
  traffic_out = [];
  isLoading: any = false;

  async getTraffic(click?: number) {
    await this.trafficService.getTraffic(this.search_route, this.search_st_km, this.search_en_km, click).subscribe(
      res => {
        if (res.code === 1) {
          if (res.data) {
            this.current_speed = res.data.current_speed;
            this.predict_speed = res.data.predict_speed;
            this.traffic_out = [];
            this.traffic_in = [];
            if (this.current_speed) {
              this.isLoading = true;
              if (this.current_speed.data_out) {
                this.current_speed.data_out.forEach(item => {
                  let by_lane = [];
                  for (let i = 1; i <= this.max_lane; i++) {
                    let spd_obj = {};
                    if (item.speed != null) {
                      spd_obj = { lane: i, lane_type: 1, speed: item.speed, color: item.color };
                    } else {
                      spd_obj = { lane: i, lane_type: 1 };
                    }
                    by_lane.push(spd_obj);
                  }
                  let all_lane = { lane: 0, lane_type: 1, speed: item.speed, properties: item.properties, location: item.location, color: item.color };
                  let obj = { route: this.route, km: item.km, dir: 2, all_lane: all_lane, by_lane: by_lane };
                  this.traffic_out.push(obj);
                });
              }
              if (this.current_speed.data_in) {
                this.current_speed.data_in.forEach(item => {
                  let by_lane = [];
                  for (let i = 1; i <= this.max_lane; i++) {
                    let spd_obj = {};
                    if (item.speed != null) {
                      spd_obj = { lane: i, lane_type: 1, speed: item.speed, color: item.color };
                    } else {
                      spd_obj = { lane: i, lane_type: 1 };
                    }
                    by_lane.push(spd_obj);
                  }
                  let all_lane = { lane: 0, lane_type: 1, speed: item.speed, properties: item.properties, location: item.location, color: item.color };
                  let obj = { route: this.route, km: item.km, dir: 1, all_lane: all_lane, by_lane: by_lane };
                  this.traffic_in.push(obj);
                });
              }
              this.isLoading = false;
            }

          }
        }
        this.winResize();
      },
      err => {
        console.log(err);
        this.winResize();
      });

  }

  async search(click?: number) {

    this.search_route = _.cloneDeep(this.route);
    this.search_st_km = _.cloneDeep(+this.st_km);
    this.search_en_km = _.cloneDeep(+this.en_km);
    this.trafficService.saveData(this.search_route, this.search_st_km, this.search_en_km);
    await this.getBoardsign(this.search_route, this.search_st_km, this.search_en_km, click);
    await this.setRoad();
    await this.getTraffic(click);
    await this.getIncident(click);
  }

  km_count = 40;
  clickRight() {
    let e_km = this.search_en_km + this.km_count;

    const count = this.search_en_km - this.search_st_km;
    let s_km = e_km - count;
    this.search_route = _.cloneDeep(this.search_route);
    this.search_st_km = _.cloneDeep(s_km);
    this.search_en_km = _.cloneDeep(e_km);
    this.route = _.cloneDeep(this.search_route);
    this.st_km = _.cloneDeep(this.search_st_km);
    this.en_km = _.cloneDeep(this.search_en_km);
    this.search(1);
  }

  clickLeft() {
    if (this.search_st_km > 0) {
      let s_km = this.search_st_km - this.km_count;
      if (s_km < 0) {
        s_km = 0;
      }
      const count = this.search_en_km - this.search_st_km;
      let e_km = s_km + count;
      this.search_route = _.cloneDeep(this.search_route);
      this.search_st_km = _.cloneDeep(s_km);
      this.search_en_km = _.cloneDeep(e_km);
      this.route = _.cloneDeep(this.search_route);
      this.st_km = _.cloneDeep(this.search_st_km);
      this.en_km = _.cloneDeep(this.search_en_km);
      this.search(1);
    }

  }

  toggleDir(dir: any) {
    if (this.toggle_dir != dir) {
      this.toggle_dir = dir;
    }
  }

  openGoogleMap() {
    if (this.current_speed) {
      let d = +((this.current_speed.data_in.length) / 2);
      let ii = parseInt(d.toString(), 10);
      const location = this.current_speed.data_in[ii];
      const lat = location.location.coordinates[1];
      const lng = location.location.coordinates[0];
      //console.log(ii, lat, lng);
      let zoom = 15;
      let lll = this.search_en_km - this.search_st_km;
      if (lll < 100) {
        zoom = 14;
      } else if (lll < 200) {
        zoom = 13;
      } else if (lll < 400) {
        zoom = 11;
      } else {
        zoom = 10;
      }
      window.open(`https://www.google.co.th/maps/@${lat},${lng},${zoom}z/data=!5m1!1e1?hl=th`);
    } else {
      window.open(`https://www.google.co.th/maps/@13.7500305,100.4989885,13.81z/data=!5m1!1e1`);
    }

  }
  hoverKm(i: number, item: any) {
    if (i > 0) {
      if (item) {
        let km = 0;
        km = item.km;
        const data = this.traffic_out.find(x => x.km == km);
        if (data) {
          this.kmListFeatures = [];
          const geometry = data.all_lane.location;
          let coor = geometry.coordinates;
          const properties = data.all_lane.properties;
          const feature = { type: 'Feature', geometry: geometry, properties: properties };
          this.kmListFeatures.push(feature);
          if (this.map.getSource('kmlocation') != null) {
            this.map.getSource('kmlocation').setData({
              'type': 'FeatureCollection',
              'features': this.kmListFeatures
            });
          }
          this.map.flyTo({ center: coor, zoom: 11 });

        }
      }
    }
  }
  //------------------------------INCIDENT-----------------------------//

  is_loading: boolean = false;
  //incidents: Inc[] = [];
  incidents: any[] = [];
  getIncident(click?: number) {

    let datenow = new Date();
    datenow.setDate(datenow.getDate() - 7);
    const date_now = new Date();
    const st_datetime = new Date(datenow.getFullYear(), datenow.getMonth(), datenow.getDate(), 0, 0);
    const en_datetime = new Date(date_now.getFullYear(), date_now.getMonth(), date_now.getDate(), 23, 59, 59);
    const st_stamp_ms = Math.round(st_datetime.getTime());
    const en_stamp_ms = Math.round(en_datetime.getTime());
    let ts_ms_range = `${st_stamp_ms},${en_stamp_ms}`;

    this.incidents = [];
    // this.incidentService.getIncidents(null, null, null, null, null, null, 'ts,-1', this.search_route, null, ts_ms_range, null, click).subscribe(res => {
    //   if (res.code == 1) {
    //     this.incidents = res.data.data;

    //     this.road_list.forEach(item => {
    //       let find = this.incidents.find(x => x.routes[0].loc0.km == item.km);
    //       if (find) {
    //         if (find.routes[0].loc0.dir_in && find.routes[0].loc0.dir_out) {
    //           item.inc_in = true;
    //           item.inc_out = true;
    //         } else if (find.routes[0].loc0.dir_in) {
    //           item.inc_in = true;
    //           item.inc_out = false;
    //         } else if (find.routes[0].loc0.dir_out) {
    //           item.inc_out = true;
    //           item.inc_in = false;
    //         } else {
    //           item.inc_in = true;
    //           item.inc_out = true;
    //         }
    //       } else {
    //         item.inc_in = false;
    //         item.inc_out = false;
    //       }
    //     });
    //     this.incidentsListFeatures = [];
    //     let coordinates = null;
    //     res.data.data.forEach(element => {
    //       if (element.routes[0].loc0.km >= this.search_st_km && element.routes[0].loc0.km <= this.search_en_km) {
    //         if (element.routes[0].loc0.lat && element.routes[0].loc0.lng) {
    //           coordinates = [element.routes[0].loc0.lng, element.routes[0].loc0.lat];
    //         } else {
    //           if (element.routes[0].loc0.coor) {
    //             coordinates = element.routes[0].loc0.coor.coordinates;
    //           }
    //         }
    //         if (coordinates) {
    //           const geometry = { type: 'Point', coordinates: coordinates };
    //           const feature = { type: 'Feature', geometry: geometry, properties: element };
    //           this.incidentsListFeatures.push(feature);
    //         }
    //       }
    //     });

    //     setTimeout(() => {
    //       if (this.map.getSource('incident') != null) {
    //         this.map.getSource('incident').setData({
    //           'type': 'FeatureCollection',
    //           'features': this.incidentsListFeatures
    //         });
    //       }
    //     }, 1000);

    //   }
    //   this.is_loading = false;
    // },
    //   err => {
    //     if (err.statusText) {
    //       console.log(err.statusText);
    //     }
    //     this.is_loading = false;
    //   });
  }

  //----------------------------Incident information Panel---------------------------------//
  //point_data: KmPoint;
  point_data: any;
  context_data: any;

  inc_toggle: boolean;
  inc_action_type: any; // 1 = info , 2 = Add, 3 = Edit
  inc_tab_id: any; // 1 = Information, 2 = TimeLine, 3 = LMS
  inc_select_item: any;
  inc_select_item_route: any;
  inc_select_item_list: any[] = [];
  is_inc_list_layer: boolean = false;
  inc_list_layer_x: number;
  inc_list_layer_y: number;
  directionEnum = RouteDirectionEnum;
  inc_click_dir: number;
  openIncPanel(item?: any) {
    this.inc_select_item = item;
    this.is_inc_list_layer = false;
    this.inc_toggle = true;
    this.inc_action_type = 1;
    this.inc_tab_id = 1;
  }

  clickIncKmList(e?: any, item?: any, dir?: number) {
    this.incidents
    this.inc_list_layer_y = e.clientY - 105;
    this.inc_list_layer_x = ((item.km - this.search_st_km) + 1) * this.km_width_px + (this.km_width_px / 2) + 10;
    if (this.inc_list_layer_y < 0) { this.inc_list_layer_y = 0; }
    if (this.inc_list_layer_x < 0) { this.inc_list_layer_x = 0; }
    if (this.inc_list_layer_x > ((this.search_en_km - this.search_st_km + 2) * this.km_width_px) - 370) { this.inc_list_layer_x = ((this.search_en_km - this.search_st_km + 2) * this.km_width_px) - 370; }
    this.is_inc_list_layer = true;

    if (dir) {
      this.inc_click_dir = dir;
    }
    let find = this.incidents.find(x => x.routes[0].loc0.km == item.km);
    if (find) {
      this.inc_select_item_route = find;
      if (find.routes[0].loc0.dir_in && find.routes[0].loc0.dir_out) {
        this.inc_select_item_list = this.incidents.filter(x => x.routes[0].loc0.km == item.km);
      } else if (find.routes[0].loc0.dir_in) {
        if (dir == this.directionEnum.IN) {
          this.inc_select_item_list = this.incidents.filter(x => x.routes[0].loc0.km == item.km && x.routes[0].loc0.dir_in);
        }
      } else if (find.routes[0].loc0.dir_out) {
        if (dir == this.directionEnum.OUT) {
          this.inc_select_item_list = this.incidents.filter(x => x.routes[0].loc0.km == item.km && x.routes[0].loc0.dir_out);
        }
      } else {
        this.inc_select_item_list = this.incidents.filter(x => x.routes[0].loc0.km == item.km);
      }
    } else {
      this.inc_select_item_list = [];
    }
  }
  clickCloseIncList() {
    this.is_inc_list_layer = false;
  }

  onMouseWheel(e) {
    if (this.is_inc_list_layer) {
      this.is_inc_list_layer = false;
    }
  }
  clickAddInc(type?: any, dir?: number, km?: number) {
    // this.is_inc_list_layer = false;
    // if (type == 1) {
    //   if (this.inc_select_item_route) {
    //     let data1 = new KmPoint();
    //     let prop = new KmPointInfo();
    //     let coor = new GeoJsonPoint();
    //     const p = this.inc_select_item_route.routes[0].loc0;
    //     prop.route = p.route;
    //     prop.km = p.km;
    //     prop.m = p.m;
    //     if (p.prov) { prop.prov = p.prov; }
    //     if (p.prov_code) { prop.prov_code = p.prov_code; }
    //     if (p.amp) { prop.amp = p.amp; }
    //     if (p.amp_code) { prop.amp_code = p.amp_code; }
    //     if (p.ctrl_name) { prop.ctrl_name = p.ctrl_name; }
    //     if (p.region_code) { prop.region_code = p.region_code; }
    //     if (p.lat && p.lat) {
    //       coor = p.coor.coordinates;
    //       data1.geometry = new GeoJsonPoint(p.lat, p.lat);
    //     } else {
    //       coor = p.coor.coordinates;
    //       data1.geometry = new GeoJsonPoint(coor[1], coor[0]);
    //     }
    //     if (dir) {
    //       prop.dir = dir;
    //     }
    //     data1.properties = prop;
    //     this.point_data = data1;
    //     this.inc_toggle = true;
    //     this.inc_action_type = 2;
    //     this.inc_tab_id = 1;
    //   }
    // } else {

    //   let point = new KmPoint();
    //   let geo = new GeoJsonPoint();
    //   let prop = new KmPointInfo();
    //   const str = this.search_route.toString();
    //   const pad = '0000';
    //   const r_text = pad.substring(0, pad.length - str.length) + str;
    //   let point_url = `${environment.inc_api}/km?style=1&route=${r_text}`;
    //   //console.log(point_url);
    //   this.incidentService.getDataUrl(point_url).subscribe(
    //     res => {
    //       if (res.features) {
    //         let find = res.features.find(x => x.properties.route == r_text && x.properties.km == km);
    //         if (find) {
    //           this.inc_toggle = true;
    //           this.inc_action_type = 2;
    //           this.inc_tab_id = 1;
    //           geo = find.geometry.coordinates;
    //           const p = find.properties;
    //           prop.route = p.route;
    //           prop.km = p.km;
    //           prop.m = p.m;
    //           if (p.prov) { prop.prov = p.prov; }
    //           if (p.prov_code) { prop.prov_code = p.prov_code; }
    //           if (p.amp) { prop.amp = p.amp; }
    //           if (p.amp_code) { prop.amp_code = p.amp_code; }
    //           if (p.ctrl_name) { prop.ctrl_name = p.ctrl_name; }
    //           if (p.region_code) { prop.region_code = p.region_code; }
    //           if (dir) {
    //             prop.dir = dir;
    //           }
    //           point.geometry = new GeoJsonPoint(geo[1], geo[0]);
    //           point.properties = prop;
    //           this.point_data = point;
    //         }
    //       }
    //     },
    //     err => {
    //       if (err.statusText) {
    //         console.log(err.statusText);
    //       }
    //     });
    // }
  }

  closeIncPanel(e) {
    this.inc_toggle = false;
    this.inc_action_type = null;
    this.inc_tab_id = null;
    this.getIncident();
  }

  //------------------------------SOCIALS-----------------------------//
  socials_panel_toggle: boolean = false;
  openSocialsPanel() {
    this.socials_panel_toggle = true;
  }
  closeSocialsPanel() {
    this.socials_panel_toggle = false;
  }


  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.winResize();
  }

  winResize() {
    const win_h = window.innerHeight;
    const road_h = this.road_layer_height + ((this.speed_time_height * this.predict_speed.length) + (this.speed_time_margin * 2)) + (this.speed_time_margin) + this.speed_time_margin + 168;
    if (win_h > road_h) {
      this.height_plus = ((win_h - road_h) / 2) - 10;
    }
  }

  //----------------------------LMS----------------------------//
  lmsList: BoardsignInfo[] = [];
  lms: BoardsignInfo;
  async getBoardsign(route: string, st?: number, en?: number, click?: number) {
    this.is_loading = true;
    let route1 = null;
    if (route) {
      const pad = '0000';
      const str_route = "" + (+route);
      route = pad.substring(0, pad.length - str_route.length) + str_route;
      route1 = [route];
    }
    //let res = await this.boardsignService.getBoardsign(null, null, click, route1, null, null, null, null, null, st, en).toPromise();
    let res = await this.boardsignService.getBoardsign(null, null, null, route, null, 1, st, en, null, null).toPromise().catch((err) => {
      console.log(err);
      this.is_loading = false;
    });
    this.lmsList = [];
    let res1: any;
    res1 = res;
    if (res) {


      if (res1.code == 1) {
        const list = res1.data.data.filter(x => x.status == 1 && x.station.route_id == route && x.station.km >= st && x.station.km <= en && x.signs.length > 0);
        this.lmsList = await this.genSignsLayerView(list, false);

        for (const i of this.lmsList) {
          i.setY = i.group_id == 1012 ? 74 : 0
        }
        if (this.lmsList.length > 0) {
          const item_max_lane = _.maxBy(this.lmsList, 'lane_count');
          if (item_max_lane) {
            this.max_lane = item_max_lane.lane_count;
          }
        }

      } else { }
    }
    this.is_loading = false;
  }
  get_sign_width(ratio: string, set_sign_height) {
    if (ratio) {
      const wh = ratio.split(':');
      const w = +wh[0];
      const h = +wh[1];
      const r = w / h;
      return set_sign_height * r;
    } else {
      return 0;
    }
  }
  //-------------------------------------------------------------------------------//
  async genSignsLayerView(boards?: BoardsignInfo[], is_suggest?: boolean) {
    boards.forEach(board => {

      board.signs = board.signs.filter(x => x.status == StatusEnum.Active);
      if (board.signs.length > 0 && board.station.boardsign_structure) {
        // layers
        //const layers_text = board.station.boardsign_structure.split("|");
        const layer_no_list = _.sortedUniq(board.signs.map(a => a.layer_no));
        let layers = [];
        let is_dir_back = false;
        const find_sign_back = board.signs.filter(x => x.direction == this.signDirection.Back);
        if (find_sign_back.length > 0)
          is_dir_back = true;

        let layers_f = [];
        let layers_b = [];
        layer_no_list.forEach((l, index) => {
          // signs
          let signs_f = [];
          let signs_b = [];
          let find_sign = board.signs.filter(x => x.layer_no == l);
          if (find_sign) {
            find_sign.forEach(s => {
              if (s.media) {
                let d = { id: s.id, sign_no: s.sign_no, sign_type_id: s.sign_type_id, ratio: s.sign_type_object.aspect_ratio, sign_type_object: s.sign_type_object, dir: s.direction, media: s.media };
                //let d = { id: s.id, sign_no: s.sign_no, board_type: s.board_type, ratio: s.board_type_obj.aspect_ratio, dir: s.direction, media: s.media };
                if (s.direction == this.signDirection.Front) {
                  signs_f.push(d);
                }
                if (s.direction == this.signDirection.Back) {
                  signs_b.push(d);
                }
              } else {
                let d = { id: s.id, sign_no: s.sign_no, sign_type_id: s.sign_type_id, ratio: s.sign_type_object.aspect_ratio, sign_type_object: s.sign_type_object, dir: s.direction, media: null };
                //let d = { id: s.id, sign_no: s.sign_no, board_type: s.board_type, ratio: s.board_type_obj.aspect_ratio, dir: s.direction, media: null };
                if (s.direction == this.signDirection.Front) {
                  signs_f.push(d);
                }
                if (s.direction == this.signDirection.Back) {
                  signs_b.push(d);
                }
              }
            });
            signs_f = _.sortBy(signs_f, ['sign_no']);
            signs_b = _.sortBy(signs_b, ['sign_no']);
            if (signs_f.length > 0) {
              layers_f.push(signs_f);
            }
            if (signs_b.length > 0) {
              layers_b.push(signs_b);
            }

          }

          let signs = [];
          if (find_sign) {
            find_sign.forEach(s => {
              if (s.media) {
                let d = { id: s.id, sign_no: s.sign_no, sign_type_id: s.sign_type_id, ratio: s.sign_type_object.aspect_ratio, sign_type_object: s.sign_type_object, dir: s.direction, media: s.media };
                signs.push(d);
              } else {
                let d = { id: s.id, sign_no: s.sign_no, sign_type_id: s.sign_type_id, ratio: s.sign_type_object.aspect_ratio, sign_type_object: s.sign_type_object, dir: s.direction, media: null };
                signs.push(d);
              }
            });
            signs = _.sortBy(signs, ['sign_no']);
            layers.push(signs);
          }
        });
        board.is_dir_back = is_dir_back;
        board.layers = layers;
        let layer_view = new TrafficInfoLayerView();
        if (layers_f.length > 0) {
          layer_view.front_layer = layers_f;
        }
        if (layers_b.length > 0) {
          if (layers_b.length > 1) {
            layers_b = _.reverse(layers_b);
          }
          layer_view.back_layer = layers_b;
        }
        board.traffic_info_view = layer_view;
      }
    });
    //console.log(boards);

    return boards;
  }

  //----------------------------LMS information Panel---------------------------------//
  // lms_toggle: boolean;
  // lms_select_item: any;

  // openLMSPanel(item?: any) {
  //   this.lms_select_item = item;
  //   this.lms_toggle = true;
  // }
  // closeLMSPanel(e) {
  //   this.lms_toggle = false;
  //   this.lms_select_item = null;
  //   this.search(1);
  // }
  set_sign_height_modal = 100;
  boardsignInfo: any;
  async clickOpenInfomodal(boardsign) {
    this.is_change_media = false;
    this.boardsignInfo = boardsign;
    if (this.boardsignInfo.name.toLowerCase().includes('atis')) {
      this.set_sign_height_modal = 500;
    } else {
      this.set_sign_height_modal = 100;
    }
    if (this.boardsignInfo.group_id == 1012) { this.set_sign_height_modal = 288 }

    let cameraData = await this.boardsignService.getBoardsignCamera([boardsign.id]).toPromise().catch(
      err => {
        console.log(JSON.stringify(err.statusText));
      });
    if (cameraData && cameraData.data && cameraData.data.data && cameraData.data.data[0] && cameraData.data.data[0].camera) {

      // this.devices = cameraData.data.data[0].camera.map((x:any) => {
      //   return {
      //     device_id: null,
      //     name: x.name,
      //     link: x.url,
      //     view: ""
      //   }
      // })

      let camera: any = cameraData.data.data[0].camera;
      camera.forEach((item: any, index) => {
        item.device_id = index + 1;
        item.link = item.url;
      });
      this.devices = [];
      this.devices = camera;
    }
    this.boardsingInfoModal.show()
  }
  onHideInfoModal() {

  }
  clickCloseInfoModal() {
    this.boardsingInfoModal.hide();
    if (this.is_change_media) {
      this.getBoardsign(this.search_route, this.search_st_km, this.search_en_km, null);
    }
  }

  clickOpenChangeMedia: boolean = false;
  board_select_list = [];
  openChangeMediaModal(boardsign_id) {
    this.clickOpenChangeMedia = true;
    this.board_select_list = [boardsign_id];
  }
  is_change_media = null;
  async closeChangeMediaModal(e) {
    if (this.is_change_media || e) {
      this.is_change_media = true;
    } else {
      this.is_change_media = false;
    }
    if (e) {
      const res = await this.boardsignService.getBoardsign(null, null, null, null, null, null, null, null, null, null, this.board_select_list).toPromise().catch(
        err => {
          console.log(JSON.stringify(err.statusText));
        }
      );
      if (res) {
        if (res.code == 1) {
          let list = await this.genSignsLayerView(res.data.data, false);
          if (list.length > 0)
            this.boardsignInfo = list[0];

        }
      }
    }
    this.clickOpenChangeMedia = false;
    this.board_select_list = [];
  }

  //------------------------------------------------------------//
  //------------------------------------------------------------------------------//
  is_toggle_camera_rtsp: boolean = false;
  is_toggle_camera_flv: boolean = false;
  is_toggle_camera_img: boolean = false;
  camera_rtsp_item: any;
  camera_flv_item: any;
  camera_img_item: any;
  station_item: any;
  clickCameraRtsp(item, station) {
    this.camera_rtsp_item = item;
    this.station_item = station;
    this.is_toggle_camera_rtsp = true;
  }
  closeCameraRtsp(e) {
    this.is_toggle_camera_rtsp = false;
  }

  clickCameraFlv(item, station) {
    this.camera_flv_item = item;
    this.station_item = station;
    this.is_toggle_camera_flv = true;
  }
  closeCameraFlv(e) {
    this.is_toggle_camera_flv = false;
  }

  clickCameraImg(item, station) {
    this.camera_img_item = item;
    this.station_item = station;
    this.is_toggle_camera_img = true;
  }
  closeCameraImg(e) {
    this.is_toggle_camera_img = false;
  }

  devices: any[] = [
    {
      device_id: 2847,
      link: "http://camera.shw.transcodeglobal.com/?code=0001-0058000-2-LMS-PTZ",
      view: "กล้องส่องถนน",
      name: "CCTV (1-58+000) PTZ ส่องถนน",
      rtsp_link: "http://180.180.242.208:1935/TOC/TOC_134.stream/playlist.m3u8"
    },
    {
      device_id: 2318,
      link: "http://camera.shw.transcodeglobal.com/?code=0001-0058000-2-LMS-CTV-L",
      view: "กล้องส่องถนน",
      name: "CCTV (1-58-000) กล่องป้ายซ้าย"
    },
    {
      device_id: 2324,
      link: "http://camera.shw.transcodeglobal.com/?code=0001-0058000-2-LMS-CTV-R",
      view: "กล้องส่องถนน",
      name: "CCTV (1-58-000) ส่องป้ายขวา"
    },
    {
      device_id: 2323,
      link: "http://camera.shw.transcodeglobal.com/?code=0001-0058000-2-LMS-CTV-B",
      view: "กล้องส่องถนน",
      name: "CCTV (1-58-000) ส่องป้ายหลัง"
    }
  ]
  //-----------------------------------------------------------------------------------//
}
