import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CorridorControlComponent } from './corridor-control/corridor-control.component';
import { CameraStreamComponent } from './camera-stream/camera-stream.component';
import { MainComponent } from './main/main.component';
import { TrafficInfoComponent } from './traffic-info/traffic-info.component';
import { SchematicMap0Component } from './schematic-map0/schematic-map0.component';
import { ReversibleLaneComponent } from './reversible-lane/reversible-lane.component';
import { TravelTimeComponent } from './travel-time/travel-time.component';

import { SchematicMapComponent } from './schematic-map/schematic-map.component';
import { ThailandComponent } from './schematic-map/thailand/thailand.component';
import { NorthComponent } from './schematic-map/north/north.component';
import { NortheastComponent } from './schematic-map/northeast/northeast.component';
import { EastComponent } from './schematic-map/east/east.component';
import { SouthComponent } from './schematic-map/south/south.component';

import { SchematicNewComponent } from './schematic-new/schematic-new.component';
import { ThailandNewComponent } from './schematic-new/thailand-new/thailand-new.component';
import { NorthNewComponent } from './schematic-new/north-new/north-new.component';
import { NortheastNewComponent } from './schematic-new/northeast-new/northeast-new.component';
import { EastNewComponent } from './schematic-new/east-new/east-new.component';
import { SouthNewComponent } from './schematic-new/south-new/south-new.component';

export let routes: Routes = [
  {
    path: '',
    redirectTo: 'corridor-control',
    pathMatch: 'full',
  },
  {
    path: '',
    component: MainComponent,
    data: {
      title: 'Home'
    },
    children: [
      {
        path: 'corridor-control',
        component: TrafficInfoComponent
      },
      {
        path: 'schematic-map',
        component: SchematicNewComponent,
        children: [
          {
            path: '',
            redirectTo: 'thailand',
            pathMatch: 'full',
          },
          {
            path: 'thailand',
            component: ThailandNewComponent
          },
          {
            path: 'north',
            component: NorthNewComponent
          },
          {
            path: 'northeast',
            component: NortheastNewComponent
          },
          {
            path: 'east',
            component: EastNewComponent
          },
          {
            path: 'south',
            component: SouthNewComponent
          }
        ]
      },
      {
        path: 'reversible-lane',
        component: ReversibleLaneComponent
      },
      {
        path: 'travel-time',
        component: TravelTimeComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DashboardRoutingModule { }
