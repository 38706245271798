<div class="row p-3">
    <div class="col-lg-4 col-md-4 col-sm-6">
        <div>ประเภทเหตุการณ์ :</div>
        <div class="d-flex align-items-center">
            <select [(ngModel)]="type_id" name="type_id" id="" class="form-control">
                <option value=null>Select</option>
                <option *ngFor="let i of typeList" [value]="i.id">{{i.name}}</option>
                <!-- <option *ngFor="let i of groupList" [value]="i.id">{{i.name}}</option> -->
            </select>
        </div>
        <!-- <div class="d-flex align-items-center">
            <select [(ngModel)]="src_group_id" name="src_group_id" id="" class="form-control">
                <option value=null>Select</option>
                <option value=1>เพิ่มโดยผู้ใช้</option>
                <option value=2>ระบบตรวจจับ</option>
            </select>
        </div> -->
    </div>
    <div class="col-lg-4 col-md-4 col-sm-6">
        <div>สถานะการดำเนินการ :</div>
        <div class="d-flex align-items-center">
            <select [(ngModel)]="status" name="status" id="" class="form-control">
                <option value=null>Select</option>
                <option *ngFor="let i of statusList" [value]="i.id">{{i.name}}</option>
            </select>
        </div>
    </div>
    <div class="col-lg-4 col-md-4 col-sm-6">
        <div>วันที่ :</div>
        <div class="input-group" style="flex-wrap: inherit;">
            <div class="input-group-prepend">
                <span class="input-group-text">เริ่ม</span>
                <kendo-datepicker style="max-width: 300px;" [(ngModel)]="st_date" class="w-100"
                    [format]="'dd-MMM-yyyy'"></kendo-datepicker>
            </div>
            <div class="input-group-prepend">
                <span class="input-group-text">ถึง</span>
                <kendo-datepicker style="max-width: 300px;" [(ngModel)]="en_date" class="w-100"
                    [format]="'dd-MMM-yyyy'"></kendo-datepicker>
            </div>

        </div>
    </div>
    <div class="col-lg-4 col-md-4 col-sm-6 mt-2">
        <div>ทางหลวง :</div>
        <div class="d-flex align-items-center">
            <kendo-combobox class="form-control" [data]="route_list" [allowCustom]="allowCustom" 
            [suggest]="true" [(ngModel)]="search_route"[filterable]="true" [placeholder]="'ทั้งหมด'" 
            (filterChange)="FilterRoute($event)"  (valueChange)="ChageRoute($event)"></kendo-combobox>
        </div>
    </div>
    <div class="col-lg-4 col-md-4 col-sm-6 mt-2">
        <div>หลักกิโลเมตร :</div>
        <div>
            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">เริ่มต้น</span>
                </div>
                <input type="text" class="form-control" [(ngModel)]="st_km" placeholder="กม." numbersOnly maxlength="4">
                <div class="input-group-prepend">
                    <span class="input-group-text">สิ้นสุด</span>
                </div>
                <input type="text" class="form-control" [(ngModel)]="en_km" placeholder="กม." numbersOnly maxlength="4">
            </div>
        </div>
    </div>
    <div class="col-lg-2 col-md-2 col-sm-3 mt-2 d-flex justify-content-end align-items-center">
        <button type="button" (click)="search()" class="btn btn-primary pull-right text-white"> <i
                class="fa fa-search"></i>
            ค้นหา </button>
    </div>

    <div class="col-12 mt-3 table-header pl-3 pr-3 pt-2 pb-2 d-flex align-items-center justify-content-between">
        <div>Event list</div>
        <button *ngIf="isAdd" class="btn btn-info text-white" (click)="openModal()">
            + เพิ่ม Event
        </button>

    </div>


    <div class="col-12 mt-3">
        <table class="table-main table table-outline mb-0">
            <thead class="thead-dark" style="background-color: black;">
                <tr>
                    <th class="text-center text-light table-header"><i class="fa fa-hashtag"></i></th>
                    <th class="text-center text-light table-header">ประเภทเหตุการณ์</th>
                    <th class="text-center text-light table-header">สถานะการดำเนินการ</th>
                    <th class="text-center text-light table-header">ตำแหน่งของเหตุการณ์</th>
                    <th class="text-center text-light table-header">Create datetime</th>
                    <th *ngIf="isDetail || isDelete" class="text-center text-light table-header">Action</th>
                </tr>
            </thead>
            <tbody *ngIf="!data_list || data_list?.length < 1">
                <tr>
                    <td colspan="10" style="height: 50px;">
                        ไม่พบข้อมูล
                    </td>
                </tr>
            </tbody>
            <tbody  *ngIf="data_list?.length">
                <tr *ngFor="let i of data_list | slice: (currentPage-1) * itemsPerPage : (currentPage-1) * itemsPerPage + itemsPerPage;index as idx">
                    <td>
                        {{(idx+1)+(itemsPerPage*(currentPage-1))}}
                    </td>
                    <td class="text-left">
                        <h5 *ngIf="i.event_group_name" class="pri-txt">{{i.event_group_name? ''+i.event_group_name+'':''}}</h5>
                        <p class="m-0">{{i.event_type_name}}</p>
                    </td>
                    <!-- <td [ngSwitch]="i.src_group_id">
                        <p class="m-0" *ngSwitchCase="1">เพิ่มโดยผู้ใช้</p>
                        <p class="m-0" *ngSwitchCase="2">ระบบตรวจจับ
                        
                        </p>
                        <p class="m-0" *ngSwitchDefault></p>
                    </td> -->
                    <td>
                        {{i.status_name}}
                    </td>
                    <td>
                        <!-- {{i.route | number:'4.0-0' | noComma}}
                        {{(i.km||i.m)? '('+(i.km||0)+(i.m? '+'+i.m:'')+')' : ''}} -->
                        {{(i.st_dir==1)? 'ขาเข้า':''}}
                        {{(i.st_dir==2)? ' ขาออก':''}}
                        {{(i.st_dir==3)? 'ขาเข้า-ขาออก':''}}
                        {{(i.st_route)? i.st_route +' ('+i.st_km+' + '+i.st_m+')':''}}
                        
                        {{(i.en_dir==1)? '/ ขาเข้า':''}}
                        {{(i.en_dir==2)? '/ ขาออก':''}}
                        {{(i.en_dir==3)? '/ ขาเข้า-ขาออก':''}}
                        {{(i.en_route)? i.en_route +' ('+i.en_km+' + '+i.en_m+')':''}}
                    </td>
                    <td>{{i.created_datetime | date:'dd/MM/yyyy HH:mm:ss':'+0700'}}</td>
                    <td *ngIf="isDetail || isDelete || isReport">
                        <button *ngIf="isDetail" class="ml-1 mr-1 btn btn-info" (click)="openModal(i)">
                            <i class="icon-list" aria-hidden="true"></i>
                        </button>
                        <!-- <button *ngIf="isDelete" class="ml-1 mr-1 btn btn-danger" (click)="rmlist(i.id)">
                            <i class="fa fa-trash" aria-hidden="true"></i>
                        </button> -->
                    </td>
                </tr>
                <tr>
                    <td colspan="12" class="table-footer">
                        <div class="table-footer-inside d-flex align-items-center justify-content-between">
                            <div *ngIf="data_list.length" class="ml-3 pt-3">

                                <pagination [(ngModel)]="currentPage" [maxSize]="10" [totalItems]="data_list.length"
                                    [itemsPerPage]="itemsPerPage" (pageChanged)="pageChanged($event)"
                                    previousText="&lsaquo;" nextText="&rsaquo;" [boundaryLinks]="true"
                                    firstText="&laquo;" lastText="&raquo;">></pagination>

                            </div>
                            <div></div>
                            <div class="mr-3 ml-2">total: {{data_list.length||0}}</div>

                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

</div>